@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,600&display=swap');
*{
  
font-family: 'Poppins', sans-serif;
}
:root {
  --primary-color: #108040;
  --primary-light: #108040  ;
    --clr-text:#000000;
  --clr-heading:#106b54; 
  --primary-color-text: #ffffff;
  --bg-gradient : linear-gradient(0deg, var(--primary-color) var(--teal-200)), linear-gradient(92.54deg, var(--teal-400) 47.88%, #FFFFFF 100.01%);
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dfe7ef;
  --surface-hover:#f6f9fc;
  --surface-ground:#eff3f8;
  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f7faf5;
  --green-100: #dbe8cf;
  --green-200: #bed6a9; 
  --green-300: #a1c384;
  --green-400: #85b15e;
  --green-500: #689f38;
  --green-600: #588730;
  --green-700: #496f27;
  --green-800: #39571f;
  --green-900: #2a4016;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f6f7fc;
  --indigo-100: #d5d9ef;
  --indigo-200: #b3bae2;
  --indigo-300: #919cd5;
  --indigo-400: #707dc8;
  --indigo-500: #4e5fbb;
  --indigo-600: #42519f;
  --indigo-700: #374383;
  --indigo-800: #2b3467;
  --indigo-900: #1f264b;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fffaf2;
  --orange-100: #ffe6c2;
  --orange-200: #ffd391;
  --orange-300: #ffbf61;
  --orange-400: #ffac30;
  --orange-500: #ff9800;
  --orange-600: #d98100;
  --orange-700: #b36a00;
  --orange-800: #8c5400;
  --orange-900: #663d00;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --red-50: #fef6f5;
  --red-100: #fcd2cf;
  --red-200: #faaea9;
  --red-300: #f88a82;
  --red-400: #f6675c;
  --red-500: #f44336;
  --red-600: #cf392e;
  --red-700: #ab2f26;
  --red-800: #86251e;
  --red-900: #621b16;
  --surface-0: #ffffff;
  --surface-50: #f9fafb;
  --surface-100: #f8f9fa;
  --surface-200: #e9ecef;
  --surface-300: #dee2e6;
  --surface-400: #ced4da;
  --surface-500: #adb5bd;
  --surface-600: #6c757d;
  --surface-700: #495057;
  --surface-800: #343a40;
  --surface-900: #212529;

  --border-radius: 4px;

 
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.color-picker > fieldset {
  border: 0;
  display: flex;
  gap: 2rem;
  width: fit-content;
  background: #fff;
  padding: 1rem 3rem;
  margin-inline: auto;
  border-radius: 0 0 1rem 1rem;
}

.color-picker input[type="radio"] {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  outline: 3px solid var(--radio-color, currentColor);
  outline-offset: 3px;
  border-radius: 50%;
}

* {
  box-sizing: border-box;
}
/* .body{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, "Roboto", Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 12.5px;
} */
.p-component {
  font-size: 12.5px;
  font-weight: normal;
}
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.15s;
  color: var(--primary-color);
}
.p-disabled,
.p-component:disabled {
  opacity: 0.65;
}
.p-error {
  color: #f44336;
}
.p-text-secondary {
  color: #6c757d;
}
.pi {
}
.p-icon {
  width: 13px;
  height: 13px;
}
.p-link {

  border-radius: 4px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}
.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}
@keyframes p-component-overlay-enter-animation {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.107rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--clr-heading);
  border-color: var(--primary-color);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input {

  color: var(--clr-text);
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--primary-color);
  color: var(--clr-text);
  border-radius: 4px;
}
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}
.p-autocomplete-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #212529;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
}
.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}
.p-datepicker {
  padding: 0;
  background: #fff;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #efefef;
  position: relative;	
  padding: .1em !important;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #212529;
  transition: box-shadow 0.15s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--primary-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 12.5px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 4px;
  transition: box-shadow 0.15s;
  border: 1px solid rgba(0, 0, 0, 0);
}
.p-datepicker table td > span.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #212529;
  border-color: rgba(0, 0, 0, 0);
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--clr-text);
  background: var(--primary-color);
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--clr-text);
  background: var(--primary-color);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-left: 0 none;
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}
@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-cascadeselect .p-cascadeselect-label {
  background: rgba(0, 0, 0, 0);
  border: 0 none;
  padding: 0.5rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-cascadeselect.p-invalid.p-component {
  border-color: #f44336;
}
.p-cascadeselect-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-item-content {
  padding: 0.5rem 1.5rem;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-group-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-input-filled .p-cascadeselect {
  background: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.15s;
  color: #fff;
  /* font-size: 14px; */
}
.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 14px;
  height: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #fff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #fff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f44336;
}
.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #efefef;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-color);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #efefef;
}
.p-input-filled
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: #0062cc;
}
.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #fff;
}
.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #212529;
  border-radius: 16px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {

  /* font-size: 1rem; */
  color: #212529;
  padding: 0;
  margin: 0;
}
.p-chips.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}
.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}
.p-colorpicker-panel {
  background: #212529;
  border: 1px solid #212529;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #fff;
}
.p-colorpicker-overlay-panel {
  box-shadow: none;
}
.p-dropdown {
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color);
  border-color: var(--primary-color);
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: rgba(0, 0, 0, 0);
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #f44336;
}
.p-dropdown-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-dropdown-panel
  .p-dropdown-header
  .p-dropdown-clearable-filter
  .p-dropdown-filter {
  padding-right: 3.5rem;
  margin-right: -3.5rem;
}
.p-dropdown-panel
  .p-dropdown-header
  .p-dropdown-clearable-filter
  .p-dropdown-filter-clear-icon {
  right: 2.5rem;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: var(--clr-text);
  background: var(--primary-color-text);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--clr-heading);
  background: var(--primary-color);
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--clr-text);
  background: var(--primary-color);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-input-filled .p-dropdown {
  background: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: rgba(0, 0, 0, 0);
}
.p-editor-container .p-editor-toolbar {
  background: #efefef;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-stroke {
  stroke: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-fill {
  fill: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-stroke {
  stroke: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-fill {
  fill: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  padding: 0.5rem 0;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #212529;
  background: #e9ecef;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded:not(.ql-icon-picker)
  .ql-picker-item {
  padding: 0.5rem 1.5rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #fff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-picker-label {
  color: var(--primary-color);
}
.p-inputgroup-addon {
  background: var(--primary-color);
  color: var(--clr-heading);
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}
.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}
.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}
.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}
.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 4px;
  transition-duration: 0.15s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ced4da;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #fff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: var(--primary-color);
}
.p-inputswitch.p-invalid .p-inputswitch-slider {
  border-color: #f44336;
}
.p-inputtext {
  font-size: 13px;
  color: var(--clr-text);
  background: var(--surface-0);
  padding: 0.5rem 0.75rem;
  border: .5px solid grey;
  /* transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s; */
  appearance: none;
  border-radius: 4px;
}
.p-inputtext:enabled:hover {
  border-color: var(--primary-color);
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2px  var(--primary-color);
  border-color: var(--primary-color);
}
.p-inputtext.p-invalid.p-component {
  border-color: #f44336;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  /* font-size: 1.25rem; */
  padding: 0.825rem 0.9375rem;
}
.p-float-label > label {
  left: 0.75rem;
  width: 100%;
  color: red;
  transition-duration: 0.15s;
}
.p-float-label > label.p-error {
  color: #f44336;
}
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.75rem;
  color: #495057;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}
.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}
.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.75rem;
  color: #495057;
}
.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}
::-webkit-input-placeholder {
  color: var(--primary-light);
}
:-moz-placeholder {
  color: var(--primary-color-text);

}
::-moz-placeholder {
  color: #6c757d;
}
:-ms-input-placeholder {
  color: #6c757d;
}
.p-input-filled .p-inputtext {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #efefef;
}
.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-listbox {
  background: #fff;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-listbox .p-listbox-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-listbox:not(.p-disabled)
  .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-listbox.p-invalid {
  border-color: #f44336;
}
.p-mention-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-mention-panel .p-mention-items {
  padding: 0.5rem 0;
}
.p-mention-panel .p-mention-items .p-mention-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-mention-panel .p-mention-items .p-mention-item:hover {
  color: #212529;
  background: #e9ecef;
}
.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-multiselect {
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #212529;
  border-radius: 16px;
}
.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-multiselect .p-multiselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}
.p-multiselect.p-invalid.p-component {
  border-color: #f44336;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip
  .p-multiselect-label.p-multiselect-items-label {
  padding: 0.5rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable
  .p-multiselect-label {
  padding-right: 1.75rem;
}
.p-multiselect-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 0.5rem;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-input-filled .p-multiselect {
  background: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}
.p-password-panel {
  padding: 1.25rem;
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 4px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #e9ecef;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #dc3545;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #ffc107;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #28a745;
}
.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 50%;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ced4da;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.15s;
  background-color: #fff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #fff;
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #f44336;
}
.p-radiobutton:focus {
  outline: 0 none;
}
.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--primary-color);
}
.p-input-filled
  .p-radiobutton
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #0062cc;
}
.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #fff;
}
.p-rating {
  gap: 0.5rem;
}
.p-rating .p-rating-item .p-rating-icon {
  color: #495057;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: 1.143rem;
}
.p-rating .p-rating-item .p-rating-icon.p-icon {
  width: 1.143rem;
  height: 1.143rem;
}
.p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
  color: #dc3545;
}
.p-rating .p-rating-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly)
  .p-rating-item:hover
  .p-rating-icon {
  color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly)
  .p-rating-item:hover
  .p-rating-icon.p-rating-cancel {
  color: #dc3545;
}
.p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #fff;
}
.p-selectbutton .p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-left,
.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}
.p-selectbutton.p-invalid > .p-button {
  border-color: #f44336;
}
.p-slider {
  background: #e9ecef;
  border: 0 none;
  border-radius: 4px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-slider .p-slider-range {
  background: var(--primary-color);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #0069d9;
  border-color: #0069d9;
}
.p-treeselect {
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: var(--primary-color);
}
.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #212529;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-treeselect.p-invalid.p-component {
  border-color: #f44336;
}
.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 0.75rem;
}
.p-treeselect-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container
  .p-treeselect-filter {
  padding-right: 1.75rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container
  .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter {
  padding-right: 3.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-input-filled .p-treeselect {
  background: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button.p-invalid > .p-button {
  border-color: #f44336;
}
.p-button {
  color: var(--primary-light);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 8px;
  /* font-size: 13px; */
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--clr-heading);
  color: black;
  /* border-color: #0069d9; */
}
.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); */
}
.p-button .p-button-label {
  transition-duration: 0.15s;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: var(--primary-color);
  background-color: #fff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  /* width: 2.357rem; */
  /* font-size: 13.2px; */
  margin: 2px;
  /* font-weight: 300; */
  /* padding: 0.75rem ; */
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
  margin-right: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
  margin-top: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
  margin-bottom: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
}
.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
  color: #fff;
  background: #6c757d;
  border: 1px solid #6c757d;
}
.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-buttonset.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:enabled:hover,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: #616971;
  color: #fff;
  border-color: #616971;
}
.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-buttonset.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(
    .p-disabled
  ):focus {
  box-shadow: 0 0 0 0.2rem #c4c8cc;
}
.p-button.p-button-secondary:enabled:active,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-buttonset.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:enabled:active,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #565e64;
  color: #fff;
  border-color: #565e64;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(
    a
  ):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(
    a
  ):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(108, 117, 125, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
  color: #fff;
  background: #17a2b8;
  border: 1px solid #17a2b8;
}
.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-buttonset.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:enabled:hover,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
  background: #1592a6;
  color: #fff;
  border-color: #1592a6;
}
.p-button.p-button-info:enabled:focus,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-buttonset.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:enabled:focus,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #93e5f2;
}
.p-button.p-button-info:enabled:active,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-buttonset.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:enabled:active,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  background: #128293;
  color: #fff;
  border-color: #128293;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-info
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-info
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-button.p-button-info.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-info
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:enabled:active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(23, 162, 184, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button,
.p-fileupload-choose.p-button-success {
  color: #fff;
  background: #28a745;
  border: 1px solid #28a745;
}
.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-buttonset.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: #24963e;
  color: #fff;
  border-color: #24963e;
}
.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-buttonset.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(
    .p-disabled
  ):focus {
  box-shadow: 0 0 0 0.2rem #9de8ae;
}
.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-buttonset.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #208637;
  color: #fff;
  border-color: #208637;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(40, 167, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button,
.p-fileupload-choose.p-button-warning {
  color: #212529;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-buttonset.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:enabled:hover,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: #ecb100;
  color: #212529;
  border-color: #ecb100;
}
.p-button.p-button-warning:enabled:focus,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-buttonset.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:enabled:focus,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(
    .p-disabled
  ):focus {
  box-shadow: 0 0 0 0.2rem #ffe69c;
}
.p-button.p-button-warning:enabled:active,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-buttonset.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:enabled:active,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #d29d00;
  color: #212529;
  border-color: #d29d00;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-warning
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-warning
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(255, 193, 7, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button,
.p-fileupload-choose.p-button-help {
  color: #fff;
  background: #6f42c1;
  border: 1px solid #6f42c1;
}
.p-button.p-button-help:enabled:hover,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-buttonset.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:enabled:hover,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
  background: #633ab0;
  color: #fff;
  border-color: #633ab0;
}
.p-button.p-button-help:enabled:focus,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-buttonset.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:enabled:focus,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #c5b3e6;
}
.p-button.p-button-help:enabled:active,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-buttonset.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:enabled:active,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
  background: #58339c;
  color: #fff;
  border-color: #58339c;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-help
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-help
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-button.p-button-help.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-help
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:enabled:active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(111, 66, 193, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
  color: #fff;
  background: #dc3545;
  border: 1px solid #dc3545;
}
.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-buttonset.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: #d12435;
  color: #fff;
  border-color: #d12435;
}
.p-button.p-button-danger:enabled:focus,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-buttonset.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:enabled:focus,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #f1aeb5;
}
.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-buttonset.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger
  > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(
    .p-disabled
  ):active {
  background: #ba202f;
  color: #fff;
  border-color: #ba202f;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(220, 53, 69, 0.04);
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger
  > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(220, 53, 69, 0.16);
  color: #dc3545;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #dc3545;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(
    .p-disabled
  ):hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #dc3545;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger
  > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(
    .p-disabled
  ):active {
  background: rgba(220, 53, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #dc3545;
}
.p-button.p-button-link {
  color: var(--primary-color);
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:hover,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
  color: #0069d9;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:hover .p-button-label,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover
  .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:active,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 0, 0, 0);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton {
  border-radius: 4px;
}
.p-splitbutton.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-color);
  border: 1px solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--primary-color);
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--primary-color);
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #6c757d;
  border-color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-splitbutton.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}
.p-splitbutton.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}
.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}
.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}
.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border: 1px solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border: 1px solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border: 1px solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border: 1px solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border: 1px solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #dc3545;
  border: 1px solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #dc3545;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #dc3545;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #dc3545;
}
.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon.p-icon {
  width: 1.3rem;
  height: 1.3rem;
}
.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: #fff;
}
.p-speeddial-action:hover {
  background: #343a40;
  color: #fff;
}
.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}
.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}
.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}
.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}
.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}
.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  visibility: hidden;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-carousel .p-carousel-indicators {
  /* padding:30 px !important */
  visibility: hidden;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--primary-color);
  color: #fff;
}
.p-datatable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: var(--primary-light);
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #212529;
  background: #ffff;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  /* border-width: 1px 0 1px 0; */
  font-weight: 600;
  color: #212529;
  background: #fff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffff;
  background: var(--primary-color);
  margin-left: 0.5rem;
}
.p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
  .p-sortable-column-icon {
  color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #fff;
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #e9ecef;
  color: var(--primary-color);
}
.p-datatable
  .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
  .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffff;
  color: #212529;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: .25px solid #dee2e6;
  /* border-width: 1px 1px 1px 1px; */
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 600;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
  background: var(--primary-color);
  color:var(--clr-text ) ;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--primary-color);
  color: var(--clr-text);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--primary-color);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--primary-color);
}
.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:focus {
  outline: 0.15rem solid rgba(38, 143, 255, 0.5);
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-selectable-cell
  .p-datatable-tbody
  > tr.p-selectable-row
  > td.p-selectable-cell:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable.p-datatable-selectable-cell
  .p-datatable-tbody
  > tr.p-selectable-row
  > td.p-selectable-cell:focus {
  outline: 0.15rem solid rgba(38, 143, 255, 0.5);
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #e9ecef;
  color: #212529;
}
.p-datatable .p-column-resizer-helper {
  background: var(--primary-color);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #efefef;
}
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-tfoot {
  background-color: #fff;
}
.p-datatable .p-datatable-loading-icon {
  /* font-size: 2rem; */
}
.p-datatable .p-datatable-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-tbody
  > tr:last-child
  > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  + .p-datatable-tfoot
  > tr
  > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  + .p-datatable-tfoot
  > tr
  > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
    .p-datatable-tfoot
  )
  .p-datatable-tbody
  > tr:last-child
  > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
    .p-datatable-tfoot
  )
  .p-datatable-tbody
  > tr:last-child
  > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--blue-100);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: var(--clr-text);
  color: #fff;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr.p-row-odd.p-highlight
  .p-row-toggler {
  color: #fff;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr.p-row-odd.p-highlight
  .p-row-toggler:hover {
  color: #fff;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr.p-row-odd
  + .p-row-expanded {
  background: #0d1a2c;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
  text-align         : center;
  border-right         : .5px solid;
  font-weight          : normal;
  color               : #ffffff;
  background-color     : var(--primary-color) !important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}
.p-datatable-drag-selection-helper {
  background: rgba(0, 123, 255, 0.16);
}
.p-dataview .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  /* background: rgba(255, 255, 255, 0.6); */
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  /* border: 5px solid var(--primary-color);
  border-width: 1px 0 0 0; */
}
.p-dataview .p-dataview-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
  /* font-size: 2rem; */
}
.p-dataview .p-dataview-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-datascroller .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datascroller .p-datascroller-header {
  background: #efefef;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datascroller .p-datascroller-content {
  background: #fff;
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
}
.p-datascroller .p-datascroller-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}
.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(0, 0, 0, 0);
  color: #495057;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: var(--primary-color);
  color: #fff;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-column-filter-overlay {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}
.fc.fc-unthemed .fc-view-container th {
  background: #efefef;
  border: 1px solid #dee2e6;
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  border: 1px solid #dee2e6;
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-view {
  background: #fff;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #0069d9;
  border: 1px solid #0069d9;
  color: #fff;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #efefef;
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #fff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #0069d9;
  color: #fff;
  border-color: #0069d9;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #0062cc;
  color: #fff;
  border-color: #0062cc;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #dee2e6;
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #efefef;
  border-color: #dee2e6;
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #212529;
  border-color: #dee2e6;
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #fff;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:before {
  content: "";
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #fff;
  background: #0069d9;
  border-color: #0069d9;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-event.fc-daygrid-block-event
  .fc-event-main {
  color: #fff;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-event.fc-daygrid-dot-event
  .fc-daygrid-event-dot {
  background: #0069d9;
  border-color: #0069d9;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: #e9ecef;
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #efefef;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #fff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #0069d9;
  color: #fff;
  border-color: #0069d9;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #0062cc;
  color: #fff;
  border-color: #0062cc;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.65;
  color: #fff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard .fc-highlight {
  color: var(--clr-text);
  background: var(--primary-color);
}
.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem 1.25rem;
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: transform 0.15s, box-shadow 0.15s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-orderlist.p-orderlist-striped
  .p-orderlist-list
  .p-orderlist-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-orderlist.p-orderlist-striped
  .p-orderlist-list
  .p-orderlist-item:nth-child(even):hover {
  background: #e9ecef;
}
.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node:not(
    .p-highlight
  ):hover {
  background: #e9ecef;
  color: #212529;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: var(--primary-color);
  color: #fff;
}
.p-organizationchart
  .p-organizationchart-node-content.p-highlight
  .p-node-toggler
  i {
  color: #003e80;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-paginator {
  background: #fff;
  color: var(--clr-heading);
  border: solid #dee2e6;
  border-width: 0;
  padding: 0.75rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: var(--primary-color);
  border: 1px solid var(--clr-text);
  color: var(--clr-text);
  min-width: 2rem;
  height: 2rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--primary-color);
  border-color: var(--clr-text);
  color: var(--clr-text);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2rem;
}
.p-paginator .p-paginator-current {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: var(--primary-color);
  min-width: 2rem;
  height: 2rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: var(--primary-color);
  border: 1px solid var(--clr-text);
  color: var(--clr-text);
  min-width: 2rem;
  height: 2rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--clr-heading);
  border-color: var(--clr-text);
  color: var(--primary-color);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--primary-color);
  border-color: var(--clr-text);
  color: var(--clr-text);
}
.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem 1.25rem;
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: transform 0.15s, box-shadow 0.15s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #fff;
  background: var(--primary-color);
}
.p-tree {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  padding: 1.25rem;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: box-shadow 0.15s;
  padding: 0.286rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--primary-color);
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler:hover,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon:hover {
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #212529;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-tree-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-tree .p-treenode-droppoint {
  height: 0.5rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #0062cc;
}
.p-treetable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: var(--primary-light);
  color: var(--clr-text);
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: var(--primary-color);
  color: var(--clr-text);
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: .5rem .5rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: var(--clr-text);
  background: var(--primary-color);
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: .5rem .5rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #212529;
  background: #fff;
}
.p-treetable .p-sortable-column {
  outline-color: var(--primary-color);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #fff;
  background: var(--primary-color);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: var(--primary-light);
  color: #212529;
}
.p-treetable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-treetable .p-sortable-column.p-highlight {
  background: var(--primary-light);
  color: var(--primary-color);
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-treetable .p-treetable-tbody > tr {
  /* background: var(--clr-heading); */
  color: var(--clr-text);
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: .5rem .5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); */
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable
  .p-treetable-tbody
  > tr
  > td
  .p-treetable-toggler
  + .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: var(--clr-heading);
}
.p-treetable .p-treetable-tbody > tr:focus {
  /* outline: 0.15rem solid rgba(38, 143, 255, 0.5); */
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: var(--primary-color);
  color: var(--clr-text);
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #fff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #fff;
}
.p-treetable.p-treetable-selectable
  .p-treetable-tbody
  > tr:not(.p-highlight):hover,
.p-treetable.p-treetable-hoverable-rows
  .p-treetable-tbody
  > tr:not(.p-highlight):hover {
  background: var(--primary-color);
  color: #212529;
}
.p-treetable.p-treetable-selectable
  .p-treetable-tbody
  > tr:not(.p-highlight):hover
  .p-treetable-toggler,
.p-treetable.p-treetable-hoverable-rows
  .p-treetable-tbody
  > tr:not(.p-highlight):hover
  .p-treetable-toggler {
  color: #212529;
}
.p-treetable .p-column-resizer-helper {
  background: var(--primary-color);
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #efefef;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable .p-treetable-loading-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}
.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: var(--primary-color);
}
.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: var(--primary-color);
  border-color: #dee2e6;
  color: #212529;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: red;
  border-color: #dee2e6;
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  background: var(--primary-color);
  color: #212529;
}
.p-accordion .p-accordion-content {
  padding: 1.25rem;
  /* border: 1px solid #dee2e6; */
  background: #fff;
  margin: 10px;
  color: #212529;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 52px;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  .p-accordion-tab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-card {
  background: #fff;
  color: #212529;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-card .p-card-body {
  padding: 1.5rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}
.p-fieldset {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem 1.25rem;
  color: #212529;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #212529;
}
.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}
.p-divider .p-divider-content {
  background-color: #fff;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 2px #d8e370;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}
.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 1rem;
  height: 1rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1.25rem;
}
.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1.25rem;
  border: 1px solid #dee2e6;
  background: #fff;
  color: #212529;
  border-top: 0 none;
}
.p-splitter {
  border: 1px solid #dee2e6;
  background: #fff;
  border-radius: 4px;
  color: #212529;
}
.p-splitter .p-splitter-gutter {
  transition: box-shadow 0.15s;
  background: #efefef;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}
.p-scrollpanel .p-scrollpanel-bar {
  background: #efefef;
  border: 0 none;
}
.p-tabview .p-tabview-nav {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  list-style: none;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid;
  border-width: 1px;
  border-color: #fff #fff #dee2e6 #fff;
  background: #fff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #fff;
  border-color: #dee2e6;
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fff;
  border-color: #dee2e6 #dee2e6 #fff #dee2e6;
  color: #495057;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #fff;
  color: #495057;
  width: 2.357rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tabview .p-tabview-panels {
  background: #fff;
  padding: 1.25rem;
  border: 0 none;
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}


  .p-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap !important;
    padding : 5px 5px;
}


.p-confirm-popup {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1.25rem 1.25rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
.p-confirm-popup:before {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #fff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-icon.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}
.p-dialog {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  color: #212529;
  padding: .4rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #fff;
  color: #212529;
  padding: 1rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #e9ecef;
  background: #fff;
  color: #212529;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}
.p-overlaypanel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--primary-color);
  color: #fff;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #0069d9;
  color: #fff;
}
.p-overlaypanel:after {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
.p-overlaypanel:before {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #fff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.p-sidebar {
  background: #fff;
  color: var(--clr-text);
  /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
  box-shadow: none;
  transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-header {
  padding: .5rem;
  align-items: center;
  flex-direction: column;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  font-size: 35px;
  color: grey;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: var(--clr-text);
  border-color: rgba(0, 0, 0, 0);
  /* background:var(--clr-text); */
  font-weight: bolder;

}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  font-weight: bolder;
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1.25rem;
}
.p-tooltip .p-tooltip-text {
  background: #212529;
  color: #fff;
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #212529;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #212529;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #212529;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #212529;
}
.p-fileupload .p-fileupload-buttonbar {
  background: #efefef;
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  gap: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-content {
  background: #fff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}
.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 4px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: var(--primary-color);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-color);
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}
.p-contextmenu {
  padding: 0.5rem 0;
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  }
.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
  transition: transform 0.15s;
}
.p-contextmenu .p-submenu-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-container,
  .p-dock.p-dock-bottom .p-dock-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-container .p-dock-list,
  .p-dock.p-dock-bottom .p-dock-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-container,
  .p-dock.p-dock-right .p-dock-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-container .p-dock-list,
  .p-dock.p-dock-right .p-dock-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next,
  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-current,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next,
  .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next,
  .p-dock.p-dock-magnification.p-dock-left .p-dock-item-current,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next,
  .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-megamenu-panel {
  background: #fff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}
.p-megamenu .p-megamenu-button {
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-megamenu .p-megamenu-button:hover {
  color: rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0);
}
.p-megamenu .p-megamenu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-megamenu .p-submenu-icon {
  transition: transform 0.15s;
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
  padding: 0.5rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  .p-submenu-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem {
  width: 100%;
  position: static;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #212529;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  margin-left: auto;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  > .p-submenu-icon {
  transform: rotate(-180deg);
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
  width: 100%;
  position: static;
  box-shadow: none;
  border: 0 none;
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  .p-submenu-list
  .p-submenu-icon {
  transition: transform 0.15s;
  transform: rotate(90deg);
}
.p-megamenu.p-megamenu-mobile-active
  .p-megamenu-root-list
  .p-submenu-list
  .p-menuitem-active
  > .p-menuitem-link
  > .p-submenu-icon {
  transform: rotate(-90deg);
}
.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
  width: 100%;
  position: static;
}
.p-menu {
  padding: 0.5rem 0;
  background: var(--primary-color);
  color: var(--clr-text);
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--primary-color);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-menu.p-menu-overlay {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  margin-top: 10px;
  max-width: 160px;
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #fff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-menubar {
  padding: 0px !important;
  background: var(--surface-900);
  color: var(--primary-color-text);
  border: 0 none;
  /* border-radius: 4px; */
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--primary-color-text);
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: var(--primary-color-text);
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-color-text);

  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: var(--primary-color-text);

  /* color: #212529; */
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {

  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--primary-color-text);

  /* color: #212529; */
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--primary-color-text);

  /* color: #212529; */
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--primary-color-text);

  /* color: #212529; */
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  /* color: rgba(0, 0, 0, 0.5); */
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
    color: var(--primary-color-text);

}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
    color: var(--primary-color-text);

  margin-right: 0.5rem;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: var(--primary-color-text);
  ;
  margin-left: 0.5rem;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
    color: var(--teal-200);
;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5); */
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-submenu-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
    width: 102%;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: white;
    border-radius: 50%;
    transition: box-shadow 0.15s;
  }
  .p-menubar .p-menubar-button:hover {
    color: white;
    background: rgba(0, 0, 0, 0);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.15s;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.15s;
    transform: rotate(90deg);
  }
  .p-menubar
    .p-menubar-root-list
    .p-submenu-list
    .p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.7rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.7rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: var(--primary-color);
  color: var(--clr-heading);
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: var(--primary-color);
  color: var(--clr-heading);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* //panelmenu heading hover active color */
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  background: var(--primary-color);
  color: var(--clr-text);
}
.p-panelmenu .p-panelmenu-content {
  padding: 0 0 0 3.5em; 
  background: var(--clr-heading);
  color: var(--clr-text);  
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 13px 0px 13px 40px;
  color: var(--clr-text);
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}


.p-menuitem-active a {
  background: red !important;
}

.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-menuitem-icon {
  color:  var(--clr-heading);
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: var(--clr-heading);
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover {
  background: var(--clr-heading);

  
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: var(--clr-heading);
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: var(--clr-heading);
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: var(--clr-heading);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-color);

}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-panelmenu-icon {
    
  margin-right: 0.5rem;
}
.p-panelmenu
  .p-panelmenu-content
  .p-submenu-list:not(.p-panelmenu-root-submenu) {
    
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
  
  
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
  
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
  
  
}
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
  > a,
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-disabled).p-highlight:hover
  > a {
  border-top: 0 none;
  
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  
}
.p-panelmenu
  .p-panelmenu-panel:last-child
  .p-panelmenu-header:not(.p-highlight)
  > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-slidemenu {
  padding: 0.5rem 0;
  background: #fff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-slidemenu.p-slidemenu-overlay {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-text {
  color: #212529;
}
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-icon,
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-icon {
  color: #212529;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #212529;
}
.p-steps .p-steps-item .p-menuitem-link {
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #212529;
  border: 1px solid #dee2e6;
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 4px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--primary-color);
  color: #fff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #212529;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}
.p-tabmenu .p-tabmenu-nav {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid;
  border-width: 1px;
  border-color: #fff #fff #dee2e6 #fff;
  background: #fff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
  height: calc(100% + 1px);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
  .p-menuitem-link {
  background: #fff;
  border-color: #dee2e6;
  color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--primary-color);
  border-color: #dee2e6 #dee2e6 #fff #dee2e6;
  color: var(--primary-color-text);
}
.p-tieredmenu {
  padding: 0.5rem 0;
  background: #fff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
  
}
.p-tieredmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
  transition: transform 0.15s;
}
.p-tieredmenu .p-submenu-icon.p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 0px;
  color: #004085;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #004085;
}
.p-inline-message.p-inline-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 0px;
  color: #155724;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #155724;
}
.p-inline-message.p-inline-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 0px;
  color: #856404;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #856404;
}
.p-inline-message.p-inline-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 0px;
  color: #721c24;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #721c24;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-icon.p-icon {
  width: 1rem;
  height: 1rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}
.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.25rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-message.p-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-message.p-message-info .p-message-icon {
  color: #004085;
}
.p-message.p-message-info .p-message-close {
  color: #004085;
}
.p-message.p-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-message.p-message-success .p-message-icon {
  color: #155724;
}
.p-message.p-message-success .p-message-close {
  color: #155724;
}
.p-message.p-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-message.p-message-warn .p-message-icon {
  color: #856404;
}
.p-message.p-message-warn .p-message-close {
  color: #856404;
}
.p-message.p-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-message.p-message-error .p-message-icon {
  color: #721c24;
}
.p-message.p-message-error .p-message-close {
  color: #721c24;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-icon.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast
  .p-toast-message
  .p-toast-message-content
  .p-toast-message-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #721c24;
}
.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #efefef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: var(--primary-color);
  color: #fff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator
  button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator
  button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator.p-highlight
  button {
  background: var(--primary-color);
  color: #fff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow 0.15s;
}
.p-galleria
  .p-galleria-thumbnail-container
  .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0);
  color: #f8f9fa;
  transition: box-shadow 0.15s;
}
.p-image-preview-indicator .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: #f8f9fa;
  background-color: rgba(0, 0, 0, 0);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link span {
  font-size: 1.5rem;
}
.p-image-action.p-link .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-avatar {
  background-color: #dee2e6;
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 1rem;
  height: 1rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 1rem;
}
.p-avatar-group .p-avatar {
  border: 2px solid #fff;
}
.p-chip {
  background-color: #dee2e6;
  color: #212529;
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
  border-radius: 4px;
  transition: box-shadow 0.15s;
  margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  box-shadow: none;
  transition: box-shadow 0.15s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #efefef;
}
.p-scrolltop .p-scrolltop-icon.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-skeleton {
  background-color: #e9ecef;
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}
.p-tag {
  background: var(--primary-color);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #28a745;
  color: #fff;
}
.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #fff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #dc3545;
  color: #fff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}
.p-tag .p-tag-icon.p-icon {
  width: 0.75rem;
  height: 0.75rem;
}
.p-inplace .p-inplace-display {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #212529;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #e9ecef;
  border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--primary-color);
}
.p-progressbar .p-progressbar-label {
  color: #fff;
  line-height: 1.5rem;
}
.p-terminal {
  background: #fff;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
}
.p-terminal .p-terminal-input {


}
.p-badge {
  background: var(--primary-color);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #6c757d;
  color: #fff;
}
.p-badge.p-badge-success {
  background-color: #28a745;
  color: #fff;
}
.p-badge.p-badge-info {
  background-color: #17a2b8;
  color: #fff;
}
.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #dc3545;
  color: #fff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-tag {
  background: var(--primary-color);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #28a745;
  color: #fff;
}
.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #fff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #dc3545;
  color: #fff;
}
.p-breadcrumb .p-breadcrumb-chevron {

}
.p-breadcrumb .p-breadcrumb-chevron:before {
  content: "/";
}

.sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.p-panelmenu-icon {
  order: 1;
  margin-right: 20px;
  width: 6%;
  float: right;
  color:var(--clr-heading);
}


.p-panelmenu .p-panelmenu-header>a {
padding: .9rem;
border: 1px solid #dee2e6;
color: var(--clr-text);
font-weight: 500;
border-radius: 6px;
transition: box-shadow 0.2s;

}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
margin-right: 0.25rem;
width: 10px;

}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
outline: 0 none;
outline-offset: 0;
/* box-shadow: 0 0 0 0.2rem #C7D2FE; */
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
background: var(--primary-color);
/* border-color: #dee2e6; */
color: var(--clr-heading);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-content {
padding: 2px 0;
background: var(--primary-color);
color: var(--clr-text);
border-top: 0;
border-top-right-radius: 0;
border-top-left-radius: 0;
border-bottom-right-radius: 6px;
border-bottom-left-radius: 6px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: var(--clr-text);

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
color: var(--clr-text);
margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
color: black;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
background: var(--primary-color);

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
color: red;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
color:black;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
color: blue;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
outline: 0 none;
outline-offset: 0;
color:red;
/* background-color: var(--primary-light); */
box-shadow: inset 0 0 0 0.15rem var(--primary-color);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
margin-right: 1rem;
width: 10px;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
padding: 0 0 0 1rem;

}

.p-panelmenu .p-panelmenu-panel {
margin-bottom: 2px;
}

.p-toggleable-content-enter {
max-height: 0;

}

.p-toggleable-content-enter-active {
overflow: hidden;
max-height: 1000px;
transition: max-height 1s ease-in-out;


}

.p-toggleable-content-enter-done {
transform: none

}

.p-toggleable-content-exit {
max-height: 1000px
}

.p-toggleable-content-exit-active {
overflow: hidden;
max-height: 0;
transition: max-height .45s cubic-bezier(0, 1, 0, 1)
}

.p-menuitem-text {
flex-grow: 1;
margin-left: 12px;
}
.layout-topbar-icons button {
  margin-left     : 5px;
}



.field > label {
    display: inline-block;
    margin-bottom: 0.2rem;
}

.layout-topbar .layout-topbar-icons {
  float: right;
  font-size: 15px;
  display: block;
  animation-duration: 0.5s;
  /* padding-top: 16px; */
}

.layout-topbar .layout-topbar-icons .pi {
 font-size: 18px;
}
p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: var(--gray-100);
}

.p-datatable .p-datatable-tbody > tr {
  background:var(--primary-color-text);
}
.p-toolbar-group-end p-toolbar-group-right{
  margin-left: 10px;
}
.p-datatable .p-column-header-content {
  display: block;
  
}
.layout-profile img {
 border-radius: 5px;
  max-height: 100px;
}
.profile-branch{
  text-align: center;
}
.profile-branch > .p-link{
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--clr-heading);
  margin-bottom: 5px;
}



/* General */
/* Menu Common */
/* Menu Light */
/* Menu Dark */
/* Topbar */
/* Footer */
.splash-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: var(--primary-color); 
  background: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light) 100%); 
}
.splash-screen .splash-container {
  width: 40px;
  height: 40px;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.splash-screen .splash-double-bounce1, .splash-screen .splash-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: splash-bounce 2s infinite ease-in-out;
}
.splash-screen .splash-double-bounce2 {
  animation-delay: -1s;
}
@keyframes splash-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

html {
  height: 100%;
}

body {
  font-family: "Open Sans", Helvetica Neue, sans-serif;
  font-size: 12.5px;
  color: #343a40;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  
    overflow: hidden;

}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.p-dropdown-label {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px !important;
}

.p-dropdown-item {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px !important;
  z-index: 1000001;
}

.datatable-header {
  border-color: 1px #dedede !important;
}

.p-datatable .p-sortable-column-icon {
  color: var(--clr-text) !important;
}

.p-datatable table {
  min-width: 150px;
}

.p-datatable-header {
  display: inline-block;
  width: 100%;
  margin-bottom: -4px;
}
.p-datatable-header h3 {
  font-weight: 500;
  padding-top: 0px;
  margin: 1px;
  font-size: 15px;
  text-align: center !important;
}

.p-treetable .p-sortable-column-icon {
  vertical-align: middle;
  color: var(--clr-text) !important;
}

.p-treetable-footer {
  background-color: #fdfefe !important;
  min-height: 42px;
  vertical-align: middle;
}

.swal2-container {
  z-index: 100005;
}

.p-dialog .p-dialog-titlebar {
  padding: 0.5em 0.5em !important;
  border: 0;
  padding-left: 0px;
}

.p-dialog {
  position: absolute !important;
  padding: 0 !important;
  padding-left: -40px !important;
  z-index: 100001;
}

.p-dialog-footer {
  padding: 0em !important;
  border-width: 0 0 0 0;
  text-align: right;
}

.p-dialog .p-dialog-content {
  position: relative;
  border: 0;
  padding: -10px !important;
  zoom: 1;
  overflow-y: auto;
}

.p-tree {
  width: 100%;
  height: 100%;
  text-align: left !important;
  font-size: 12px !important;
}

.tree-selection-scroll-panel {
  width: 100%;
  height: 300px;
}

.p-inputgroup {
  padding: 0px;
  margin-bottom: -1px !important;
  float: left;
}

.p-toolbar-group-left {
  width: 100%;
}
.p-toolbar-group-left h2 {
  padding: 4px 2px 0px 2px;
  vertical-align: bottom !important;
  font-size: 15px;
}

.p-toolbar-group-right {
  float: right;
}

.vnheader {
  display: flex;
}

.p-datepicker .p-datepicker-header {
  position: relative;
  padding: 0.5em !important;
}

.p-datepicker .p-datepicker-month {
  position: relative;
  padding: 0.75em !important;
}

.p-datepicker .p-datepicker-year {
  position: relative;
  padding: 0.25em !important;
  margin-left: 0.25em !important;
  width: 80px;
  border-color: #d8d8d8;
}

.p-datepicker th {
  padding: 0.2em !important;
  text-align: center;
  border: 0;
}

.p-timepicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5em 0;
  font-size: 11px !important;
}

.p-timepicker > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.25em;
  min-width: 1.5em;
}
/* 
.p-menubar {
  padding: 6px;
  margin: -1px;
  background: var(--surface-900) !important;
}

.p-menubar .p-menuitem-text {
  vertical-align: middle;
  font-size: 12.5px;
  font-weight: 550;
  padding: 0%;
}

.p-menubar .p-menubar-root-list {
  display: contents;
  position: relative;
}

.p-menubar .p-submenu-list {
  display: none;
  position: absolute;
  min-width: 15em;
  padding: 0px !important;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin-top: -1px !important;
  margin-bottom: -4px !important;
  position: relative;
  padding-top: 0px !important;
} */

.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0px !important;
  padding-left: 6px !important;
  max-width: 64px !important;
  height: 18px;
  cursor: pointer;
  white-space: nowrap;
  display: block;
  font-size: 11px;
}

.p-fieldset {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
}

.p-fieldset .p-fieldset-legend {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.cs-panel {
  width: 100%;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -5px;
}

.cs-panel-section {
  overflow-y: auto;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -5px;
}

.cs-tab-menu {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 2px;
}

.cs-tab-menu-item {
  font-weight: bold;
  background-color: blue !important;
}

.cs-tab-menu-item-disabled {
  background-color: #fefefe;
}

.cs-dialog {
  z-index: 100001;
  max-width: 1050px;
  max-height: 675px;
}

.cs-dialog-1 {
  min-height: 200px;
}

.cs-dialog-form-item {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100%;
  margin-top: -5px;
  margin-left: -5px;
  margin-right: -5px;
  border-radius: 3px;
}

.cs-details-section-1 {
  position: relative;
  justify-items: center;
  width: 100%;
  min-width: 350px !important;
  min-height: 100px;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.cs-details-section {
  justify-items: center;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-details-first-section {
  justify-items: center;
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-details-even-section {
  justify-items: center;
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: white;
}

.cs-details-odd-section {
  justify-items: center;
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-details-last-section {
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-top: 0.5px solid #d8d8d8;
  border-bottom: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  background-color: white;
}

.cs-details-section-control {
  width: 95%;
  min-width: 170px;
  height: 35px;
  text-align: left;
  margin-top: 0.15em;
  margin-bottom: 0.15em;
  margin-left: 0em;
  margin-right: 0em;
  font-size: 13px !important;
}
.cs-details-section-control .p-dropdown-panel {
  font-size: 13px !important;
  z-index: 1000001;
}
.cs-details-section-control .p-dropdown-item {
  position: relative;
  font-weight: normal;
  font-size: 13px !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  border: 0 none;
  cursor: pointer;
  margin: 1px 0;
  padding: 0.125em 0.15em;
  z-index: 1000001;
}
.cs-details-section-control .p-dropdown-label {
  display: block;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
  font-size: 13px !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  padding-right: 1.5em;
}

.cs-details-section-control-1 {
  width: 100%;
  min-width: 250px;
  height: 35px;
  text-align: left;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  margin-left: 0em;
  margin-right: 0em;
  font-size: 13px !important;
}
.cs-details-section-control-1 .p-dropdown-panel {
  font-size: 13px !important;
  z-index: 1000001;
}
.cs-details-section-control-1 .p-dropdown-item {
  position: relative;
  font-weight: normal;
  font-size: 13px !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  border: 0 none;
  cursor: pointer;
  margin: 1px 0;
  padding: 0.125em 0.15em;
  z-index: 1000001;
}
.cs-details-section-control-1 .p-dropdown-label {
  display: block;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
  font-size: 13px !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  padding-right: 1.5em;
}

.cs-details-section-text-area {
  width: 95%;
  height: 99px;
  resize: none;
}

.p-inputtextarea {
  font-size: 13px !important;
}

.cs-details-section-label {
  height: 15px;
  width: 100%;
  text-align: left;
  padding-left: 10px;
}

.cs-dialog-footer {
  width: 100%;
  align-content: right;
  padding: 4px;
  background-color: #f1f1f1;
}

.cs-cli-script-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.cs-cli-script {
  width: 40% !important;
  height: 100%;
  min-height: 300px;
  overflow-y: auto;
  padding: 10px;
  background-color: #f1f1f1;
  margin-right: 10px;
  resize: none;
}

.cs-cli-criteria {
  width: 60%;
  height: 100%;
  overflow-y: auto;
}

.cs-query-group {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  width: 100%;
}

.cs-query {
  width: 40%;
  height: 100%;
  overflow-y: auto;
  margin-right: 10px;
}

.cs-query-script {
  width: 100% !important;
  height: 100%;
  min-height: 270px;
  overflow-y: auto;
  padding: 10px;
  background-color: #f1f1f1;
  resize: none;
}

.cs-query-criteria {
  width: 60%;
  height: 100%;
  overflow-y: auto;
}

.cs-query-form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-top: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-query-section-label {
  height: 15px;
  text-align: left;
  margin-right: 10px;
}

.cs-query-section-control {
  width: 220px;
  height: 32px;
}

.cs-details-auto-complete {
  width: 220px !important;
}

.cs-query-form-item-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  border-top: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-dao-form-item-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  border-top: 0.5px solid #d8d8d8;
  border-right: 0.5px solid #d8d8d8;
  border-left: 0.5px solid #d8d8d8;
  background-color: #fafafa;
}

.cs-dao-form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cs-dao-section-label {
  height: 15px;
  padding-right: 10px;
  text-align: left;
  padding-left: 15px;
}

.p-multiselect {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
  z-index: 1000001;
}

.p-multiselect .p-multiselect-label {
  display: block;
  padding: 0.25em 2em 0.25em 0.25em;
  border: none;
  font-weight: normal;
  font-size: 12px;
  width: 100%;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1000001;
}

.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
  z-index: 1000001;
}

.p-accordion {
  width: 100%;
}

.p-accordion .p-accordion-header-text {
  vertical-align: middle;
  font-size: 12.5px;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.p-accordion .p-accordion-toggle-icon {
  vertical-align: middle;
  margin-left: -10px;
}

.p-accordion .p-accordion-content {
  width: 100%;
  padding: 0em;
  border-top: 0;
  zoom: 1;
}

.layout-topbar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.4em 1.5em 0em 1.5em;
  color: var(--clr-text);
  z-index: 999;
  right: 0;
  background-image: linear-gradient(to left, var(--primary-color), var(--primary-light));  
  transition: left 0.2s;
}
.layout-topbar:after {
  content: "";
  display: table;
  clear: both;
}
.layout-topbar .layout-navigation {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-left: 40%;
}
.layout-topbar .layout-navigation span.layout-topbar-item-text {
  padding-left: 20px;
  font-size: 24px;
  margin-left: 5px;
  margin-top: -4px;
}
.layout-topbar .layout-topbar-icons {
  float: right;
  display: block;
  animation-duration: 0.5s;
  /* padding-top: 16px; */
}
.layout-topbar .layout-topbar-icons button {
  position: relative;
  color: var(--clr-text);
  display: inline-block;
  text-decoration: none;
  overflow: visible;
  transition: color 0.2s;
}
.layout-topbar .layout-topbar-icons button:hover {
  color: var(--clr-heading);
}

.layout-topbar .layout-topbar-icons button span.layout-topbar-icon {
  font-size: 26px;
}
.layout-topbar .layout-topbar-icons button span.layout-topbar-item-text {
  /* padding-bottom: 5px; */
  /* vertical-align: top; */
  font-size: 14px;
  display: none;
}
.layout-topbar .layout-topbar-icons button span.layout-topbar-badge {
  position: absolute;
  font-size: 10px;
  right: -5px;
  top: -5px;
  width: 12px;
  height: 13px;
  text-align: center;
  line-height: 12px;
  color: #ffffff;
  background-color: #ef6262;
  border-radius: 50%;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search {
  padding: 0;
  position: relative;
  display: inline-block;
  top: -4px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input {
  display: inline-block;
  border: 0 none;
  font-size: 13px;
  background: transparent;
  border-bottom: 2px solid #ffffff;
  outline: 0 none;
  box-shadow: none;
  color: #ffffff;
  width: 100px;
  padding: 1px 20px 1px 1px;
  margin: 0px;
  border-radius: 2px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -ms-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon {
  font-size: 18px;
  position: absolute;
  top: -1px;
  right: 0px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input {
  border-bottom-color: #c3e8fb;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-webkit-input-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-moz-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-moz-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-ms-input-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover .layout-topbar-search-icon {
  color: #c3e8fb;
}
.layout-topbar .layout-menu-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: var(--clr-text);
  transition: color 0.2s;
}
.layout-topbar .layout-menu-button span {
  vertical-align: middle;
  font-size: 18px;
}
.layout-topbar .layout-menu-button:hover {
  color: #c3e8fb;
}
.layout-topbar button {
  cursor: pointer;
}

.layout-sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: left 0.2s;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.layout-sidebar .layout-logo {
  text-align: center;
  margin-top: 24px;
}
.layout-sidebar .menuitem-badge {
  display: inline-block;
  margin-left: 4px;
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: rgb(59, 70, 223);
  background-color: #007be5;
  border-radius: 50%;
}

.layout-profile {
  text-align: center;
  padding-top: 7px;
  padding-right: 5px;
  padding-bottom: 2px;
}
.layout-profile img {
  max-height: 100px;
}
.layout-profile .layout-profile-link {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0.5em;
  transition: color 0.2s;
}
.layout-profile .layout-profile-link i {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}
.layout-profile ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-profile ul.layout-profile-expanded {
  max-height: 500px;
}
.layout-profile ul li button {
  width: 100%;
  padding: 1em;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
  transition: color 0.2s;
}
.layout-profile ul li button:hover {
  color: #ffffff;
}
.layout-profile ul li button span {
  margin-left: 0.25em;
  vertical-align: middle;
}
.layout-profile ul li button i {
  vertical-align: middle;
}

.profile-user {
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer;
}
.profile-user button {
  color: var(--clr-text);
  text-align: center;
  font-weight: bold !important;
}

.layout-sidebar-light .layout-profile .layout-profile-link {
  color: #000000;
}
.layout-sidebar-light .layout-profile .layout-profile-link:hover {
  color: #ffffff;
}
.layout-sidebar-light .layout-profile ul {
  background-color: var(--primary-color);
}
.layout-sidebar-light .layout-profile ul li button {
  color: #000000;
}
.layout-sidebar-light .layout-profile ul li button:hover {
  color: #ffffff;
}

.layout-sidebar-dark .layout-profile .layout-profile-link {
  color: #ffffff;
}
.layout-sidebar-dark .layout-profile .layout-profile-link:hover {
  color:var(--primary-color);
}
.layout-sidebar-dark .layout-profile ul {
  background-color: #2e3035;
}
.layout-sidebar-dark .layout-profile ul li button {
  color: #ffffff;
}
.layout-sidebar-dark .layout-profile ul li button:hover {
  color:var(--primary-color);
}

.layout-menu-container {
  padding-bottom: 40px;
}

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.layout-menu li a {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  display: block;
  transition: color 0.2s;
}
.layout-menu li a i {
  font-size: 16px;
  vertical-align: middle;
}
.layout-menu li a span {
  margin-left: 0.25em;
  padding-left: 5px;
  vertical-align: middle;
  line-height: 16px;
  display: inline-block;
}
.layout-menu li a .menuitem-toggle-icon {
  position: absolute;
  top: 50%;
  right: 0.2em;
  margin-top: -9px;
}
.layout-menu li a.router-link-active {
  font-weight: 700;
}
.layout-menu li.active-menuitem > a .menuitem-toggle-icon:before {
  content: "\e933";
}
.layout-menu li.active-menuitem > ul {
  max-height: 500px;
}
.layout-menu > li > a {
  padding: 1em;
}
.layout-menu > li > a span {
  font-size: 12.5px;
}
.layout-menu > li:last-child > a {
  border-bottom: 1px solid #ffffff;
}
.layout-menu > li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 1.5em;
  max-height: 0;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-menu > li ul li a {
  cursor: pointer;
  padding: 0.6em 0.6em;
  border-top: 0 none;
}
.layout-menu > li ul li a span {
  font-size: 12.5px;
}
.layout-menu > li ul li:last-child {
  padding-bottom: 0.6em;
}

.layout-sidebar-light {
  background: var(--primary-color, 50%) ; 
  background: linear-gradient(to bottom, var(--primary-light) 25%,  var(--primary-color)  ) ; 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#000000",GradientType=0 ); 
  z-index: 9;
}
.layout-sidebar-light .layout-menu > li > a {
  border-top: 1px solid #ffffff;
}
.layout-sidebar-light .layout-menu > li:last-child > a {
  border-bottom: 1px solid #ffffff;
}
.layout-sidebar-light .layout-menu > li ul {
  background-color: rgb(59, 152, 223);
}
.layout-sidebar-light .layout-menu li a {
  color: #000000;
}
.layout-sidebar-light .layout-menu li a.router-link-active {
  color: #1fa1fc;
}
.layout-sidebar-light .layout-menu li a:hover {
  color: #ffffff;
}
.layout-sidebar-light .layout-menu li.active-menuitem > a {
  background-color: rgb(59, 152, 223);
  color: #ffffff;
}

.layout-sidebar-dark {
  background: #4d505b; 
  background: linear-gradient(to bottom, #4d505b 0%, #3b3e47 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#000000",GradientType=0 ); 
}
.layout-sidebar-dark .layout-menu > li > a {
  border-top: 1px solid rgba(52, 56, 65, 0.6);
}
.layout-sidebar-dark .layout-menu > li:last-child > a {
  border-bottom: 1px solid rgba(52, 56, 65, 0.6);
}
.layout-sidebar-dark .layout-menu > li ul {
  background-color: #2e3035;
}
.layout-sidebar-dark .layout-menu li a {
  color: #ffffff;
}
.layout-sidebar-dark .layout-menu li a.router-link-active {
  color: #1fa1fc;
}
.layout-sidebar-dark .layout-menu li a:hover {
  color:var(--primary-color);
}
.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  background-color: #2e3035;
  color:var(--primary-color);
}

.layout-main {
  position: fixed;
  padding-left: 3px;
  padding-right: 3px !important;
  padding-bottom: 3px;
  top: 60px !important;
  left: 1px;
  right: 2px;
  overflow-x: auto;
  overflow-y: auto;
  height: 92%;
  min-width: calc(100% - 250px);
  background-color: #ffffff;
  transition: margin-left 0.2s;
}

.container {
  display: flex;
}

.header-photo {
  font-size: 8px;
  width: 18px !important;
  height: 16px;
  position: relative;
  margin: 1px !important;
  top: -60px;
  right: -5px;
}

.cs-image-box {
  border: 0.005ex solid #ababec !important;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
  max-height: 150;
  max-width: 150;
  display: flex;
  flex-direction: column;
}

.cs-image-buttonbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.cs-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cs-image-container .header-photo-1 {
  width: 20px !important;
  height: 20px;
  margin: 5px;
}

.cs-image-container .header-photo-2 {
  width: 20px !important;
  height: 20px;
  margin: 5px;
}

.cs-image {
  width: 550px;
  height: 140px;
}

.form-layout {
  flex: 1;
  overflow-y: scroll;
}

.form-criteria {
  display: flex;
  background-color: #ffffff;
  padding: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center !important;
  border-radius: 3px;
}

.form-item {
  margin: -7px;
  padding: 1px !important;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100%;
  /* //    max-width       : 400px; */
  margin: auto;
  border-radius: 3px;
}

.form-item-1 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  border-radius: 3px;
}

.form-item-2 {
  margin-top: 0px !important;
  margin-bottom: -10px !important;
  margin-left: -10px !important;
  margin-right: -10px !important;
  padding: 2px;
  overflow: auto;
  height: 400px;
  min-width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}

.form-item-3 {
  margin-top: -13px !important;
  margin-left: -4px !important;
  margin-right: -8px !important;
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100%;
  margin: auto;
  border-radius: 3px;
}

.form-item-4 {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100% !important;
  border-radius: 3px;
}

.form-item-5 {
  margin-left: -2px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100% !important;
  border-radius: 3px;
}

.form-item-6 {
  margin-top: 5px !important;
  margin-left: 3px !important;
  margin-right: 1px !important;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100% !important;
  min-width: 300px;
  border-radius: 3px;
}

.form-item-7 {
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100% !important;
  border-radius: 3px;
}

.form-item-8 {
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  max-width: 269px;
  width: 100% !important;
  border-radius: 3px;
}

.form-item-9 {
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: 100% !important;
  height: 90% !important;
  border-radius: 3px;
}

.cs-grid-data {
  font-size: 12.5px !important;
  overflow-x: auto !important;
}
.cs-grid-data .p-datatable-scrollable-body {
  padding-bottom: 0px !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.cs-grid-data .p-datatable-frozenlayout-left {
  width: 150px !important;
}
.cs-grid-data .p-datatable-footer {
  height: 45px !important;
  background-color: #edf0f5 !important;
}

.accordion-tab {
  overflow-y: auto;
  max-height: 300px;
  font-size: 11.5px;
}

.row {
  display: flex;
  z-index: 999;
}

.column {
  flex: 1;
  position: relative;
  min-width: 800px;
  min-height: 400px;
  z-index: 999;
}

#ID_ZOOM_CLIENT {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.form_control_label {
  display: block;
  font-size: 12.5px;
  font-weight: 500;
  text-align: left;
}

.form_section_label {
  display: block;
  font-size: 12.5px;
  font-weight: 500;
  text-align: right;
}

.form-item-row-3 {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  justify-content: center;
}

.form-item-row {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  max-width: 400px;
  justify-content: center;
  width: 100%;
}

.form-item-row-1 {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  width: 100%;
  max-width: 400px;
  justify-content: left;
}

.form-item-row-2 {
  margin-top: 0px;
  height: 35px !important;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  width: 100% !important;
  vertical-align: top !important;
  justify-content: center;
}

/*
.form-label
{
    padding             : 0.25em;
    margin              : 0;
    display             : flexbox;
    flex-direction      : column;
    justify-content     : space-around;
}
*/
.cs-date-field {
  font-size: 12.5px;
  height: 34px;
  width: 100%;
  align-items: center;
}

.cs-df-input {
  width: 80%;
}

.cs-text-input {
  font-size: 12.5 px;
  height: 34px;
  width: 100%;
}

.cs-password {
  font-size: 12.5px;
  height: 34px;
  width: 100%;
}

.cs-text-area {
  font-size: 12.5px !important;
  width: 100%;
  color: rgba(0, 0, 0, 0.2666666667);
}

.cs-tel-font {
  font-family: "AnupamaMedium", serif;
  font-size: "20px";
}

.cs-drop-down {
  font-size: 12.5px;
  height: 34px;
  width: 100%;
}

.cs-multi-select {
  font-size: 12.5px;
  height: 34px;
  width: 100%;
}

.cs-time-field {
  font-size: 12.5px;
  height: 34px;
  width: 100%;
}

.cs-checkbox {
  font-size: 12.5px;
  height: 50px;
  width: 100%;
}

.cs-control-label {
  justify-content: left;
  margin: 0;
  display: flexbox;
  margin-bottom: 0.2rem;
}

.cs-control {
  margin: 0.25em;
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.form-vsection1 {
  align-items: center;
}

.criteria-action {
  width: 120px !important;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.form-hsection {
  /* border: 0.005ex solid #dfdfff !important; */
  /* background-color: #f9f9f9; */
  border-collapse: collapse !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.form-vsection {
  /* border: 0.005ex solid #dfdfff !important; */
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: column;
  text-align: left;
  border-collapse: collapse !important;
  font-size-adjust: inherit;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
}

.form-align {
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.form-control {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
}

.rc-control-1 {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
  height: 70px;
}

.form-control {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
}

.calendar-control {
  height: "28px";
  width: "100%";
  font-size: "13px";
  margin: "0.25em";
}

.calendar-icon {
  height: "28px";
  margin: "0.15em";
}

.form-label {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  text-align: left;
  align-items: center;
}

.background {
  background-color: #f8f8f8;
  margin: -7px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  padding-right: 1px !important;
}

.login-form-item {
  border-radius: 3px;
  margin: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
  display: flex;
  vertical-align: middle !important;
  align-items: center;
  justify-content: center;
}

.carousel-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1.5px solid #a2bbd1;
  padding-top: 10px;
  margin-top: 4px;
  margin-left: 3px;
  margin-right: 8px;
}

.login-ins-form {
  margin: 1em 0 0.5em 0;
  border-top: 1px solid #d5d5d5;
  padding: 0.1em;
  margin-top: 30px;
  padding-top: 10px;
}

.login-form {
  margin: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form h1 {
  margin: 1em 0 0.5em 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 0.1em;
  font-size: 20px;
  text-align: center !important;
  height: 50px;
}
.login-form h1:first-child {
  margin: 0 0 0.5em 0;
}
.login-form h2 {
  margin: 15px;
  font-size: 17px;
  text-align: center !important;
  padding-bottom: 5px;
}
.login-form h3 {
  font-size: 13px;
  font-weight: normal;
  color: crimson;
  text-align: center !important;
  padding-top: 5px;
  padding-bottom: 0px;
}

.swal2-container {
  z-index: 100000000000 !important;
}
.swal2-container .swal2-icon {
  zoom: 0.8;
}
.swal2-container .swal2-popup {
  zoom: 0.9;
}
.swal2-container .swal2-title {
  font-size: 28px;
}
.swal2-container .swal2-content {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
}

.p-picklist .p-picklist-list {
  min-width: 300px !important;
}

.p-picklist .p-button {
  height: 28px;
  width: 60px !important;
  margin-bottom: 0.5em;
}

.invoice {
  position: relative;
  background-color: #FFF;
  min-height: 680px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  max-width: 22cm;
}

.invoice .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.invoice .company-details {
  text-align: left;
  width: 38%;
}
.invoice .company-details div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.invoice .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
}
.invoice .company-details .name h2 {
  color: var(--primary-color);
}

.invoice .company-contact {
  margin-left: 10px;
  padding-left: 10px;
  text-align: left;
  margin: auto;
  width: 35%;
}
.invoice .company-contact div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.invoice .logo {
  margin-left: 10px;
  text-align: left;
  width: 25%;
  margin: auto;
}

.invoice header {
  padding: 10px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--primary-color);
}

.invoice .title {
  width: 100%;
  height: 50px;
  margin: auto;
  padding: 8px;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #c2dff5;
}
.invoice .title h1 {
  font-weight: bold;
  color: var(--primary-color);
}

.invoice .summary {
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}

.invoice .customer-details {
  text-align: left;
  padding: 10px;
  width: 40%;
  background-color: #fafafa !important;
}
.invoice .customer-details div {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}

.invoice .challan-details {
  text-align: left;
  padding: 10px;
  width: 40%;
  background-color: #ffffff;
}
.invoice .challan-details div {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}

.invoice .customer-name {
  margin-top: 2px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color:var(--primary-color);
  display: flex;
  justify-content: center;
  border-bottom: solid 1px lavender;
}

.invoice .challan-name {
  margin-top: 2px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  border-bottom: solid 1px lavender;
}

.invoice .qrcode {
  margin: auto;
  width: 20%;
  height: 100% !important;
  display: grid;
  justify-items: center;
}

.invoice .terms-conditions {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #c2dff5;
  padding-top: 10px;
  margin-top: 10px;
}

.invoice .terms {
  padding: 10px;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  width: 70%;
}
.invoice .terms div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.invoice .conditions {
  padding: 10px;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  width: 30%;
  display: grid;
  align-items: center;
  font-size: 13px;
}

.invoice .terms-heading {
  margin-top: 2px;
  margin-bottom: 5px;
  color:var(--primary-color);
}

.invoice .items {
  width: 100%;
  border-collapse: collapse;
}

.invoice .items th {
  background-color: #b1cfe6;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  height: 40px;
}

.invoice .items td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.invoice .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.invoice footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 1px solid #aaa;
  padding: 8px 0;
  font-size: 13px;
}

.ms {
  position: relative;
  background-color: #FFF;
  min-height: 680px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  width: 22cm;
}

.ms .column {
  padding: 2px;
  display: flex;
  flex-direction: column;
}

.ms .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.ms .logo {
  text-align: left;
  width: 18%;
  margin: auto;
}

.ms header {
  padding: 5px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--primary-color);
}

.ms .header-heading {
  width: 100%;
  height: 40px;
  margin: auto;
  padding: 2px;
  text-align: center;
}
.ms .header-heading h1 {
  font-weight: bold;
  font-size: 24px;
}

.ms marks-details {
  width: 100%;
  border-collapse: collapse;
  padding-bottom: 20px !important;
}

.ms .title {
  padding-top: 10px;
  -moz-text-align-last: center;
       text-align-last: center;
  height: 40px;
}
.ms .title h1 {
  font-weight: bold;
  font-size: 20px;
  height: 18px;
}

.ms .customer-details {
  text-align: center;
  width: 100%;
}
.ms .customer-details div {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}

.ms .items {
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
}

.ms .items th {
  background-color: #f9fcff;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  height: 40px;
}

.ms .items td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.ms .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ms footer {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 0.5px solid #aaa;
  padding: 8px 0;
  font-size: 13px;
}

.report-title {
  padding-top: 20px;
  font-size: 18px !important;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #c2dff5;
}

.report-table {
  width: 98%;
  border-collapse: collapse;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.report-table th {
  background-color: #b1cfe6;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  height: 40px;
}

.report-table td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

report-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.so1 {
  width: 21.2cm;
  height: 29.7cm;
  align-items: center;
  position: relative;
  background-color: #FFF;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.so1 footer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.so1 .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.so1 .company-details {
  text-align: left;
  width: 70%;
}
.so1 .company-details div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.so1 .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--primary-color);
}

.so1 .company-contact {
  margin-left: 10px;
  padding-left: 10px;
  text-align: left;
  margin: auto;
  width: 35%;
}
.so1 .company-contact div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.so1 .logo {
  margin-left: 10px;
  text-align: left;
  width: 25%;
  margin: auto;
}

.so1 header {
  padding: 10px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--primary-color);
}

.so1 .title {
  width: 100%;
  height: 50px;
  margin: auto;
  padding: 8px;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #c2dff5;
}
.so1 .title h1 {
  font-weight: bold;
  font-size: 18px;
  color: var(--primary-color);
}

.so1 .sum-1 {
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}
.so1 .sum-1 .summary-1 {
  width: 70%;
  font-size: 12.5px;
}
.so1 .sum-1 .summary-2 {
  width: 100%;
  font-size: 12.5px;
}
.so1 .sum-1 .form-item-row {
  height: 40px;
}

.so1 .sum-2 {
  margin: auto;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}
.so1 .sum-2 .summary-1 {
  width: 70%;
  font-size: 12.5px;
}
.so1 .sum-2 .summary-1 .form-item-row {
  vertical-align: top;
  font-size: 12.5px;
  height: 40px;
  width: 600px;
}
.so1 .sum-2 .summary-2 {
  width: 100%;
  font-size: 13px;
}
.so1 .sum-2 .summary-2 .form-item-row {
  height: 40px;
}

.so1 .order-details {
  padding-bottom: 20px;
  border-bottom: 1px solid #c2dff5;
}
.so1 .order-details .items {
  width: 100%;
  border-collapse: collapse;
}

.so1 .items th {
  background-color: #d6e7f4;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12.5px;
  height: 36px;
}

.so1 .items td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12.5px;
  height: 30px;
}

.so1 .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.so1 .terms {
  padding-top: 10px;
  padding-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}
.so1 .terms .terms-heading {
  font-size: 14px;
}
.so1 .terms .terms-seq {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  width: 40px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
}
.so1 .terms .terms-det {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  height: 30px;
  width: 95%;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
}

.po1 {
  width: 21.2cm;
  height: 29.7cm;
  align-items: center;
  position: relative;
  background-color: #FFF;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.po1 footer {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.po1 .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.po1 .company-details {
  text-align: center;
  width: 70%;
}
.po1 .company-details div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.po1 .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
  color:var(--primary-color);
}

.po1 .company-contact {
  margin-left: 10px;
  padding-left: 10px;
  text-align: left;
  margin: auto;
  width: 35%;
}
.po1 .company-contact div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.po1 .logo {
  margin-left: 10px;
  text-align: left;
  width: 25%;
  margin: auto;
}

.po1 header {
  padding: 10px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--primary-color);
}

.po1 .title {
  width: 100%;
  height: 50px;
  margin: auto;
  padding: 8px;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #c2dff5;
}
.po1 .title h1 {
  font-weight: bold;
  font-size: 18px;
  color: var(--primary-color);
}

.po1 .order-details-title {
  text-align: center;
}

.po1 .sum-1 {
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}
.po1 .sum-1 .summary-1 {
  width: 50%;
  font-size: 12.5px;
  justify-content: center;
  align-items: center;
}
.po1 .sum-1 .summary-1 .summary-title {
  text-align: center;
  text-decoration: underline;
}
.po1 .sum-1 .summary-2 {
  width: 50%;
  font-size: 12.5px;
  justify-content: center;
  align-items: center;
}
.po1 .sum-1 .summary-2 .summary-title {
  text-align: center;
  text-decoration: underline;
}
.po1 .sum-1 .form-item-row {
  height: 30px;
  justify-content: flex-start;
}

.po1 .sum-2 {
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}
.po1 .sum-2 .summary-1 {
  font-size: 12.5px;
}
.po1 .sum-2 .summary-1 .form-item-row {
  height: 40px;
  width: 600px;
  font-size: 12.5px;
}
.po1 .sum-2 .summary-2 {
  width: 50%;
  font-size: 12.5px;
}
.po1 .sum-2 .summary-2 .form-item-row {
  height: 40px;
}

.po1 .order-details {
  padding-bottom: 20px;
  border-bottom: 1px solid #c2dff5;
}
.po1 .order-details .items {
  width: 100%;
  border-collapse: collapse;
}

.po1 .items th {
  background-color: #d6e7f4;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12.5px;
  height: 36px;
}

.po1 .items td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12.5px;
  height: 30px;
}

.po1 .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.po1 .terms {
  padding-top: 10px;
  padding-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}
.po1 .terms .terms-heading {
  font-size: 14px;
}
.po1 .terms .terms-seq {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  width: 40px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
}
.po1 .terms .terms-det {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  height: 30px;
  width: 95%;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
}

.dc {
  position: relative;
  background-color: #FFF;
  min-height: 680px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  max-width: 22cm;
}

.dc .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.dc .company-details {
  text-align: left;
  width: 70%;
}
.dc .company-details div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.dc .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--primary-color);
}

.dc .company-contact {
  margin-left: 10px;
  padding-left: 10px;
  text-align: left;
  margin: auto;
  width: 35%;
}
.dc .company-contact div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.dc .logo {
  margin-left: 10px;
  text-align: left;
  width: 25%;
  margin: auto;
}

.dc header {
  padding: 10px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--primary-color);
}

.dc .title {
  width: 100%;
  height: 50px;
  margin: auto;
  padding: 8px;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #c2dff5;
}
.dc .title h1 {
  font-weight: bold;
  font-size: 17px;
  color: var(--primary-color);
}

.dc .summary {
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2dff5;
  background-color: #fafafa !important;
}

.dc .customer-details {
  text-align: left;
  padding: 10px;
  width: 45%;
  background-color: #fafafa !important;
}
.dc .customer-details div {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}

.dc .challan-details {
  text-align: left;
  padding: 10px;
  width: 40%;
  background-color: #ffffff;
}
.dc .challan-details div {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}

.dc .package-contents-title {
  color:var(--primary-color);
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
}

.dc .items .package-challan-no-title {
  margin-top: 0px;
  padding-top: -10px;
  height: 20px;
}

.dc .customer-name {
  margin-top: 2px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  border-bottom: solid 1px lavender;
}

.dc .challan-name {
  margin-top: 2px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  border-bottom: solid 1px lavender;
}

.dc .qrcode {
  margin: auto;
  width: 15%;
  height: 100% !important;
  display: grid;
  justify-items: center;
}

.dc .terms-conditions {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #c2dff5;
  padding-top: 10px;
  margin-top: 10px;
  border-collapse: collapse;
}

.dc .terms {
  padding: 10px;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  width: 70%;
}
.dc .terms div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: initial;
}

.dc .conditions {
  padding: 10px;
  border-right: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  width: 30%;
  display: grid;
  align-items: center;
  font-size: 12px;
}

.dc .terms-heading {
  margin-top: 2px;
  margin-bottom: 5px;
  color: var(--primary-color);
}

.dc .items {
  width: 100%;
  border-collapse: collapse;
}

.dc .shipment-contents {
  padding-bottom: 20px;
  border-bottom: 1px solid #c2dff5;
}

.dc .items th {
  background-color: #d6e7f4;
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  height: 32px;
}

.dc .items td {
  border-bottom: 1px solid #c8d8d8;
  border-left: 1px solid #c8d8d8;
  border-top: 1px solid #c8d8d8;
  border-right: 1px solid #c8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
}

.dc .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.dc footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 0.5px solid #aaa;
  padding: 8px 0;
  font-size: 12px;
}

.rf {
  position: relative;
  min-height: 600px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1.25px solid #a8a8a8;
}

.rf .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.rf .title {
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
}
.rf .title h1 {
  font-size: 17px;
  font-weight: bold;
}
.rf .title h2 {
  padding-left: 18%;
  padding-right: 18%;
  font-size: 12px;
}

.rf .top {
  flex-direction: row;
  padding-top: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  display: flex;
}
.rf .top .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.rf .top .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  width: 120px;
}

.rf .summary {
  margin-top: 10px;
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.rf .student-details {
  text-align: left;
  width: 41%;
  padding-left: 5px;
  align-items: center !important;
}
.rf .student-details div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: left;
}

.rf .receipt-details {
  text-align: left;
  vertical-align: middle;
  width: 41%;
  padding-top: 8px;
  padding-bottom: 7px;
  border-left: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  padding-left: 5px;
}
.rf .receipt-details div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  justify-content: left;
}
.rf .receipt-details form-item-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.rf .qrcode {
  width: 18%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  justify-items: center;
}

.rf .payment-details {
  padding-top: 15px;
  padding-bottom: 15px;
}
.rf .payment-details h2 {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
}

.rf .pmt-amt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.rf .pmt-amt .pmt-words {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  max-width: 400px;
  justify-content: left;
  font-size: 12.5px;
  width: 100%;
}

.rf .items {
  width: 100%;
  border-collapse: collapse;
}

.rf .items th {
  background-color: #efefef;
  border-bottom: 1px solid #a8a8a8;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 25px;
}

.rf .items td {
  border-bottom: 1px solid #a8a8a8;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  padding-left: 7px;
  padding-right: 5px;
  height: 26px;
  font-size: 12px;
}

.rf .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.rf footer {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  border-top: 0.5px solid #aaa;
  padding: 6px 0;
  font-size: 13px;
}

.rf .payment-info {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  margin-top: 3px;
  border-collapse: collapse;
}

.rf .pi-item {
  padding: 2px;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
  text-align: center;
  font-size: 12px;
  width: 19%;
  border-collapse: collapse;
}
.rf .pi-item div {
  font-size: 12px;
}
.rf .pi-item .pi-item-heading {
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  margin-bottom: 2px;
}

.rf .pi-item-accountant {
  border-left: 1px solid #a8a8a8;
  padding-left: 20px;
  width: 24%;
}
.rf .pi-item-accountant .pi-item-heading {
  position: relative;
  width: 24%;
  font-size: 12px;
  font-weight: bold;
  position: static;
  margin-top: 38px;
  margin-left: 20px;
}

.trn_note {
  position: relative;
  background-color: #FFF;
  min-height: 680px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.trn_note .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.trn_note .title {
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 2px solid #d8d8d8;
}
.trn_note .title h1 {
  font-size: 17px;
  font-weight: bold;
  color: #777;
}
.trn_note .title h2 {
  padding-left: 18%;
  padding-right: 18%;
  font-size: 12px;
  color: #777;
}

.trn_note .top {
  flex-direction: row;
  padding-top: 0px;
  margin-top: 5px;
  text-align: center;
  display: flex;
}
.trn_note .top .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: #777;
}
.trn_note .top .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  color: #777;
  width: 120px;
}

.trn_note .summary {
  background-color: #fafafa;
  border-bottom: 2px solid #d8d8d8;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.trn_note .student-details {
  text-align: left;
  width: 50%;
  padding-left: 2px;
}
.trn_note .student-details div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: left;
}

.trn_note .receipt-details {
  text-align: left;
  vertical-align: middle;
  width: 41%;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 2px;
  background-color: #ffffff;
}
.trn_note .receipt-details div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  justify-content: left;
}
.trn_note .receipt-details form-item-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.trn_note .qrcode {
  width: 18%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  justify-items: center;
}

.trn_note .payment-details {
  padding-top: 15px;
  padding-bottom: 15px;
}
.trn_note .payment-details h2 {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
}

.trn_note .pmt-amt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.trn_note .pmt-amt .pmt-words {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  max-width: 400px;
  justify-content: left;
  font-size: 12.5px;
  width: 100%;
}

.trn_note .items {
  width: 100%;
  border-collapse: collapse;
}

.trn_note .items th {
  background-color: #efefef;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 25px;
}

.trn_note .items td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  height: 26px;
  font-size: 12px;
}

.trn_note .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.trn_note footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 0.5px solid #aaa;
  padding: 6px 0;
  font-size: 13px;
}

.trn_note .payment-info {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  margin-top: 3px;
  border-collapse: collapse;
}

.trn_note .pi-item {
  padding: 2px;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  text-align: center;
  font-size: 12px;
  width: 19%;
  border-collapse: collapse;
}
.trn_note .pi-item div {
  font-size: 12px;
}
.trn_note .pi-item .pi-item-heading {
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  margin-bottom: 2px;
}

.trn_note .pi-item-accountant {
  border-left: 1px solid #d8d8d8;
  padding-left: 20px;
  width: 24%;
}
.trn_note .pi-item-accountant .pi-item-heading {
  position: relative;
  width: 24%;
  font-size: 12px;
  font-weight: bold;
  position: static;
  margin-top: 38px;
  margin-left: 20px;
}

.rf5 {
  position: relative;
  min-height: 600px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  border: 1.25px solid #a8a8a8;
}

.rf5 .form-title {
  text-align: center;
}
.rf5 .form-title h2 {
  padding-top: 15px;
  font-size: 13px;
  font-weight: bold;
}

.rf5 .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.rf5 .title {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 2px solid #a8a8a8;
}
.rf5 .title .ins-name {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
}
.rf5 .title h2 {
  font-size: 12.5px;
}

.rf5 .top {
  flex-direction: row;
  padding-top: 0px;
  margin-top: 5px;
  text-align: center;
  display: flex;
}
.rf5 .top .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.rf5 .top .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  width: 120px;
}

.rf5 .summary {
  border-bottom: 2px solid #a8a8a8;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.rf5 .summary-1 {
  text-align: left;
  width: 41%;
  padding-left: 2px;
  align-items: center !important;
}
.rf5 .summary-1 div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: left;
}

.rf5 .summary-2 {
  text-align: left;
  vertical-align: middle;
  width: 41%;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 2px;
}
.rf5 .summary-2 div {
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  justify-content: left;
}
.rf5 .summary-2 form-item-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.rf5 .qrcode {
  width: 18%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  justify-items: center;
}

.rf5 .pmt-amt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.rf5 .pmt-amt .pmt-words {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  max-width: 400px;
  justify-content: left;
  font-size: 12.5px;
  width: 100%;
}

.rf5 .payment-details {
  padding-top: 15px;
  padding-bottom: 15px;
}
.rf5 .payment-details h2 {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
}

.rf5 .items {
  width: 100%;
  border-collapse: collapse;
}

.rf5 .items th {
  background-color: #efefef;
  border-bottom: 1px solid #a8a8a8;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 25px;
}

.rf5 .items td {
  border-bottom: 1px solid #a8a8a8;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  padding-left: 7px;
  padding-right: 5px;
  height: 26px;
  font-size: 12px;
}

.rf5 .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.rf5 footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  border-top: 0.5px solid #aaa;
  padding: 6px 0;
  font-size: 13px;
}

.rf5 .payment-info {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  margin-top: 3px;
  border-collapse: collapse;
}

.rf5 .pi-item {
  padding: 2px;
  border-left: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
  text-align: center;
  font-size: 12px;
  width: 19%;
  border-collapse: collapse;
}
.rf5 .pi-item div {
  font-size: 12px;
}
.rf5 .pi-item .pi-item-heading {
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  margin-bottom: 2px;
}

.rf5 .pi-item-accountant {
  border-left: 1px solid #a8a8a8;
  padding-left: 20px;
  width: 24%;
}
.rf5 .pi-item-accountant .pi-item-heading {
  position: relative;
  width: 24%;
  font-size: 12px;
  font-weight: bold;
  position: static;
  margin-top: 38px;
  margin-left: 20px;
}

.bc1 {
  width: 29.7cm;
  height: 20.8cm;
  align-items: center;
}
.bc1 .std-photo {
  position: absolute;
  right: 155px;
  top: 140px;
}
.bc1 .p0 {
  position: absolute;
  right: 200px;
  top: 285px;
  font-weight: bold;
  font-size: 16px;
}
.bc1 .p1 {
  position: absolute;
  left: 550px;
  top: 340px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc1 .p2 {
  position: absolute;
  left: 400px;
  top: 405px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc1 .p3 {
  position: absolute;
  right: 220px;
  top: 470px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc1 .p4 {
  position: absolute;
  left: 325px;
  top: 537px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc1 .p5 {
  position: absolute;
  left: 280px;
  top: 605px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc1 .p6 {
  position: absolute;
  left: 115px;
  top: 180px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}

.bc2 {
  width: 29.7cm;
  height: 21.2cm;
  align-items: center;
}
.bc2 .std-photo {
  position: absolute;
  right: 155px;
  top: 110px;
}
.bc2 .p0 {
  position: absolute;
  right: 150px;
  top: 257px;
  font-weight: bold;
  font-size: 16px;
}
.bc2 .p1 {
  position: absolute;
  left: 550px;
  top: 307px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p2 {
  position: absolute;
  left: 350px;
  top: 370px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p3 {
  position: absolute;
  right: 220px;
  top: 435px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p4 {
  position: absolute;
  left: 325px;
  top: 500px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p5 {
  position: absolute;
  left: 290px;
  top: 565px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p6 {
  position: absolute;
  left: 115px;
  top: 180px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}
.bc2 .p7 {
  position: absolute;
  right: 400px;
  top: 565px;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
}

.rs1 {
  width: 29.7cm;
  height: 21.2cm;
  display: flex;
  margin-top: -5px;
}
.rs1 .std-photo {
  position: absolute;
  right: 110px;
  top: 180px;
}
.rs1 .p0 {
  position: absolute;
  left: 460px;
  top: 121px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p1 {
  position: absolute;
  left: 460px;
  top: 141px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p2 {
  position: absolute;
  left: 460px;
  top: 161px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p3 {
  position: absolute;
  left: 460px;
  top: 181px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p4 {
  position: absolute;
  left: 460px;
  top: 201px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p5 {
  position: absolute;
  left: 460px;
  top: 221px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p6 {
  position: absolute;
  left: 460px;
  top: 259px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p7 {
  position: absolute;
  left: 460px;
  top: 279px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p8 {
  position: absolute;
  left: 460px;
  top: 299px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p9 {
  position: absolute;
  left: 460px;
  top: 319px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p10 {
  position: absolute;
  left: 460px;
  top: 339px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
}
.rs1 .p11 {
  position: absolute;
  left: 50px;
  top: 540px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
  width: 80px;
  text-align: justify;
}
.rs1 .p12 {
  position: absolute;
  left: 130px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p13 {
  position: absolute;
  left: 190px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p14 {
  position: absolute;
  left: 243px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p15 {
  position: absolute;
  left: 296px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p16 {
  position: absolute;
  left: 350px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p17 {
  position: absolute;
  left: 403px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p18 {
  position: absolute;
  left: 456px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p19 {
  position: absolute;
  left: 510px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p20 {
  position: absolute;
  left: 563px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p21 {
  position: absolute;
  left: 616px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p22 {
  position: absolute;
  left: 670px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p23 {
  position: absolute;
  left: 723px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p24 {
  position: absolute;
  left: 776px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p25 {
  position: absolute;
  left: 776px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p26 {
  position: absolute;
  left: 776px;
  top: 550px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}
.rs1 .p27 {
  position: absolute;
  right: 175px;
  top: 535px;
  font-size: 12px;
  transform: rotate(-90deg);
  font-weight: bold;
  height: 20px;
}

.vf0 {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.vf0 .form-title {
  text-align: center;
}
.vf0 .form-title h2 {
  text-transform: uppercase;
  -webkit-text-decoration: double underline;
          text-decoration: double underline;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.vf0 .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.vf0 .title {
  width: 100%;
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #d8d8d8;
}
.vf0 .title .ins-name {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
  color: #777;
}
.vf0 .title .sub-title h2 {
  padding-left: 18%;
  padding-right: 18%;
  font-size: 12px;
  color: #777;
  text-align: center;
}
.vf0 .title h2 {
  font-size: 12.5px;
  color: #777;
}

.vf0 .top {
  flex-direction: row;
  padding-top: 0px;
  margin-top: 5px;
  text-align: center;
  display: flex;
}
.vf0 .top .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: #777;
}
.vf0 .top .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  color: #777;
  width: 120px;
}

.vf0 .summary {
  background-color: #fafafa;
  border-bottom: 2px solid #d8d8d8;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.vf0 .summary-1 {
  text-align: left;
  width: 50%;
  padding-left: 2px;
  align-items: center !important;
}
.vf0 .summary-1 div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-display: left;
}

.vf0 .summary-2 {
  text-align: left;
  vertical-align: middle;
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2px;
  background-color: #ffffff;
}
.vf0 .summary-2 div {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  justify-content: left;
}
.vf0 .summary-2 form-item-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.vf0 .qrcode {
  width: 18%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  justify-items: center;
}

.vf0 .payment-details {
  padding-top: 15px;
  padding-bottom: 15px;
}
.vf0 .payment-details h2 {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
}

.vf0 .items {
  width: 100%;
  border-collapse: collapse;
}

.vf0 .items th {
  background-color: #efefef;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 25px;
}

.vf0 .items td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  height: 26px;
  font-size: 14px;
}

.vf0 .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.vf0 footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 0.5px solid #aaa;
  padding: 6px 0;
  font-size: 13px;
}

.vf0 .payment-info {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-top: 10px;
  border-collapse: collapse;
}

.vf0 .pi-item {
  padding: 2px;
  border: 1px solid #d8d8d8;
  height: 60px;
  text-align: center;
  font-size: 12px;
  width: 25%;
  border-collapse: collapse;
}
.vf0 .pi-item div {
  font-size: 14px;
}
.vf0 .pi-item .pi-item-heading {
  font-size: 14px;
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 2px;
}

.vf0 .pi-item-accountant {
  border-left: 1px solid #d8d8d8;
  padding-left: 20px;
  width: 24%;
}
.vf0 .pi-item-accountant .pi-item-heading {
  position: relative;
  width: 24%;
  font-size: 12px;
  font-weight: bold;
  position: static;
  margin-top: 38px;
  margin-left: 20px;
}

.vf0 .pmt-amt {
  padding-top: 10px;
  padding-bottom: 10px;
}

.vf0 .pmt-words {
  display: flex;
  padding-top: 20px;
  padding-left: 5px;
  font-size: 13px;
  height: 30px;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  justify-content: left;
}

.cheque-realization {
  height: 25px;
  display: flex;
  font-size: 12px;
  padding-top: 25px;
  padding-bottom: 15px;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.separator {
  border-bottom: 2px #a8a8a8 dotted !important;
  height: 22px;
  margin-bottom: 12px;
}

.timestamp {
  font-size: 10px;
  padding-top: 5px;
}

.rf1 {
  position: relative;
  background-color: #FFF;
  min-height: 680px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.rf1 .row {
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.rf1 .form-title {
  align-content: center;
  padding: 5px;
}
.rf1 .form-title h2 {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
  justify-content: center;
}

.rf1 .title-summary {
  background-color: #fafafa;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  padding-bottom: 5px;
  border-bottom: 2px solid #d8d8d8;
}
.rf1 .title-summary .tax-info {
  width: 25%;
  font-size: 12px;
}
.rf1 .title-summary .title {
  width: 50%;
  margin-top: 5px;
  padding-top: 5px;
}
.rf1 .title-summary .title h1 {
  font-size: 17px;
  font-weight: bold;
  color: #777;
  height: 24px;
  text-align: center;
}
.rf1 .title-summary .title .sub-title h2 {
  padding-left: 18%;
  padding-right: 18%;
  font-size: 12px;
  color: #777;
  text-align: center;
}

.rf1 .top {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  padding-top: 0px;
  justify-content: center;
  text-align: center;
  display: flex;
}
.rf1 .top .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: #777;
}
.rf1 .top .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  color: #777;
  text-align: center;
  width: 120px;
}

.rf1 .top-1 {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  margin-top: 5px;
  padding-top: 5px;
  justify-content: center;
  text-align: center;
  display: flex;
}
.rf1 .top-1 .ins-name {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding-left: 120px;
  margin-left: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: #777;
}
.rf1 .top-1 .heading-copy {
  font-size: 12px;
  height: 22px;
  right: 0;
  margin-bottom: 0;
  color: #777;
  text-align: center;
  width: 120px;
}

.rf1 .summary {
  margin-top: 5px;
  background-color: #fafafa;
  border-bottom: 1px solid #d8d8d8;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.rf1 .student-details {
  text-align: left;
  width: 60%;
  padding-left: 2px;
  align-items: center !important;
}
.rf1 .student-details div {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-display: left;
}

.rf1 .receipt-details {
  text-align: left;
  vertical-align: middle;
  width: 40%;
  padding-left: 2px;
}
.rf1 .receipt-details div {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: left;
}
.rf1 .receipt-details form-item-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.rf1 .payment-details {
  padding-top: 15px;
  padding-bottom: 15px;
}
.rf1 .payment-details h2 {
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  text-align: center;
}

.rf1 .items {
  width: 100%;
  border-collapse: collapse;
}

.rf1 .items th {
  background-color: #efefef;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 25px;
}

.rf1 .items td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding-left: 7px;
  padding-right: 5px;
  height: 26px;
  font-size: 12px;
}

.rf1 .items tr:nth-child(even) {
  background-color: #f2f2f2;
}

.rf1 footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 0.5px solid #aaa;
  padding: 6px 0;
  font-size: 13px;
}

.rf1 .payment-info {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  margin-top: 3px;
  border-collapse: collapse;
}

.rf1 .pmt-amt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.rf1 .pmt-amt .pmt-words {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  vertical-align: middle;
  max-width: 400px;
  justify-content: left;
  font-size: 12.5px;
  width: 100%;
}

.rf1 .pi-item {
  padding: 2px;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  text-align: center;
  font-size: 12px;
  width: 19%;
  border-collapse: collapse;
}
.rf1 .pi-item div {
  font-size: 12px;
}
.rf1 .pi-item .pi-item-heading {
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  margin-bottom: 2px;
}

.rf1 .pi-item-accountant {
  border-left: 1px solid #d8d8d8;
  padding-left: 20px;
  width: 24%;
}
.rf1 .pi-item-accountant .pi-item-heading {
  position: relative;
  width: 24%;
  font-size: 12px;
  font-weight: bold;
  position: static;
  margin-top: 38px;
  margin-left: 20px;
}

.layout-footer {
  transition: margin-left 0.2s;
  background-color: #c3e8fb;
  padding: 1em 2em;
}
.layout-footer img {
  vertical-align: middle;
}
.layout-footer .footer-text {
  vertical-align: middle;
}

/* Typography */
h1 {
  font-weight: normal;
  margin: 0;
  font-size: 24px;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.div-2 {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 3px;
  margin-right: 8px;
  background-color: #fefefe;
  border: 1.5px solid #a2bbd1;
}

.card {
  padding: 0.25em;
  margin-bottom: 3px;
  border-radius: 3px;
}
.card.card-w-title {
  justify-content: center;
  text-align: center;
  padding-bottom: 1.25em;
}
.card.card-w-heading {
  padding-bottom: 0.25em;
}
.card h1 {
  margin: 1em 0 0.5em 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 0.1em;
  font-size: 20px;
}
.card h1:first-child {
  margin: 0 0 0.5em 0;
}
.card h2 {
  margin: 0.2em 0.2em 0.2em 0.2em;
  border-bottom: 1px solid #d5d5d5;
  padding: 0.1em;
  font-size: 16px;
}
.card h3 {
  margin: 0.25em 0.25em 0.25em 0.25em;
  border-bottom: 1px solid #d5d5d5;
  padding: 0.05em;
  font-size: 14px;
  font-weight: bolder;
}

.label {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #000000;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  text-align: center;
}

.label-1 {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #000000;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  text-align: center;
  padding-top: 2px;
}
.label-1 .header-1 {
  display: flex;
  align-items: flex-end;
  font-size: 12.5px;
  justify-content: center;
  padding-bottom: 4px;
}
.label-1 .header-1 h1 {
  font-size: 12.5px;
  font-weight: bold;
  text-align: center;
}
.label-1 .header-1 h2 {
  font-size: 11.5px;
  font-weight: bold;
  text-align: center;
}
.label-1 .header-1 .logo {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 45px;
  height: 45px;
}
.label-1 .header-1 .seal {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  height: 50px;
}
.label-1 .form-item-row {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: start;
  font-size: 12px;
  width: 100%;
}
.label-1 .footer-1 {
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
}

.label-2 {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #000000;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  text-align: center;
  padding-top: 2px;
}
.label-2 .qrcode {
  padding: 6px;
  transform: translateY(10%);
}
.label-2 .form-item-row {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: start;
  font-size: 12px;
  height: 28px;
  width: 100%;
}

.label-top-inline-text {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: -8px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  border: hidden;
  max-width: 75px;
  text-align: center;
}

.label-top-inner-text {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  border: hidden;
  max-width: 120px;
}

.label-top-outer-text {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  border: hidden;
  max-width: 120px;
}

.label-bottom-inline-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  max-width: 75px;
}

.label-bottom-inner-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  max-width: 120px;
}

.label-bottom-outer-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  background: white;
  max-width: 120px;
}

.label-left-inline-text {
  position: absolute;
  display: block;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 10px;
  padding-right: 10px;
  top: 0px;
  bottom: 0px;
  text-align: center;
  background: white;
  border: hidden;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  transform: rotate(270deg);
  height: 80px;
  width: 45px;
}

.label-left-inner-text {
  position: absolute;
  display: block;
  margin-left: 15px;
  margin-right: 2px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 20px;
  top: 0px;
  bottom: 0px;
  text-align: center;
  background: white;
  border: hidden;
  font-size: 7.5px;
  font-weight: bold;
  color: #666666;
  transform: rotate(270deg);
  height: 90px;
}

.label-left-outer-text {
  position: absolute;
  margin-left: -20px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0px;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background: white;
  border: hidden;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  max-height: 100px;
}

.label-right-inline-text {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -5px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: white;
  border: hidden;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  writing-mode: vertical-rl;
  max-height: 50px;
}

.label-right-inner-text {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  border: hidden;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  writing-mode: vertical-rl;
  max-height: 100px;
}

.label-right-outer-text {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -20px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  border: hidden;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: #666666;
  writing-mode: vertical-rl;
  max-height: 100px;
}

.label-qrcode {
  position: absolute;
  padding: 5px;
  right: 8px;
  bottom: 12px;
  transform: translateY(10%);
}

.label-items {
  transform: translateY(10%);
}

.label-section-1 {
  display: block;
  text-align: left;
  margin-top: 5px;
  margin-left: 25px;
  font-size: 9px;
}

.label-section-2 {
  display: block;
  text-align: left;
  margin-left: 25px;
  margin-top: 2px;
  font-size: 9px;
}

.label-section-3 {
  display: block;
  text-align: left;
  margin-left: 25px;
  margin-top: 2px;
  font-size: 9px;
}

.label-section-4 {
  display: block;
  text-align: left;
  margin-left: 25px;
  margin-top: 2px;
  font-size: 9px;
}

.label-section-5 {
  display: block;
  text-align: left;
  margin-left: 25px;
  margin-top: 2px;
  font-size: 9px;
}

.label-section-6 {
  display: block;
  text-align: left;
  margin-left: 25px;
  margin-top: 2px;
  font-size: 9px;
}

.menu-card {
  background-color: #ffffff;
  padding: 0.25em;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 3px;
  display: flex;
}
.menu-card.card-w-title {
  padding-bottom: 1.25em;
}
.menu-card.card-w-heading {
  padding-bottom: 0.25em;
}
.menu-card h3 {
  margin: 0.25em 0.25em 0.25em 0.25em;
  padding: 0.05em;
  font-size: 14px;
  font-weight: bolder;
}

.report-card {
  background-color: #ffffff !important;
  padding: 0.25em;
  margin-bottom: 4px;
  margin-top: 4px;
  border-radius: 3px;
}
.report-card.report-card-content {
  height: 100% !important;
}

.report-criteria {
  background-color: #ffffff;
  padding: 0.1em;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.report-criteria-1 {
  padding: 0.1em;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.report-criteria-1 .report-criteria-section {
  margin: auto;
  width: 100%;
}

.report-criteria-title {
  background-color: #f8f8f8;
  border: 1px solid #D8D8D8;
  width: 100%;
  text-align: center;
}

.report-criteria-action {
  background-color: #f8f8f8;
  border: 1px solid #D8D8D8;
  width: 100%;
  height: 60px !important;
  text-align: center;
  padding-top: 8px;
}

.p-g {
  flex-wrap: wrap;
}

.parentMenu.p-tabmenu, .parentMenu.p-tabmenu-nav {
  margin-top: -2px !important;
}
.parentMenu.p-tabmenu, .parentMenu.p-tabmenu-nav, .parentMenu.p-tabmenuitem {
  list-style: none;
  float: left;
  position: relative;
  white-space: nowrap;
  display: block;
  top: 0px;
  font-size: 12.5px;
}
.parentMenu .p-tabmenu .p-tabmenu-nav .p-tabmenuitem a {
  float: left;
  font-weight: bold;
  text-decoration: underline !important;
}

.childMenu.p-tabmenu, .childMenu.p-tabmenu-nav {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  padding-right: -10px !important;
  list-style: none;
  position: relative;
  white-space: nowrap;
  display: block;
  font-size: 12px;
}
.childMenu.p-tabmenu, .childMenu.p-tabmenu-nav a {
  padding-left: -2px;
  padding-top: 3px;
}
.childMenu.p-tabmenu, .childMenu.p-menuitem-text {
  vertical-align: middle;
}

.container {
  display: flex;
  /* height: 100vh; */
}

.section_with_scroll {
  flex: 1;
  background: #aaa;
  overflow-y: scroll;
}

.p-tabmenu-item {
  background: #A8C8EE !important;
}

/* Dashboard */
.dashboard .summary {
  position: relative;
}
.dashboard .summary .title {
  font-size: 20px;
}
.dashboard .summary .detail {
  color: #000000;
  display: block;
  margin-top: 10px;
}
.dashboard .summary .count {
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  padding: 7px 14px;
  border-radius: 3px;
}
.dashboard .summary .count.visitors {
  background-color: #20d077;
}
.dashboard .summary .count.purchases {
  background-color: #f9c851;
}
.dashboard .summary .count.revenue {
  background-color: var(--primary-color);
}
.dashboard .highlight-box {
  height: 100px;
}
.dashboard .highlight-box:after {
  content: "";
  display: table;
  clear: both;
}
.dashboard .highlight-box .initials {
  height: 100%;
  float: left;
  width: 50%;
  text-align: center;
  padding: 1em;
}
.dashboard .highlight-box .initials > span {
  font-size: 48px;
}
.dashboard .highlight-box .highlight-details {
  height: 100%;
  background-color: #ffffff;
  float: left;
  width: 50%;
  padding: 1em;
}
.dashboard .highlight-box .highlight-details i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 0.25em;
}
.dashboard .highlight-box .highlight-details .count {
  color: #000000;
  font-size: 36px;
  display: block;
}
.dashboard .task-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .task-list li {
  padding: 0.5em 0.25em;
  border-bottom: 1px solid #e3e3e3;
}
.dashboard .task-list li:after {
  content: "";
  display: table;
  clear: both;
}
.dashboard .task-list .p-checkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}
.dashboard .task-list .task-name {
  vertical-align: middle;
}
.dashboard .task-list i {
  float: right;
  font-size: 24px;
  color: #000000;
}
.dashboard .task-list .p-panel-content {
  min-height: 256px;
}
.dashboard .contact-form .p-panel-content {
  min-height: 256px;
}
.dashboard .contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .contacts ul li {
  border-bottom: 1px solid #e3e3e3;
}
.dashboard .contacts ul li button {
  padding: 9px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 2px;
  transition: background-color 0.2s;
}
.dashboard .contacts ul li button .name {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}
.dashboard .contacts ul li button .email {
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 14px;
  color: #000000;
}
.dashboard .contacts ul li button:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
.dashboard .contacts ul li:last-child {
  border: 0;
}
.dashboard .contacts .p-panel-content {
  min-height: 256px;
}
.dashboard .activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .activity-list li {
  border-bottom: 1px solid #e3e3e3;
  padding: 16px 8px;
}
.dashboard .activity-list li .count {
  font-size: 24px;
  color: #ffffff;
  background-color: var(--primary-color);
  font-weight: 700;
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 3px;
}
.dashboard .activity-list li:first-child {
  border-top: 1px solid #e3e3e3;
}
.dashboard .activity-list li:last-child {
  border: 0;
}
.dashboard .activity-list li .p-g-6:first-child {
  font-size: 18px;
  padding-left: 0;
}
.dashboard .activity-list li .p-g-6:last-child {
  text-align: right;
  color: #000000;
}
.grid-header-style {
  
    font-size            : 13px;
    font-weight          : normal;
    color               : #ffffff;
    background-color     : var(--primary-color)
}
.grid-colomn-header-style
{
  /* width               : (lColumn.width_ === '-1') ? this.state.mColumnWidth : lColumn.width_ ; */
  /* display             : lVisible; */
   text-align         : center;
  border-right         : .5px solid;
  font-weight          : normal;
  color               : #ffffff;
  background-color     : var(--primary-color);
  
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
  text-align         : center;
  border-right         : .5px solid;
  font-weight          : normal;
  color               : var(--clr-text);
  background-color     : var(--primary-color)!important;
}
.text-primary{
  color: var(--clr-heading) !important;
}
.border-round {
  border-radius: 10px !important;
}
.border-1 {
  border: 1px solid var(--clr-text);
}
.Carouselimg{
  width: 80px;
  height: 120px;
  padding: 10px;
}
.p-sidebar-right .p-sidebar {
  width: 5rem !important;
  height: 100%;
  top : 58px;
  background-color: var(--primary-color);
}
.siedbar-icons{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-sidebar-close p-sidebar-icon p-link{
  text-align: center !important;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 13px;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 20px;
  min-width: 60px;
  position: relative;
}
.fc .fc-button-primary:disabled {
  background-color: var(--primary-color);
  border: none !important;
  color: var(--fc-button-text-color);
}
.fc .fc-button-primary {
  background-color: var(--primary-color);
  border: none !important;
  color: var(--clr-text);
}
.fc .fc-button-primary:focus {
  box-shadow: none;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  padding: 200px;
}
.bg-primary-light{
  background-color: var(--primary-light);
}
.product-image{
  max-width: 446px !important;
  max-height: 320px !important;
}
.demo{
  border-radius: 1rem; 
  background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%);
}
.gradient{
  background: var(--bg-gradient);
}
.dv-button{
  margin-left: 420px !important;
}

.mr-9{
  margin-right: 9rem;
}
.gradient{
  background: linear-gradient(0deg,rgba(21, 166, 175, 1), rgba(22, 173, 182, 1)), linear-gradient(92.54deg, #004085 47.88%, #FFFFFF 100.01%);

  /* background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%); */
}
;
.gradient-primary{
background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%);
 
  background: linear-gradient(0deg,rgba(21, 166, 175, 1), rgba(22, 173, 182, 1)), linear-gradient(92.54deg, #004085 47.88%, #FFFFFF 100.01%);
  }
.transparent{
  background-color: transparent !important;
  border: transparent !important;
  color: #000000;
  font-weight: bold;
  font-size: small;
}

.d-inline-block {
  display: inline-block;
}
.float-right{
  float: right;
}

.text-primary-light{
  color: var(--primary-light);
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  /* box-shadow: 0 0 0 0.2rem var(--primary-color); */
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.dataview-image{
  background-color: #EDFEFF;
}

.pb-05{
  padding-bottom: .1rem;
}
.p-carousel-container {
  display: flex;
  flex-direction: row;
  padding-left: -5px;
  padding-right: -5px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.grid > .col,
.grid > [class*=col] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .sm\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .sm\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .sm\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .sm\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .sm\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .sm\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .sm\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .sm\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .sm\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .sm\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .sm\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .sm\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .sm\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .sm\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .md\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .md\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .md\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .md\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .md\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .md\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .md\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .md\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .md\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .md\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .md\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .md\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .md\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .md\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .lg\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .lg\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .lg\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .lg\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .lg\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .lg\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .lg\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .lg\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .lg\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .lg\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .lg\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .lg\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .lg\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .xl\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xl\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xl\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xl\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xl\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xl\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xl\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xl\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xl\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xl\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xl\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xl\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xl\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }
  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }
  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }
  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }
  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }
  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .md\:col-offset-3 {
    margin-left: 25% !important;
  }
  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .md\:col-offset-6 {
    margin-left: 50% !important;
  }
  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .md\:col-offset-9 {
    margin-left: 75% !important;
  }
  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }
  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }
  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }
  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }
  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus {
  color: var(--surface-0) !important;
}

.hover\:text-0:hover {
  color: var(--surface-0) !important;
}

.active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus {
  color: var(--surface-50) !important;
}

.hover\:text-50:hover {
  color: var(--surface-50) !important;
}

.active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus {
  color: var(--surface-100) !important;
}

.hover\:text-100:hover {
  color: var(--surface-100) !important;
}

.active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus {
  color: var(--surface-200) !important;
}

.hover\:text-200:hover {
  color: var(--surface-200) !important;
}

.active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus {
  color: var(--surface-300) !important;
}

.hover\:text-300:hover {
  color: var(--surface-300) !important;
}

.active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus {
  color: var(--surface-400) !important;
}

.hover\:text-400:hover {
  color: var(--surface-400) !important;
}

.active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus {
  color: var(--surface-500) !important;
}

.hover\:text-500:hover {
  color: var(--surface-500) !important;
}

.active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus {
  color: var(--surface-600) !important;
}

.hover\:text-600:hover {
  color: var(--surface-600) !important;
}

.active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus {
  color: var(--surface-700) !important;
}

.hover\:text-700:hover {
  color: var(--surface-700) !important;
}

.active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus {
  color: var(--surface-800) !important;
}

.hover\:text-800:hover {
  color: var(--surface-800) !important;
}

.active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus {
  color: var(--surface-900) !important;
}

.hover\:text-900:hover {
  color: var(--surface-900) !important;
}

.active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus {
  background-color: var(--surface-0) !important;
}

.hover\:surface-0:hover {
  background-color: var(--surface-0) !important;
}

.active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus {
  background-color: var(--surface-50) !important;
}

.hover\:surface-50:hover {
  background-color: var(--surface-50) !important;
}

.active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus {
  background-color: var(--surface-100) !important;
}

.hover\:surface-100:hover {
  background-color: var(--surface-100) !important;
}

.active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus {
  background-color: var(--surface-200) !important;
}

.hover\:surface-200:hover {
  background-color: var(--surface-200) !important;
}

.active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus {
  background-color: var(--surface-300) !important;
}

.hover\:surface-300:hover {
  background-color: var(--surface-300) !important;
}

.active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus {
  background-color: var(--surface-400) !important;
}

.hover\:surface-400:hover {
  background-color: var(--surface-400) !important;
}

.active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus {
  background-color: var(--surface-500) !important;
}

.hover\:surface-500:hover {
  background-color: var(--surface-500) !important;
}

.active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus {
  background-color: var(--surface-600) !important;
}

.hover\:surface-600:hover {
  background-color: var(--surface-600) !important;
}

.active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus {
  background-color: var(--surface-700) !important;
}

.hover\:surface-700:hover {
  background-color: var(--surface-700) !important;
}

.active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus {
  background-color: var(--surface-800) !important;
}

.hover\:surface-800:hover {
  background-color: var(--surface-800) !important;
}

.active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus {
  background-color: var(--surface-900) !important;
}

.hover\:surface-900:hover {
  background-color: var(--surface-900) !important;
}

.active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus {
  border-color: var(--surface-0) !important;
}

.hover\:border-0:hover {
  border-color: var(--surface-0) !important;
}

.active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus {
  border-color: var(--surface-50) !important;
}

.hover\:border-50:hover {
  border-color: var(--surface-50) !important;
}

.active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus {
  border-color: var(--surface-100) !important;
}

.hover\:border-100:hover {
  border-color: var(--surface-100) !important;
}

.active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus {
  border-color: var(--surface-200) !important;
}

.hover\:border-200:hover {
  border-color: var(--surface-200) !important;
}

.active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus {
  border-color: var(--surface-300) !important;
}

.hover\:border-300:hover {
  border-color: var(--surface-300) !important;
}

.active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus {
  border-color: var(--surface-400) !important;
}

.hover\:border-400:hover {
  border-color: var(--surface-400) !important;
}

.active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus {
  border-color: var(--surface-500) !important;
}

.hover\:border-500:hover {
  border-color: var(--surface-500) !important;
}

.active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus {
  border-color: var(--surface-600) !important;
}

.hover\:border-600:hover {
  border-color: var(--surface-600) !important;
}

.active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus {
  border-color: var(--surface-700) !important;
}

.hover\:border-700:hover {
  border-color: var(--surface-700) !important;
}

.active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus {
  border-color: var(--surface-800) !important;
}

.hover\:border-800:hover {
  border-color: var(--surface-800) !important;
}

.active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus {
  border-color: var(--surface-900) !important;
}

.hover\:border-900:hover {
  border-color: var(--surface-900) !important;
}

.active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
.border-transparent {
  border-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-transparent {
    border-color: transparent !important;
  }
}
.text-blue-50 {
  color: var(--blue-50) !important;
}
.text-blue-100 {
  color: var(--blue-100) !important;
}
.text-blue-200 {
  color: var(--blue-200) !important;
}
.text-blue-300 {
  color: var(--blue-300) !important;
}
.text-blue-400 {
  color: var(--blue-400) !important;
}
.text-blue-500 {
  color: var(--blue-500) !important;
}
.text-blue-600 {
  color: var(--blue-600) !important;
}
.text-blue-700 {
  color: var(--blue-700) !important;
}
.text-blue-800 {
  color: var(--blue-800) !important;
}
.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}
.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}
.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}
.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}
.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}
.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}
.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}
.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}
.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}
.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}
.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}
.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}
.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}
.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}
.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}
.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}
.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}
.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}
.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}
.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}
.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}
.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}
.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}
.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}
.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}
.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}
.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}
.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}
.text-green-100 {
  color: var(--green-100) !important;
}
.text-green-200 {
  color: var(--green-200) !important;
}
.text-green-300 {
  color: var(--green-300) !important;
}
.text-green-400 {
  color: var(--green-400) !important;
}
.text-green-500 {
  color: var(--green-500) !important;
}
.text-green-600 {
  color: var(--green-600) !important;
}
.text-green-700 {
  color: var(--green-700) !important;
}
.text-green-800 {
  color: var(--green-800) !important;
}
.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}
.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}
.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}
.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}
.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}
.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}
.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}
.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}
.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}
.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}
.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}
.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}
.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}
.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}
.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}
.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}
.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}
.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}
.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}
.active\:text-green-100:active {
  color: var(--green-100) !important;
}
.active\:text-green-200:active {
  color: var(--green-200) !important;
}
.active\:text-green-300:active {
  color: var(--green-300) !important;
}
.active\:text-green-400:active {
  color: var(--green-400) !important;
}
.active\:text-green-500:active {
  color: var(--green-500) !important;
}
.active\:text-green-600:active {
  color: var(--green-600) !important;
}
.active\:text-green-700:active {
  color: var(--green-700) !important;
}
.active\:text-green-800:active {
  color: var(--green-800) !important;
}
.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}
.text-yellow-100 {
  color: var(--yellow-100) !important;
}
.text-yellow-200 {
  color: var(--yellow-200) !important;
}
.text-yellow-300 {
  color: var(--yellow-300) !important;
}
.text-yellow-400 {
  color: var(--yellow-400) !important;
}
.text-yellow-500 {
  color: var(--yellow-500) !important;
}
.text-yellow-600 {
  color: var(--yellow-600) !important;
}
.text-yellow-700 {
  color: var(--yellow-700) !important;
}
.text-yellow-800 {
  color: var(--yellow-800) !important;
}
.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}
.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}
.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}
.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}
.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}
.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}
.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}
.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}
.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}
.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}
.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}
.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}
.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}
.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}
.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}
.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}
.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}
.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}
.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}
.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}
.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}
.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}
.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}
.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}
.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}
.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}
.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}
.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}
.text-cyan-100 {
  color: var(--cyan-100) !important;
}
.text-cyan-200 {
  color: var(--cyan-200) !important;
}
.text-cyan-300 {
  color: var(--cyan-300) !important;
}
.text-cyan-400 {
  color: var(--cyan-400) !important;
}
.text-cyan-500 {
  color: var(--cyan-500) !important;
}
.text-cyan-600 {
  color: var(--cyan-600) !important;
}
.text-cyan-700 {
  color: var(--cyan-700) !important;
}
.text-cyan-800 {
  color: var(--cyan-800) !important;
}
.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}
.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}
.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}
.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}
.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}
.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}
.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}
.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}
.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}
.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}
.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}
.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}
.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}
.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}
.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}
.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}
.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}
.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}
.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}
.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}
.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}
.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}
.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}
.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}
.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}
.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}
.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}
.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}
.text-pink-100 {
  color: var(--pink-100) !important;
}
.text-pink-200 {
  color: var(--pink-200) !important;
}
.text-pink-300 {
  color: var(--pink-300) !important;
}
.text-pink-400 {
  color: var(--pink-400) !important;
}
.text-pink-500 {
  color: var(--pink-500) !important;
}
.text-pink-600 {
  color: var(--pink-600) !important;
}
.text-pink-700 {
  color: var(--pink-700) !important;
}
.text-pink-800 {
  color: var(--pink-800) !important;
}
.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}
.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}
.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}
.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}
.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}
.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}
.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}
.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}
.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}
.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}
.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}
.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}
.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}
.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}
.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}
.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}
.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}
.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}
.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}
.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}
.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}
.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}
.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}
.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}
.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}
.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}
.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}
.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}
.text-indigo-100 {
  color: var(--indigo-100) !important;
}
.text-indigo-200 {
  color: var(--indigo-200) !important;
}
.text-indigo-300 {
  color: var(--indigo-300) !important;
}
.text-indigo-400 {
  color: var(--indigo-400) !important;
}
.text-indigo-500 {
  color: var(--indigo-500) !important;
}
.text-indigo-600 {
  color: var(--indigo-600) !important;
}
.text-indigo-700 {
  color: var(--indigo-700) !important;
}
.text-indigo-800 {
  color: var(--indigo-800) !important;
}
.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}
.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}
.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}
.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}
.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}
.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}
.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}
.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}
.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}
.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}
.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}
.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}
.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}
.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}
.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}
.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}
.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}
.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}
.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}
.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}
.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}
.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}
.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}
.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}
.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}
.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}
.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}
.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}
.text-teal-100 {
  color: var(--teal-100) !important;
}
.text-teal-200 {
  color: var(--teal-200) !important;
}
.text-teal-300 {
  color: var(--teal-300) !important;
}
.text-teal-400 {
  color: var(--teal-400) !important;
}
.text-teal-500 {
  color: var(--teal-500) !important;
}
.text-teal-600 {
  color: var(--teal-600) !important;
}
.text-teal-700 {
  color: var(--teal-700) !important;
}
.text-teal-800 {
  color: var(--teal-800) !important;
}
.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}
.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}
.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}
.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}
.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}
.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}
.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}
.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}
.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}
.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}
.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}
.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}
.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}
.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}
.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}
.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}
.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}
.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}
.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}
.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}
.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}
.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}
.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}
.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}
.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}
.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}
.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}
.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}
.text-orange-100 {
  color: var(--orange-100) !important;
}
.text-orange-200 {
  color: var(--orange-200) !important;
}
.text-orange-300 {
  color: var(--orange-300) !important;
}
.text-orange-400 {
  color: var(--orange-400) !important;
}
.text-orange-500 {
  color: var(--orange-500) !important;
}
.text-orange-600 {
  color: var(--orange-600) !important;
}
.text-orange-700 {
  color: var(--orange-700) !important;
}
.text-orange-800 {
  color: var(--orange-800) !important;
}
.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}
.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}
.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}
.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}
.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}
.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}
.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}
.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}
.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}
.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}
.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}
.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}
.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}
.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}
.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}
.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}
.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}
.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}
.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}
.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}
.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}
.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}
.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}
.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}
.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}
.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}
.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}
.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}
.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}
.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}
.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}
.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}
.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}
.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}
.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}
.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}
.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}
.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}
.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}
.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}
.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}
.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}
.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}
.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}
.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}
.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}
.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}
.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}
.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}
.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}
.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}
.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}
.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}
.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}
.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}
.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}
.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}
.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}
.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}
.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}
.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}
.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}
.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}
.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}
.text-purple-100 {
  color: var(--purple-100) !important;
}
.text-purple-200 {
  color: var(--purple-200) !important;
}
.text-purple-300 {
  color: var(--purple-300) !important;
}
.text-purple-400 {
  color: var(--purple-400) !important;
}
.text-purple-500 {
  color: var(--purple-500) !important;
}
.text-purple-600 {
  color: var(--purple-600) !important;
}
.text-purple-700 {
  color: var(--purple-700) !important;
}
.text-purple-800 {
  color: var(--purple-800) !important;
}
.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}
.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}
.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}
.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}
.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}
.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}
.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}
.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}
.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}
.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}
.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}
.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}
.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}
.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}
.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}
.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}
.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}
.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}
.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}
.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}
.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}
.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}
.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}
.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}
.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}
.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}
.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}
.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}
.text-gray-100 {
  color: var(--gray-100) !important;
}
.text-gray-200 {
  color: var(--gray-200) !important;
}
.text-gray-300 {
  color: var(--gray-300) !important;
}
.text-gray-400 {
  color: var(--gray-400) !important;
}
.text-gray-500 {
  color: var(--gray-500) !important;
}
.text-gray-600 {
  color: var(--gray-600) !important;
}
.text-gray-700 {
  color: var(--gray-700) !important;
}
.text-gray-800 {
  color: var(--gray-800) !important;
}
.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}
.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}
.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}
.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}
.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}
.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}
.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}
.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}
.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}
.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}
.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}
.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}
.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}
.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}
.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}
.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}
.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}
.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}
.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}
.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}
.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}
.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}
.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}
.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}
.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}
.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}
.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}
.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}
.text-red-100 {
  color: var(--red-100) !important;
}
.text-red-200 {
  color: var(--red-200) !important;
}
.text-red-300 {
  color: var(--red-300) !important;
}
.text-red-400 {
  color: var(--red-400) !important;
}
.text-red-500 {
  color: var(--red-500) !important;
}
.text-red-600 {
  color: var(--red-600) !important;
}
.text-red-700 {
  color: var(--red-700) !important;
}
.text-red-800 {
  color: var(--red-800) !important;
}
.text-red-900 {
  color: var(--red-900) !important;
}

.focus\:text-red-50:focus {
  color: var(--red-50) !important;
}
.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}
.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}
.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}
.focus\:text-red-400:focus {
  color: var(--red-400) !important;
}
.focus\:text-red-500:focus {
  color: var(--red-500) !important;
}
.focus\:text-red-600:focus {
  color: var(--red-600) !important;
}
.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}
.focus\:text-red-800:focus {
  color: var(--red-800) !important;
}
.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-50:hover {
  color: var(--red-50) !important;
}
.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}
.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}
.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}
.hover\:text-red-400:hover {
  color: var(--red-400) !important;
}
.hover\:text-red-500:hover {
  color: var(--red-500) !important;
}
.hover\:text-red-600:hover {
  color: var(--red-600) !important;
}
.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}
.hover\:text-red-800:hover {
  color: var(--red-800) !important;
}
.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-50:active {
  color: var(--red-50) !important;
}
.active\:text-red-100:active {
  color: var(--red-100) !important;
}
.active\:text-red-200:active {
  color: var(--red-200) !important;
}
.active\:text-red-300:active {
  color: var(--red-300) !important;
}
.active\:text-red-400:active {
  color: var(--red-400) !important;
}
.active\:text-red-500:active {
  color: var(--red-500) !important;
}
.active\:text-red-600:active {
  color: var(--red-600) !important;
}
.active\:text-red-700:active {
  color: var(--red-700) !important;
}
.active\:text-red-800:active {
  color: var(--red-800) !important;
}
.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}
.text-primary-100 {
  color: var(--primary-100) !important;
}
.text-primary-200 {
  color: var(--primary-200) !important;
}
.text-primary-300 {
  color: var(--primary-300) !important;
}
.text-primary-400 {
  color: var(--primary-400) !important;
}
.text-primary-500 {
  color: var(--primary-500) !important;
}
.text-primary-600 {
  color: var(--primary-600) !important;
}
.text-primary-700 {
  color: var(--primary-700) !important;
}
.text-primary-800 {
  color: var(--primary-800) !important;
}
.text-primary-900 {
  color: var(--primary-900) !important;
}

.focus\:text-primary-50:focus {
  color: var(--primary-50) !important;
}
.focus\:text-primary-100:focus {
  color: var(--primary-100) !important;
}
.focus\:text-primary-200:focus {
  color: var(--primary-200) !important;
}
.focus\:text-primary-300:focus {
  color: var(--primary-300) !important;
}
.focus\:text-primary-400:focus {
  color: var(--primary-400) !important;
}
.focus\:text-primary-500:focus {
  color: var(--primary-500) !important;
}
.focus\:text-primary-600:focus {
  color: var(--primary-600) !important;
}
.focus\:text-primary-700:focus {
  color: var(--primary-700) !important;
}
.focus\:text-primary-800:focus {
  color: var(--primary-800) !important;
}
.focus\:text-primary-900:focus {
  color: var(--primary-900) !important;
}

.hover\:text-primary-50:hover {
  color: var(--primary-50) !important;
}
.hover\:text-primary-100:hover {
  color: var(--primary-100) !important;
}
.hover\:text-primary-200:hover {
  color: var(--primary-200) !important;
}
.hover\:text-primary-300:hover {
  color: var(--primary-300) !important;
}
.hover\:text-primary-400:hover {
  color: var(--primary-400) !important;
}
.hover\:text-primary-500:hover {
  color: var(--primary-500) !important;
}
.hover\:text-primary-600:hover {
  color: var(--primary-600) !important;
}
.hover\:text-primary-700:hover {
  color: var(--primary-700) !important;
}
.hover\:text-primary-800:hover {
  color: var(--primary-800) !important;
}
.hover\:text-primary-900:hover {
  color: var(--primary-900) !important;
}

.active\:text-primary-50:active {
  color: var(--primary-50) !important;
}
.active\:text-primary-100:active {
  color: var(--primary-100) !important;
}
.active\:text-primary-200:active {
  color: var(--primary-200) !important;
}
.active\:text-primary-300:active {
  color: var(--primary-300) !important;
}
.active\:text-primary-400:active {
  color: var(--primary-400) !important;
}
.active\:text-primary-500:active {
  color: var(--primary-500) !important;
}
.active\:text-primary-600:active {
  color: var(--primary-600) !important;
}
.active\:text-primary-700:active {
  color: var(--primary-700) !important;
}
.active\:text-primary-800:active {
  color: var(--primary-800) !important;
}
.active\:text-primary-900:active {
  color: var(--primary-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.bg-blue-500 {
  background-color: var(--blue-500) !important;
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.bg-blue-800 {
  background-color: var(--blue-800) !important;
}
.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}
.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}
.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}
.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}
.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}
.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}
.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}
.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}
.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}
.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}
.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}
.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}
.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}
.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}
.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}
.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}
.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}
.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}
.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}
.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}
.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}
.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}
.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}
.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}
.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}
.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}
.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}
.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}
.bg-green-100 {
  background-color: var(--green-100) !important;
}
.bg-green-200 {
  background-color: var(--green-200) !important;
}
.bg-green-300 {
  background-color: var(--green-300) !important;
}
.bg-green-400 {
  background-color: var(--green-400) !important;
}
.bg-green-500 {
  background-color: var(--green-500) !important;
}
.bg-green-600 {
  background-color: var(--green-600) !important;
}
.bg-green-700 {
  background-color: var(--green-700) !important;
}
.bg-green-800 {
  background-color: var(--green-800) !important;
}
.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}
.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}
.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}
.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}
.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}
.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}
.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}
.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}
.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}
.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}
.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}
.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}
.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}
.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}
.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}
.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}
.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}
.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}
.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}
.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}
.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}
.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}
.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}
.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}
.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}
.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}
.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}
.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}
.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}
.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}
.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}
.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}
.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}
.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}
.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}
.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}
.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}
.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}
.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}
.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}
.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}
.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}
.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}
.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}
.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}
.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}
.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}
.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}
.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}
.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}
.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}
.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}
.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}
.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}
.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}
.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}
.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}
.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}
.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}
.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}
.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}
.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}
.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}
.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}
.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}
.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}
.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}
.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}
.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}
.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}
.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}
.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}
.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}
.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}
.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}
.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}
.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}
.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}
.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}
.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}
.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}
.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}
.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}
.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}
.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}
.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}
.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}
.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}
.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}
.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}
.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}
.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}
.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}
.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}
.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}
.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}
.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}
.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}
.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}
.bg-pink-100 {
  background-color: var(--pink-100) !important;
}
.bg-pink-200 {
  background-color: var(--pink-200) !important;
}
.bg-pink-300 {
  background-color: var(--pink-300) !important;
}
.bg-pink-400 {
  background-color: var(--pink-400) !important;
}
.bg-pink-500 {
  background-color: var(--pink-500) !important;
}
.bg-pink-600 {
  background-color: var(--pink-600) !important;
}
.bg-pink-700 {
  background-color: var(--pink-700) !important;
}
.bg-pink-800 {
  background-color: var(--pink-800) !important;
}
.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}
.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}
.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}
.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}
.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}
.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}
.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}
.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}
.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}
.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}
.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}
.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}
.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}
.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}
.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}
.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}
.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}
.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}
.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}
.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}
.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}
.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}
.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}
.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}
.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}
.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}
.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}
.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}
.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}
.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}
.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}
.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}
.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}
.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}
.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}
.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}
.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}
.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}
.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}
.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}
.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}
.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}
.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}
.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}
.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}
.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}
.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}
.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}
.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}
.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}
.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}
.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}
.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}
.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}
.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}
.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}
.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}
.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}
.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}
.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}
.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}
.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}
.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}
.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}
.bg-teal-100 {
  background-color: var(--teal-100) !important;
}
.bg-teal-200 {
  background-color: var(--teal-200) !important;
}
.bg-teal-300 {
  background-color: var(--teal-300) !important;
}
.bg-teal-400 {
  background-color: var(--teal-400) !important;
}
.bg-teal-500 {
  background-color: var(--teal-500) !important;
}
.bg-teal-600 {
  background-color: var(--teal-600) !important;
}
.bg-teal-700 {
  background-color: var(--teal-700) !important;
}
.bg-teal-800 {
  background-color: var(--teal-800) !important;
}
.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}
.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}
.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}
.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}
.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}
.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}
.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}
.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}
.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}
.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}
.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}
.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}
.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}
.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}
.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}
.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}
.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}
.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}
.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}
.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}
.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}
.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}
.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}
.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}
.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}
.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}
.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}
.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}
.bg-orange-100 {
  background-color: var(--orange-100) !important;
}
.bg-orange-200 {
  background-color: var(--orange-200) !important;
}
.bg-orange-300 {
  background-color: var(--orange-300) !important;
}
.bg-orange-400 {
  background-color: var(--orange-400) !important;
}
.bg-orange-500 {
  background-color: var(--orange-500) !important;
}
.bg-orange-600 {
  background-color: var(--orange-600) !important;
}
.bg-orange-700 {
  background-color: var(--orange-700) !important;
}
.bg-orange-800 {
  background-color: var(--orange-800) !important;
}
.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}
.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}
.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}
.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}
.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}
.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}
.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}
.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}
.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}
.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}
.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}
.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}
.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}
.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}
.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}
.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}
.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}
.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}
.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}
.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}
.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}
.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}
.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}
.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}
.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}
.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}
.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}
.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}
.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}
.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}
.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}
.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}
.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}
.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}
.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}
.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}
.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}
.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}
.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}
.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}
.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}
.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}
.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}
.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}
.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}
.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}
.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}
.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}
.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}
.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}
.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}
.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}
.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}
.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}
.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}
.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}
.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}
.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}
.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}
.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}
.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}
.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}
.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}
.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}
.bg-purple-100 {
  background-color: var(--purple-100) !important;
}
.bg-purple-200 {
  background-color: var(--purple-200) !important;
}
.bg-purple-300 {
  background-color: var(--purple-300) !important;
}
.bg-purple-400 {
  background-color: var(--purple-400) !important;
}
.bg-purple-500 {
  background-color: var(--purple-500) !important;
}
.bg-purple-600 {
  background-color: var(--purple-600) !important;
}
.bg-purple-700 {
  background-color: var(--purple-700) !important;
}
.bg-purple-800 {
  background-color: var(--purple-800) !important;
}
.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}
.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}
.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}
.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}
.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}
.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}
.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}
.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}
.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}
.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}
.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}
.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}
.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}
.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}
.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}
.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}
.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}
.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}
.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}
.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}
.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}
.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}
.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}
.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}
.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}
.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}
.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}
.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}
.bg-gray-100 {
  background-color: var(--gray-100) !important;
}
.bg-gray-200 {
  background-color: var(--gray-200) !important;
}
.bg-gray-300 {
  background-color: var(--gray-300) !important;
}
.bg-gray-400 {
  background-color: var(--gray-400) !important;
}
.bg-gray-500 {
  background-color: var(--gray-500) !important;
}
.bg-gray-600 {
  background-color: var(--gray-600) !important;
}
.bg-gray-700 {
  background-color: var(--gray-700) !important;
}
.bg-gray-800 {
  background-color: var(--gray-800) !important;
}
.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}
.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}
.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}
.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}
.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}
.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}
.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}
.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}
.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}
.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}
.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}
.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}
.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}
.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}
.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}
.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}
.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}
.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}
.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}
.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}
.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}
.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}
.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}
.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}
.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}
.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}
.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}
.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}
.bg-red-100 {
  background-color: var(--red-100) !important;
}
.bg-red-200 {
  background-color: var(--red-200) !important;
}
.bg-red-300 {
  background-color: var(--red-300) !important;
}
.bg-red-400 {
  background-color: var(--red-400) !important;
}
.bg-red-500 {
  background-color: var(--red-500) !important;
}
.bg-red-600 {
  background-color: var(--red-600) !important;
}
.bg-red-700 {
  background-color: var(--red-700) !important;
}
.bg-red-800 {
  background-color: var(--red-800) !important;
}
.bg-red-900 {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-50:focus {
  background-color: var(--red-50) !important;
}
.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}
.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}
.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}
.focus\:bg-red-400:focus {
  background-color: var(--red-400) !important;
}
.focus\:bg-red-500:focus {
  background-color: var(--red-500) !important;
}
.focus\:bg-red-600:focus {
  background-color: var(--red-600) !important;
}
.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}
.focus\:bg-red-800:focus {
  background-color: var(--red-800) !important;
}
.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-50:hover {
  background-color: var(--red-50) !important;
}
.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}
.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}
.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}
.hover\:bg-red-400:hover {
  background-color: var(--red-400) !important;
}
.hover\:bg-red-500:hover {
  background-color: var(--red-500) !important;
}
.hover\:bg-red-600:hover {
  background-color: var(--red-600) !important;
}
.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}
.hover\:bg-red-800:hover {
  background-color: var(--red-800) !important;
}
.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-50:active {
  background-color: var(--red-50) !important;
}
.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}
.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}
.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}
.active\:bg-red-400:active {
  background-color: var(--red-400) !important;
}
.active\:bg-red-500:active {
  background-color: var(--red-500) !important;
}
.active\:bg-red-600:active {
  background-color: var(--red-600) !important;
}
.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}
.active\:bg-red-800:active {
  background-color: var(--red-800) !important;
}
.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.bg-primary-50 {
  background-color: var(--primary-50) !important;
}
.bg-primary-100 {
  background-color: var(--primary-100) !important;
}
.bg-primary-200 {
  background-color: var(--primary-200) !important;
}
.bg-primary-300 {
  background-color: var(--primary-300) !important;
}
.bg-primary-400 {
  background-color: var(--primary-400) !important;
}
.bg-primary-500 {
  background-color: var(--primary-500) !important;
}
.bg-primary-600 {
  background-color: var(--primary-600) !important;
}
.bg-primary-700 {
  background-color: var(--primary-700) !important;
}
.bg-primary-800 {
  background-color: var(--primary-800) !important;
}
.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.focus\:bg-primary-50:focus {
  background-color: var(--primary-50) !important;
}
.focus\:bg-primary-100:focus {
  background-color: var(--primary-100) !important;
}
.focus\:bg-primary-200:focus {
  background-color: var(--primary-200) !important;
}
.focus\:bg-primary-300:focus {
  background-color: var(--primary-300) !important;
}
.focus\:bg-primary-400:focus {
  background-color: var(--primary-400) !important;
}
.focus\:bg-primary-500:focus {
  background-color: var(--primary-500) !important;
}
.focus\:bg-primary-600:focus {
  background-color: var(--primary-600) !important;
}
.focus\:bg-primary-700:focus {
  background-color: var(--primary-700) !important;
}
.focus\:bg-primary-800:focus {
  background-color: var(--primary-800) !important;
}
.focus\:bg-primary-900:focus {
  background-color: var(--primary-900) !important;
}

.hover\:bg-primary-50:hover {
  background-color: var(--primary-50) !important;
}
.hover\:bg-primary-100:hover {
  background-color: var(--primary-100) !important;
}
.hover\:bg-primary-200:hover {
  background-color: var(--primary-200) !important;
}
.hover\:bg-primary-300:hover {
  background-color: var(--primary-300) !important;
}
.hover\:bg-primary-400:hover {
  background-color: var(--primary-400) !important;
}
.hover\:bg-primary-500:hover {
  background-color: var(--primary-500) !important;
}
.hover\:bg-primary-600:hover {
  background-color: var(--primary-600) !important;
}
.hover\:bg-primary-700:hover {
  background-color: var(--primary-700) !important;
}
.hover\:bg-primary-800:hover {
  background-color: var(--primary-800) !important;
}
.hover\:bg-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.active\:bg-primary-50:active {
  background-color: var(--primary-50) !important;
}
.active\:bg-primary-100:active {
  background-color: var(--primary-100) !important;
}
.active\:bg-primary-200:active {
  background-color: var(--primary-200) !important;
}
.active\:bg-primary-300:active {
  background-color: var(--primary-300) !important;
}
.active\:bg-primary-400:active {
  background-color: var(--primary-400) !important;
}
.active\:bg-primary-500:active {
  background-color: var(--primary-500) !important;
}
.active\:bg-primary-600:active {
  background-color: var(--primary-600) !important;
}
.active\:bg-primary-700:active {
  background-color: var(--primary-700) !important;
}
.active\:bg-primary-800:active {
  background-color: var(--primary-800) !important;
}
.active\:bg-primary-900:active {
  background-color: var(--primary-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}
.border-blue-100 {
  border-color: var(--blue-100) !important;
}
.border-blue-200 {
  border-color: var(--blue-200) !important;
}
.border-blue-300 {
  border-color: var(--blue-300) !important;
}
.border-blue-400 {
  border-color: var(--blue-400) !important;
}
.border-blue-500 {
  border-color: var(--blue-500) !important;
}
.border-blue-600 {
  border-color: var(--blue-600) !important;
}
.border-blue-700 {
  border-color: var(--blue-700) !important;
}
.border-blue-800 {
  border-color: var(--blue-800) !important;
}
.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}
.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}
.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}
.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}
.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}
.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}
.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}
.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}
.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}
.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}
.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}
.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}
.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}
.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}
.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}
.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}
.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}
.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}
.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}
.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}
.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}
.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}
.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}
.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}
.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}
.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}
.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}
.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}
.border-green-100 {
  border-color: var(--green-100) !important;
}
.border-green-200 {
  border-color: var(--green-200) !important;
}
.border-green-300 {
  border-color: var(--green-300) !important;
}
.border-green-400 {
  border-color: var(--green-400) !important;
}
.border-green-500 {
  border-color: var(--green-500) !important;
}
.border-green-600 {
  border-color: var(--green-600) !important;
}
.border-green-700 {
  border-color: var(--green-700) !important;
}
.border-green-800 {
  border-color: var(--green-800) !important;
}
.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}
.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}
.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}
.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}
.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}
.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}
.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}
.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}
.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}
.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}
.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}
.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}
.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}
.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}
.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}
.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}
.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}
.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}
.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}
.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}
.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}
.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}
.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}
.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}
.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}
.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}
.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}
.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}
.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}
.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}
.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}
.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}
.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}
.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}
.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}
.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}
.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}
.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}
.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}
.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}
.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}
.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}
.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}
.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}
.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}
.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}
.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}
.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}
.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}
.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}
.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}
.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}
.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}
.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}
.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}
.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}
.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}
.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}
.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}
.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}
.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}
.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}
.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}
.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}
.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}
.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}
.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}
.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}
.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}
.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}
.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}
.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}
.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}
.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}
.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}
.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}
.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}
.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}
.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}
.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}
.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}
.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}
.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}
.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}
.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}
.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}
.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}
.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}
.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}
.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}
.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}
.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}
.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}
.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}
.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}
.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}
.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}
.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}
.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}
.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}
.border-pink-100 {
  border-color: var(--pink-100) !important;
}
.border-pink-200 {
  border-color: var(--pink-200) !important;
}
.border-pink-300 {
  border-color: var(--pink-300) !important;
}
.border-pink-400 {
  border-color: var(--pink-400) !important;
}
.border-pink-500 {
  border-color: var(--pink-500) !important;
}
.border-pink-600 {
  border-color: var(--pink-600) !important;
}
.border-pink-700 {
  border-color: var(--pink-700) !important;
}
.border-pink-800 {
  border-color: var(--pink-800) !important;
}
.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}
.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}
.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}
.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}
.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}
.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}
.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}
.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}
.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}
.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}
.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}
.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}
.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}
.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}
.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}
.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}
.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}
.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}
.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}
.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}
.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}
.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}
.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}
.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}
.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}
.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}
.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}
.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}
.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}
.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}
.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}
.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}
.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}
.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}
.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}
.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}
.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}
.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}
.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}
.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}
.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}
.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}
.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}
.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}
.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}
.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}
.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}
.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}
.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}
.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}
.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}
.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}
.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}
.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}
.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}
.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}
.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}
.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}
.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}
.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}
.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}
.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}
.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}
.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}
.border-teal-100 {
  border-color: var(--teal-100) !important;
}
.border-teal-200 {
  border-color: var(--teal-200) !important;
}
.border-teal-300 {
  border-color: var(--teal-300) !important;
}
.border-teal-400 {
  border-color: var(--teal-400) !important;
}
.border-teal-500 {
  border-color: var(--teal-500) !important;
}
.border-teal-600 {
  border-color: var(--teal-600) !important;
}
.border-teal-700 {
  border-color: var(--teal-700) !important;
}
.border-teal-800 {
  border-color: var(--teal-800) !important;
}
.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}
.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}
.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}
.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}
.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}
.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}
.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}
.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}
.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}
.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}
.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}
.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}
.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}
.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}
.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}
.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}
.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}
.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}
.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}
.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}
.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}
.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}
.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}
.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}
.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}
.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}
.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}
.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}
.border-orange-100 {
  border-color: var(--orange-100) !important;
}
.border-orange-200 {
  border-color: var(--orange-200) !important;
}
.border-orange-300 {
  border-color: var(--orange-300) !important;
}
.border-orange-400 {
  border-color: var(--orange-400) !important;
}
.border-orange-500 {
  border-color: var(--orange-500) !important;
}
.border-orange-600 {
  border-color: var(--orange-600) !important;
}
.border-orange-700 {
  border-color: var(--orange-700) !important;
}
.border-orange-800 {
  border-color: var(--orange-800) !important;
}
.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}
.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}
.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}
.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}
.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}
.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}
.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}
.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}
.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}
.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}
.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}
.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}
.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}
.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}
.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}
.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}
.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}
.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}
.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}
.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}
.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}
.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}
.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}
.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}
.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}
.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}
.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}
.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}
.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}
.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}
.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}
.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}
.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}
.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}
.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}
.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}
.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}
.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}
.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}
.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}
.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}
.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}
.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}
.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}
.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}
.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}
.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}
.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}
.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}
.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}
.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}
.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}
.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}
.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}
.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}
.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}
.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}
.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}
.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}
.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}
.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}
.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}
.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}
.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}
.border-purple-100 {
  border-color: var(--purple-100) !important;
}
.border-purple-200 {
  border-color: var(--purple-200) !important;
}
.border-purple-300 {
  border-color: var(--purple-300) !important;
}
.border-purple-400 {
  border-color: var(--purple-400) !important;
}
.border-purple-500 {
  border-color: var(--purple-500) !important;
}
.border-purple-600 {
  border-color: var(--purple-600) !important;
}
.border-purple-700 {
  border-color: var(--purple-700) !important;
}
.border-purple-800 {
  border-color: var(--purple-800) !important;
}
.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}
.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}
.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}
.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}
.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}
.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}
.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}
.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}
.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}
.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}
.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}
.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}
.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}
.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}
.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}
.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}
.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}
.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}
.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}
.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}
.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}
.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}
.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}
.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}
.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}
.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}
.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}
.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}
.border-gray-100 {
  border-color: var(--gray-100) !important;
}
.border-gray-200 {
  border-color: var(--gray-200) !important;
}
.border-gray-300 {
  border-color: var(--gray-300) !important;
}
.border-gray-400 {
  border-color: var(--gray-400) !important;
}
.border-gray-500 {
  border-color: var(--gray-500) !important;
}
.border-gray-600 {
  border-color: var(--gray-600) !important;
}
.border-gray-700 {
  border-color: var(--gray-700) !important;
}
.border-gray-800 {
  border-color: var(--gray-800) !important;
}
.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}
.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}
.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}
.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}
.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}
.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}
.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}
.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}
.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}
.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}
.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}
.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}
.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}
.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}
.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}
.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}
.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}
.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}
.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}
.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}
.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}
.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}
.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}
.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}
.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}
.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}
.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}
.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.border-red-50 {
  border-color: var(--red-50) !important;
}
.border-red-100 {
  border-color: var(--red-100) !important;
}
.border-red-200 {
  border-color: var(--red-200) !important;
}
.border-red-300 {
  border-color: var(--red-300) !important;
}
.border-red-400 {
  border-color: var(--red-400) !important;
}
.border-red-500 {
  border-color: var(--red-500) !important;
}
.border-red-600 {
  border-color: var(--red-600) !important;
}
.border-red-700 {
  border-color: var(--red-700) !important;
}
.border-red-800 {
  border-color: var(--red-800) !important;
}
.border-red-900 {
  border-color: var(--red-900) !important;
}

.focus\:border-red-50:focus {
  border-color: var(--red-50) !important;
}
.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}
.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}
.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}
.focus\:border-red-400:focus {
  border-color: var(--red-400) !important;
}
.focus\:border-red-500:focus {
  border-color: var(--red-500) !important;
}
.focus\:border-red-600:focus {
  border-color: var(--red-600) !important;
}
.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}
.focus\:border-red-800:focus {
  border-color: var(--red-800) !important;
}
.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-50:hover {
  border-color: var(--red-50) !important;
}
.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}
.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}
.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}
.hover\:border-red-400:hover {
  border-color: var(--red-400) !important;
}
.hover\:border-red-500:hover {
  border-color: var(--red-500) !important;
}
.hover\:border-red-600:hover {
  border-color: var(--red-600) !important;
}
.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}
.hover\:border-red-800:hover {
  border-color: var(--red-800) !important;
}
.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-50:active {
  border-color: var(--red-50) !important;
}
.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}
.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}
.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}
.active\:border-red-400:active {
  border-color: var(--red-400) !important;
}
.active\:border-red-500:active {
  border-color: var(--red-500) !important;
}
.active\:border-red-600:active {
  border-color: var(--red-600) !important;
}
.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}
.active\:border-red-800:active {
  border-color: var(--red-800) !important;
}
.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}
.border-primary-100 {
  border-color: var(--primary-100) !important;
}
.border-primary-200 {
  border-color: var(--primary-200) !important;
}
.border-primary-300 {
  border-color: var(--primary-300) !important;
}
.border-primary-400 {
  border-color: var(--primary-400) !important;
}
.border-primary-500 {
  border-color: var(--primary-500) !important;
}
.border-primary-600 {
  border-color: var(--primary-600) !important;
}
.border-primary-700 {
  border-color: var(--primary-700) !important;
}
.border-primary-800 {
  border-color: var(--primary-800) !important;
}
.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.focus\:border-primary-50:focus {
  border-color: var(--primary-50) !important;
}
.focus\:border-primary-100:focus {
  border-color: var(--primary-100) !important;
}
.focus\:border-primary-200:focus {
  border-color: var(--primary-200) !important;
}
.focus\:border-primary-300:focus {
  border-color: var(--primary-300) !important;
}
.focus\:border-primary-400:focus {
  border-color: var(--primary-400) !important;
}
.focus\:border-primary-500:focus {
  border-color: var(--primary-500) !important;
}
.focus\:border-primary-600:focus {
  border-color: var(--primary-600) !important;
}
.focus\:border-primary-700:focus {
  border-color: var(--primary-700) !important;
}
.focus\:border-primary-800:focus {
  border-color: var(--primary-800) !important;
}
.focus\:border-primary-900:focus {
  border-color: var(--primary-900) !important;
}

.hover\:border-primary-50:hover {
  border-color: var(--primary-50) !important;
}
.hover\:border-primary-100:hover {
  border-color: var(--primary-100) !important;
}
.hover\:border-primary-200:hover {
  border-color: var(--primary-200) !important;
}
.hover\:border-primary-300:hover {
  border-color: var(--primary-300) !important;
}
.hover\:border-primary-400:hover {
  border-color: var(--primary-400) !important;
}
.hover\:border-primary-500:hover {
  border-color: var(--primary-500) !important;
}
.hover\:border-primary-600:hover {
  border-color: var(--primary-600) !important;
}
.hover\:border-primary-700:hover {
  border-color: var(--primary-700) !important;
}
.hover\:border-primary-800:hover {
  border-color: var(--primary-800) !important;
}
.hover\:border-primary-900:hover {
  border-color: var(--primary-900) !important;
}

.active\:border-primary-50:active {
  border-color: var(--primary-50) !important;
}
.active\:border-primary-100:active {
  border-color: var(--primary-100) !important;
}
.active\:border-primary-200:active {
  border-color: var(--primary-200) !important;
}
.active\:border-primary-300:active {
  border-color: var(--primary-300) !important;
}
.active\:border-primary-400:active {
  border-color: var(--primary-400) !important;
}
.active\:border-primary-500:active {
  border-color: var(--primary-500) !important;
}
.active\:border-primary-600:active {
  border-color: var(--primary-600) !important;
}
.active\:border-primary-700:active {
  border-color: var(--primary-700) !important;
}
.active\:border-primary-800:active {
  border-color: var(--primary-800) !important;
}
.active\:border-primary-900:active {
  border-color: var(--primary-900) !important;
}

.bg-white-alpha-10 {
  background-color: rgba(255,255,255,0.1) !important;
}
.bg-white-alpha-20 {
  background-color: rgba(255,255,255,0.2) !important;
}
.bg-white-alpha-30 {
  background-color: rgba(255,255,255,0.3) !important;
}
.bg-white-alpha-40 {
  background-color: rgba(255,255,255,0.4) !important;
}
.bg-white-alpha-50 {
  background-color: rgba(255,255,255,0.5) !important;
}
.bg-white-alpha-60 {
  background-color: rgba(255,255,255,0.6) !important;
}
.bg-white-alpha-70 {
  background-color: rgba(255,255,255,0.7) !important;
}
.bg-white-alpha-80 {
  background-color: rgba(255,255,255,0.8) !important;
}
.bg-white-alpha-90 {
  background-color: rgba(255,255,255,0.9) !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: rgba(255,255,255,0.1) !important;
}
.hover\:bg-white-alpha-20:hover {
  background-color: rgba(255,255,255,0.2) !important;
}
.hover\:bg-white-alpha-30:hover {
  background-color: rgba(255,255,255,0.3) !important;
}
.hover\:bg-white-alpha-40:hover {
  background-color: rgba(255,255,255,0.4) !important;
}
.hover\:bg-white-alpha-50:hover {
  background-color: rgba(255,255,255,0.5) !important;
}
.hover\:bg-white-alpha-60:hover {
  background-color: rgba(255,255,255,0.6) !important;
}
.hover\:bg-white-alpha-70:hover {
  background-color: rgba(255,255,255,0.7) !important;
}
.hover\:bg-white-alpha-80:hover {
  background-color: rgba(255,255,255,0.8) !important;
}
.hover\:bg-white-alpha-90:hover {
  background-color: rgba(255,255,255,0.9) !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: rgba(255,255,255,0.1) !important;
}
.focus\:bg-white-alpha-20:focus {
  background-color: rgba(255,255,255,0.2) !important;
}
.focus\:bg-white-alpha-30:focus {
  background-color: rgba(255,255,255,0.3) !important;
}
.focus\:bg-white-alpha-40:focus {
  background-color: rgba(255,255,255,0.4) !important;
}
.focus\:bg-white-alpha-50:focus {
  background-color: rgba(255,255,255,0.5) !important;
}
.focus\:bg-white-alpha-60:focus {
  background-color: rgba(255,255,255,0.6) !important;
}
.focus\:bg-white-alpha-70:focus {
  background-color: rgba(255,255,255,0.7) !important;
}
.focus\:bg-white-alpha-80:focus {
  background-color: rgba(255,255,255,0.8) !important;
}
.focus\:bg-white-alpha-90:focus {
  background-color: rgba(255,255,255,0.9) !important;
}

.active\:bg-white-alpha-10:active {
  background-color: rgba(255,255,255,0.1) !important;
}
.active\:bg-white-alpha-20:active {
  background-color: rgba(255,255,255,0.2) !important;
}
.active\:bg-white-alpha-30:active {
  background-color: rgba(255,255,255,0.3) !important;
}
.active\:bg-white-alpha-40:active {
  background-color: rgba(255,255,255,0.4) !important;
}
.active\:bg-white-alpha-50:active {
  background-color: rgba(255,255,255,0.5) !important;
}
.active\:bg-white-alpha-60:active {
  background-color: rgba(255,255,255,0.6) !important;
}
.active\:bg-white-alpha-70:active {
  background-color: rgba(255,255,255,0.7) !important;
}
.active\:bg-white-alpha-80:active {
  background-color: rgba(255,255,255,0.8) !important;
}
.active\:bg-white-alpha-90:active {
  background-color: rgba(255,255,255,0.9) !important;
}

.bg-black-alpha-10 {
  background-color: rgba(0,0,0,0.1) !important;
}
.bg-black-alpha-20 {
  background-color: rgba(0,0,0,0.2) !important;
}
.bg-black-alpha-30 {
  background-color: rgba(0,0,0,0.3) !important;
}
.bg-black-alpha-40 {
  background-color: rgba(0,0,0,0.4) !important;
}
.bg-black-alpha-50 {
  background-color: rgba(0,0,0,0.5) !important;
}
.bg-black-alpha-60 {
  background-color: rgba(0,0,0,0.6) !important;
}
.bg-black-alpha-70 {
  background-color: rgba(0,0,0,0.7) !important;
}
.bg-black-alpha-80 {
  background-color: rgba(0,0,0,0.8) !important;
}
.bg-black-alpha-90 {
  background-color: rgba(0,0,0,0.9) !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: rgba(0,0,0,0.1) !important;
}
.hover\:bg-black-alpha-20:hover {
  background-color: rgba(0,0,0,0.2) !important;
}
.hover\:bg-black-alpha-30:hover {
  background-color: rgba(0,0,0,0.3) !important;
}
.hover\:bg-black-alpha-40:hover {
  background-color: rgba(0,0,0,0.4) !important;
}
.hover\:bg-black-alpha-50:hover {
  background-color: rgba(0,0,0,0.5) !important;
}
.hover\:bg-black-alpha-60:hover {
  background-color: rgba(0,0,0,0.6) !important;
}
.hover\:bg-black-alpha-70:hover {
  background-color: rgba(0,0,0,0.7) !important;
}
.hover\:bg-black-alpha-80:hover {
  background-color: rgba(0,0,0,0.8) !important;
}
.hover\:bg-black-alpha-90:hover {
  background-color: rgba(0,0,0,0.9) !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: rgba(0,0,0,0.1) !important;
}
.focus\:bg-black-alpha-20:focus {
  background-color: rgba(0,0,0,0.2) !important;
}
.focus\:bg-black-alpha-30:focus {
  background-color: rgba(0,0,0,0.3) !important;
}
.focus\:bg-black-alpha-40:focus {
  background-color: rgba(0,0,0,0.4) !important;
}
.focus\:bg-black-alpha-50:focus {
  background-color: rgba(0,0,0,0.5) !important;
}
.focus\:bg-black-alpha-60:focus {
  background-color: rgba(0,0,0,0.6) !important;
}
.focus\:bg-black-alpha-70:focus {
  background-color: rgba(0,0,0,0.7) !important;
}
.focus\:bg-black-alpha-80:focus {
  background-color: rgba(0,0,0,0.8) !important;
}
.focus\:bg-black-alpha-90:focus {
  background-color: rgba(0,0,0,0.9) !important;
}

.active\:bg-black-alpha-10:active {
  background-color: rgba(0,0,0,0.1) !important;
}
.active\:bg-black-alpha-20:active {
  background-color: rgba(0,0,0,0.2) !important;
}
.active\:bg-black-alpha-30:active {
  background-color: rgba(0,0,0,0.3) !important;
}
.active\:bg-black-alpha-40:active {
  background-color: rgba(0,0,0,0.4) !important;
}
.active\:bg-black-alpha-50:active {
  background-color: rgba(0,0,0,0.5) !important;
}
.active\:bg-black-alpha-60:active {
  background-color: rgba(0,0,0,0.6) !important;
}
.active\:bg-black-alpha-70:active {
  background-color: rgba(0,0,0,0.7) !important;
}
.active\:bg-black-alpha-80:active {
  background-color: rgba(0,0,0,0.8) !important;
}
.active\:bg-black-alpha-90:active {
  background-color: rgba(0,0,0,0.9) !important;
}

.border-white-alpha-10 {
  border-color: rgba(255,255,255,0.1) !important;
}
.border-white-alpha-20 {
  border-color: rgba(255,255,255,0.2) !important;
}
.border-white-alpha-30 {
  border-color: rgba(255,255,255,0.3) !important;
}
.border-white-alpha-40 {
  border-color: rgba(255,255,255,0.4) !important;
}
.border-white-alpha-50 {
  border-color: rgba(255,255,255,0.5) !important;
}
.border-white-alpha-60 {
  border-color: rgba(255,255,255,0.6) !important;
}
.border-white-alpha-70 {
  border-color: rgba(255,255,255,0.7) !important;
}
.border-white-alpha-80 {
  border-color: rgba(255,255,255,0.8) !important;
}
.border-white-alpha-90 {
  border-color: rgba(255,255,255,0.9) !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: rgba(255,255,255,0.1) !important;
}
.hover\:border-white-alpha-20:hover {
  border-color: rgba(255,255,255,0.2) !important;
}
.hover\:border-white-alpha-30:hover {
  border-color: rgba(255,255,255,0.3) !important;
}
.hover\:border-white-alpha-40:hover {
  border-color: rgba(255,255,255,0.4) !important;
}
.hover\:border-white-alpha-50:hover {
  border-color: rgba(255,255,255,0.5) !important;
}
.hover\:border-white-alpha-60:hover {
  border-color: rgba(255,255,255,0.6) !important;
}
.hover\:border-white-alpha-70:hover {
  border-color: rgba(255,255,255,0.7) !important;
}
.hover\:border-white-alpha-80:hover {
  border-color: rgba(255,255,255,0.8) !important;
}
.hover\:border-white-alpha-90:hover {
  border-color: rgba(255,255,255,0.9) !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: rgba(255,255,255,0.1) !important;
}
.focus\:border-white-alpha-20:focus {
  border-color: rgba(255,255,255,0.2) !important;
}
.focus\:border-white-alpha-30:focus {
  border-color: rgba(255,255,255,0.3) !important;
}
.focus\:border-white-alpha-40:focus {
  border-color: rgba(255,255,255,0.4) !important;
}
.focus\:border-white-alpha-50:focus {
  border-color: rgba(255,255,255,0.5) !important;
}
.focus\:border-white-alpha-60:focus {
  border-color: rgba(255,255,255,0.6) !important;
}
.focus\:border-white-alpha-70:focus {
  border-color: rgba(255,255,255,0.7) !important;
}
.focus\:border-white-alpha-80:focus {
  border-color: rgba(255,255,255,0.8) !important;
}
.focus\:border-white-alpha-90:focus {
  border-color: rgba(255,255,255,0.9) !important;
}

.active\:border-white-alpha-10:active {
  border-color: rgba(255,255,255,0.1) !important;
}
.active\:border-white-alpha-20:active {
  border-color: rgba(255,255,255,0.2) !important;
}
.active\:border-white-alpha-30:active {
  border-color: rgba(255,255,255,0.3) !important;
}
.active\:border-white-alpha-40:active {
  border-color: rgba(255,255,255,0.4) !important;
}
.active\:border-white-alpha-50:active {
  border-color: rgba(255,255,255,0.5) !important;
}
.active\:border-white-alpha-60:active {
  border-color: rgba(255,255,255,0.6) !important;
}
.active\:border-white-alpha-70:active {
  border-color: rgba(255,255,255,0.7) !important;
}
.active\:border-white-alpha-80:active {
  border-color: rgba(255,255,255,0.8) !important;
}
.active\:border-white-alpha-90:active {
  border-color: rgba(255,255,255,0.9) !important;
}

.border-black-alpha-10 {
  border-color: rgba(0,0,0,0.1) !important;
}
.border-black-alpha-20 {
  border-color: rgba(0,0,0,0.2) !important;
}
.border-black-alpha-30 {
  border-color: rgba(0,0,0,0.3) !important;
}
.border-black-alpha-40 {
  border-color: rgba(0,0,0,0.4) !important;
}
.border-black-alpha-50 {
  border-color: rgba(0,0,0,0.5) !important;
}
.border-black-alpha-60 {
  border-color: rgba(0,0,0,0.6) !important;
}
.border-black-alpha-70 {
  border-color: rgba(0,0,0,0.7) !important;
}
.border-black-alpha-80 {
  border-color: rgba(0,0,0,0.8) !important;
}
.border-black-alpha-90 {
  border-color: rgba(0,0,0,0.9) !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: rgba(0,0,0,0.1) !important;
}
.hover\:border-black-alpha-20:hover {
  border-color: rgba(0,0,0,0.2) !important;
}
.hover\:border-black-alpha-30:hover {
  border-color: rgba(0,0,0,0.3) !important;
}
.hover\:border-black-alpha-40:hover {
  border-color: rgba(0,0,0,0.4) !important;
}
.hover\:border-black-alpha-50:hover {
  border-color: rgba(0,0,0,0.5) !important;
}
.hover\:border-black-alpha-60:hover {
  border-color: rgba(0,0,0,0.6) !important;
}
.hover\:border-black-alpha-70:hover {
  border-color: rgba(0,0,0,0.7) !important;
}
.hover\:border-black-alpha-80:hover {
  border-color: rgba(0,0,0,0.8) !important;
}
.hover\:border-black-alpha-90:hover {
  border-color: rgba(0,0,0,0.9) !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: rgba(0,0,0,0.1) !important;
}
.focus\:border-black-alpha-20:focus {
  border-color: rgba(0,0,0,0.2) !important;
}
.focus\:border-black-alpha-30:focus {
  border-color: rgba(0,0,0,0.3) !important;
}
.focus\:border-black-alpha-40:focus {
  border-color: rgba(0,0,0,0.4) !important;
}
.focus\:border-black-alpha-50:focus {
  border-color: rgba(0,0,0,0.5) !important;
}
.focus\:border-black-alpha-60:focus {
  border-color: rgba(0,0,0,0.6) !important;
}
.focus\:border-black-alpha-70:focus {
  border-color: rgba(0,0,0,0.7) !important;
}
.focus\:border-black-alpha-80:focus {
  border-color: rgba(0,0,0,0.8) !important;
}
.focus\:border-black-alpha-90:focus {
  border-color: rgba(0,0,0,0.9) !important;
}

.active\:border-black-alpha-10:active {
  border-color: rgba(0,0,0,0.1) !important;
}
.active\:border-black-alpha-20:active {
  border-color: rgba(0,0,0,0.2) !important;
}
.active\:border-black-alpha-30:active {
  border-color: rgba(0,0,0,0.3) !important;
}
.active\:border-black-alpha-40:active {
  border-color: rgba(0,0,0,0.4) !important;
}
.active\:border-black-alpha-50:active {
  border-color: rgba(0,0,0,0.5) !important;
}
.active\:border-black-alpha-60:active {
  border-color: rgba(0,0,0,0.6) !important;
}
.active\:border-black-alpha-70:active {
  border-color: rgba(0,0,0,0.7) !important;
}
.active\:border-black-alpha-80:active {
  border-color: rgba(0,0,0,0.8) !important;
}
.active\:border-black-alpha-90:active {
  border-color: rgba(0,0,0,0.9) !important;
}

.text-white-alpha-10 {
  color: rgba(255,255,255,0.1) !important;
}
.text-white-alpha-20 {
  color: rgba(255,255,255,0.2) !important;
}
.text-white-alpha-30 {
  color: rgba(255,255,255,0.3) !important;
}
.text-white-alpha-40 {
  color: rgba(255,255,255,0.4) !important;
}
.text-white-alpha-50 {
  color: rgba(255,255,255,0.5) !important;
}
.text-white-alpha-60 {
  color: rgba(255,255,255,0.6) !important;
}
.text-white-alpha-70 {
  color: rgba(255,255,255,0.7) !important;
}
.text-white-alpha-80 {
  color: rgba(255,255,255,0.8) !important;
}
.text-white-alpha-90 {
  color: rgba(255,255,255,0.9) !important;
}

.hover\:text-white-alpha-10:hover {
  color: rgba(255,255,255,0.1) !important;
}
.hover\:text-white-alpha-20:hover {
  color: rgba(255,255,255,0.2) !important;
}
.hover\:text-white-alpha-30:hover {
  color: rgba(255,255,255,0.3) !important;
}
.hover\:text-white-alpha-40:hover {
  color: rgba(255,255,255,0.4) !important;
}
.hover\:text-white-alpha-50:hover {
  color: rgba(255,255,255,0.5) !important;
}
.hover\:text-white-alpha-60:hover {
  color: rgba(255,255,255,0.6) !important;
}
.hover\:text-white-alpha-70:hover {
  color: rgba(255,255,255,0.7) !important;
}
.hover\:text-white-alpha-80:hover {
  color: rgba(255,255,255,0.8) !important;
}
.hover\:text-white-alpha-90:hover {
  color: rgba(255,255,255,0.9) !important;
}

.focus\:text-white-alpha-10:focus {
  color: rgba(255,255,255,0.1) !important;
}
.focus\:text-white-alpha-20:focus {
  color: rgba(255,255,255,0.2) !important;
}
.focus\:text-white-alpha-30:focus {
  color: rgba(255,255,255,0.3) !important;
}
.focus\:text-white-alpha-40:focus {
  color: rgba(255,255,255,0.4) !important;
}
.focus\:text-white-alpha-50:focus {
  color: rgba(255,255,255,0.5) !important;
}
.focus\:text-white-alpha-60:focus {
  color: rgba(255,255,255,0.6) !important;
}
.focus\:text-white-alpha-70:focus {
  color: rgba(255,255,255,0.7) !important;
}
.focus\:text-white-alpha-80:focus {
  color: rgba(255,255,255,0.8) !important;
}
.focus\:text-white-alpha-90:focus {
  color: rgba(255,255,255,0.9) !important;
}

.active\:text-white-alpha-10:active {
  color: rgba(255,255,255,0.1) !important;
}
.active\:text-white-alpha-20:active {
  color: rgba(255,255,255,0.2) !important;
}
.active\:text-white-alpha-30:active {
  color: rgba(255,255,255,0.3) !important;
}
.active\:text-white-alpha-40:active {
  color: rgba(255,255,255,0.4) !important;
}
.active\:text-white-alpha-50:active {
  color: rgba(255,255,255,0.5) !important;
}
.active\:text-white-alpha-60:active {
  color: rgba(255,255,255,0.6) !important;
}
.active\:text-white-alpha-70:active {
  color: rgba(255,255,255,0.7) !important;
}
.active\:text-white-alpha-80:active {
  color: rgba(255,255,255,0.8) !important;
}
.active\:text-white-alpha-90:active {
  color: rgba(255,255,255,0.9) !important;
}

.text-black-alpha-10 {
  color: rgba(0,0,0,0.1) !important;
}
.text-black-alpha-20 {
  color: rgba(0,0,0,0.2) !important;
}
.text-black-alpha-30 {
  color: rgba(0,0,0,0.3) !important;
}
.text-black-alpha-40 {
  color: rgba(0,0,0,0.4) !important;
}
.text-black-alpha-50 {
  color: rgba(0,0,0,0.5) !important;
}
.text-black-alpha-60 {
  color: rgba(0,0,0,0.6) !important;
}
.text-black-alpha-70 {
  color: rgba(0,0,0,0.7) !important;
}
.text-black-alpha-80 {
  color: rgba(0,0,0,0.8) !important;
}
.text-black-alpha-90 {
  color: rgba(0,0,0,0.9) !important;
}

.hover\:text-black-alpha-10:hover {
  color: rgba(0,0,0,0.1) !important;
}
.hover\:text-black-alpha-20:hover {
  color: rgba(0,0,0,0.2) !important;
}
.hover\:text-black-alpha-30:hover {
  color: rgba(0,0,0,0.3) !important;
}
.hover\:text-black-alpha-40:hover {
  color: rgba(0,0,0,0.4) !important;
}
.hover\:text-black-alpha-50:hover {
  color: rgba(0,0,0,0.5) !important;
}
.hover\:text-black-alpha-60:hover {
  color: rgba(0,0,0,0.6) !important;
}
.hover\:text-black-alpha-70:hover {
  color: rgba(0,0,0,0.7) !important;
}
.hover\:text-black-alpha-80:hover {
  color: rgba(0,0,0,0.8) !important;
}
.hover\:text-black-alpha-90:hover {
  color: rgba(0,0,0,0.9) !important;
}

.focus\:text-black-alpha-10:focus {
  color: rgba(0,0,0,0.1) !important;
}
.focus\:text-black-alpha-20:focus {
  color: rgba(0,0,0,0.2) !important;
}
.focus\:text-black-alpha-30:focus {
  color: rgba(0,0,0,0.3) !important;
}
.focus\:text-black-alpha-40:focus {
  color: rgba(0,0,0,0.4) !important;
}
.focus\:text-black-alpha-50:focus {
  color: rgba(0,0,0,0.5) !important;
}
.focus\:text-black-alpha-60:focus {
  color: rgba(0,0,0,0.6) !important;
}
.focus\:text-black-alpha-70:focus {
  color: rgba(0,0,0,0.7) !important;
}
.focus\:text-black-alpha-80:focus {
  color: rgba(0,0,0,0.8) !important;
}
.focus\:text-black-alpha-90:focus {
  color: rgba(0,0,0,0.9) !important;
}

.active\:text-black-alpha-10:active {
  color: rgba(0,0,0,0.1) !important;
}
.active\:text-black-alpha-20:active {
  color: rgba(0,0,0,0.2) !important;
}
.active\:text-black-alpha-30:active {
  color: rgba(0,0,0,0.3) !important;
}
.active\:text-black-alpha-40:active {
  color: rgba(0,0,0,0.4) !important;
}
.active\:text-black-alpha-50:active {
  color: rgba(0,0,0,0.5) !important;
}
.active\:text-black-alpha-60:active {
  color: rgba(0,0,0,0.6) !important;
}
.active\:text-black-alpha-70:active {
  color: rgba(0,0,0,0.7) !important;
}
.active\:text-black-alpha-80:active {
  color: rgba(0,0,0,0.8) !important;
}
.active\:text-black-alpha-90:active {
  color: rgba(0,0,0,0.9) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #ffffff !important;
}

.border-primary-light {
  border-color: #d8e370 !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus {
  color: var(--primary-color) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.hover\:bg-primary:hover {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.hover\:bg-primary-reverse:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus {
  background-color: #ffffff !important;
}

.hover\:bg-white:hover {
  background-color: #ffffff !important;
}

.active\:bg-white:active {
  background-color: #ffffff !important;
}

.focus\:border-primary:focus {
  border-color: var(--primary-color) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary-color) !important;
}

.active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus {
  color: #ffffff !important;
}

.hover\:text-white:hover {
  color: #ffffff !important;
}

.active\:text-white:active {
  color: #ffffff !important;
}

.focus\:border-white:focus {
  border-color: #ffffff !important;
}

.hover\:border-white:hover {
  border-color: #ffffff !important;
}

.active\:border-white:active {
  border-color: #ffffff !important;
}

.focus\:text-color:focus {
  color: var(--text-color) !important;
}

.hover\:text-color:hover {
  color: var(--text-color) !important;
}

.active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus {
  color: var(--text-color-secondary) !important;
}

.hover\:text-color-secondary:hover {
  color: var(--text-color-secondary) !important;
}

.active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus {
  background-color: var(--surface-ground) !important;
}

.hover\:surface-ground:hover {
  background-color: var(--surface-ground) !important;
}

.active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus {
  background-color: var(--surface-section) !important;
}

.hover\:surface-section:hover {
  background-color: var(--surface-section) !important;
}

.active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus {
  background-color: var(--surface-card) !important;
}

.hover\:surface-card:hover {
  background-color: var(--surface-card) !important;
}

.active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus {
  background-color: var(--surface-overlay) !important;
}

.hover\:surface-overlay:hover {
  background-color: var(--surface-overlay) !important;
}

.active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus {
  background-color: var(--surface-hover) !important;
}

.hover\:surface-hover:hover {
  background-color: var(--surface-hover) !important;
}

.active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus {
  border-color: var(--surface-border) !important;
}

.hover\:surface-border:hover {
  border-color: var(--surface-border) !important;
}

.active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }
  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:bg-white {
    background-color: #ffffff !important;
  }
  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .sm\:text-white {
    color: #ffffff !important;
  }
  .sm\:border-white {
    border-color: #ffffff !important;
  }
  .sm\:text-color {
    color: var(--text-color) !important;
  }
  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .sm\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .sm\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .sm\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .sm\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .sm\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .sm\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .sm\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .sm\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .sm\:active\:text-white:active {
    color: #ffffff !important;
  }
  .sm\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .sm\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .sm\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .sm\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .sm\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .sm\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .sm\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .sm\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .sm\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .sm\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .sm\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .sm\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .sm\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .sm\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .sm\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .sm\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .sm\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }
  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:bg-white {
    background-color: #ffffff !important;
  }
  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .md\:text-white {
    color: #ffffff !important;
  }
  .md\:border-white {
    border-color: #ffffff !important;
  }
  .md\:text-color {
    color: var(--text-color) !important;
  }
  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .md\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .md\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .md\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .md\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .md\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .md\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .md\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .md\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .md\:active\:text-white:active {
    color: #ffffff !important;
  }
  .md\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .md\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .md\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .md\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .md\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .md\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .md\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .md\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .md\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .md\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .md\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .md\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .md\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .md\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .md\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .md\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .md\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .md\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .md\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }
  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:bg-white {
    background-color: #ffffff !important;
  }
  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .lg\:text-white {
    color: #ffffff !important;
  }
  .lg\:border-white {
    border-color: #ffffff !important;
  }
  .lg\:text-color {
    color: var(--text-color) !important;
  }
  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .lg\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .lg\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .lg\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .lg\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .lg\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .lg\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .lg\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .lg\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .lg\:active\:text-white:active {
    color: #ffffff !important;
  }
  .lg\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .lg\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .lg\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .lg\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .lg\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .lg\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .lg\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .lg\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .lg\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .lg\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .lg\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .lg\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .lg\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .lg\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .lg\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .lg\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .lg\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }
  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:bg-white {
    background-color: #ffffff !important;
  }
  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xl\:text-white {
    color: #ffffff !important;
  }
  .xl\:border-white {
    border-color: #ffffff !important;
  }
  .xl\:text-color {
    color: var(--text-color) !important;
  }
  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xl\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xl\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xl\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xl\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xl\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xl\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xl\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xl\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xl\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xl\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xl\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xl\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xl\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xl\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xl\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xl\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xl\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xl\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xl\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xl\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xl\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xl\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xl\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xl\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xl\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xl\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
.field {
  margin-bottom: 1rem;
}

.field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.field.grid > label {
  display: flex;
  align-items: center;
}

.field > small {
  margin-top: 0.25rem;
}

.field.grid,
.formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed,
.formgrid.grid .col-fixed,
.field.grid .col,
.formgrid.grid .col,
.field.grid .col-1,
.formgrid.grid .col-1,
.field.grid .col-2,
.formgrid.grid .col-2,
.field.grid .col-3,
.formgrid.grid .col-3,
.field.grid .col-4,
.formgrid.grid .col-4,
.field.grid .col-5,
.formgrid.grid .col-5,
.field.grid .col-6,
.formgrid.grid .col-6,
.field.grid .col-7,
.formgrid.grid .col-7,
.field.grid .col-8,
.formgrid.grid .col-8,
.field.grid .col-9,
.formgrid.grid .col-9,
.field.grid .col-10,
.formgrid.grid .col-10,
.field.grid .col-11,
.formgrid.grid .col-11,
.field.grid .col-12,
.formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.field-checkbox > label,
.field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:inline {
    display: inline !important;
  }
  .sm\:inline-block {
    display: inline-block !important;
  }
  .sm\:flex {
    display: flex !important;
  }
  .sm\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline {
    display: inline !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: flex !important;
  }
  .md\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline {
    display: inline !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: flex !important;
  }
  .lg\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:hidden {
    display: none !important;
  }
  .xl\:block {
    display: block !important;
  }
  .xl\:inline {
    display: inline !important;
  }
  .xl\:inline-block {
    display: inline-block !important;
  }
  .xl\:flex {
    display: flex !important;
  }
  .xl\:inline-flex {
    display: inline-flex !important;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-justify {
    text-align: justify !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-justify {
    text-align: justify !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-justify {
    text-align: justify !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-justify {
    text-align: justify !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
}
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }
  .sm\:font-normal {
    font-weight: 400 !important;
  }
  .sm\:font-medium {
    font-weight: 500 !important;
  }
  .sm\:font-semibold {
    font-weight: 600 !important;
  }
  .sm\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:font-light {
    font-weight: 300 !important;
  }
  .md\:font-normal {
    font-weight: 400 !important;
  }
  .md\:font-medium {
    font-weight: 500 !important;
  }
  .md\:font-semibold {
    font-weight: 600 !important;
  }
  .md\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }
  .lg\:font-normal {
    font-weight: 400 !important;
  }
  .lg\:font-medium {
    font-weight: 500 !important;
  }
  .lg\:font-semibold {
    font-weight: 600 !important;
  }
  .lg\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }
  .xl\:font-normal {
    font-weight: 400 !important;
  }
  .xl\:font-medium {
    font-weight: 500 !important;
  }
  .xl\:font-semibold {
    font-weight: 600 !important;
  }
  .xl\:font-bold {
    font-weight: 700 !important;
  }
}
.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-xs {
    font-size: 0.75rem !important;
  }
  .sm\:text-sm {
    font-size: 0.875rem !important;
  }
  .sm\:text-base {
    font-size: 1rem !important;
  }
  .sm\:text-lg {
    font-size: 1.125rem !important;
  }
  .sm\:text-xl {
    font-size: 1.25rem !important;
  }
  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }
  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }
  .sm\:text-4xl {
    font-size: 2rem !important;
  }
  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }
  .sm\:text-6xl {
    font-size: 3rem !important;
  }
  .sm\:text-7xl {
    font-size: 4rem !important;
  }
  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-xs {
    font-size: 0.75rem !important;
  }
  .md\:text-sm {
    font-size: 0.875rem !important;
  }
  .md\:text-base {
    font-size: 1rem !important;
  }
  .md\:text-lg {
    font-size: 1.125rem !important;
  }
  .md\:text-xl {
    font-size: 1.25rem !important;
  }
  .md\:text-2xl {
    font-size: 1.5rem !important;
  }
  .md\:text-3xl {
    font-size: 1.75rem !important;
  }
  .md\:text-4xl {
    font-size: 2rem !important;
  }
  .md\:text-5xl {
    font-size: 2.5rem !important;
  }
  .md\:text-6xl {
    font-size: 3rem !important;
  }
  .md\:text-7xl {
    font-size: 4rem !important;
  }
  .md\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-xs {
    font-size: 0.75rem !important;
  }
  .lg\:text-sm {
    font-size: 0.875rem !important;
  }
  .lg\:text-base {
    font-size: 1rem !important;
  }
  .lg\:text-lg {
    font-size: 1.125rem !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
  }
  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }
  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }
  .lg\:text-4xl {
    font-size: 2rem !important;
  }
  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }
  .lg\:text-6xl {
    font-size: 3rem !important;
  }
  .lg\:text-7xl {
    font-size: 4rem !important;
  }
  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-xs {
    font-size: 0.75rem !important;
  }
  .xl\:text-sm {
    font-size: 0.875rem !important;
  }
  .xl\:text-base {
    font-size: 1rem !important;
  }
  .xl\:text-lg {
    font-size: 1.125rem !important;
  }
  .xl\:text-xl {
    font-size: 1.25rem !important;
  }
  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }
  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }
  .xl\:text-4xl {
    font-size: 2rem !important;
  }
  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }
  .xl\:text-6xl {
    font-size: 3rem !important;
  }
  .xl\:text-7xl {
    font-size: 4rem !important;
  }
  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}
.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (min-width: 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .sm\:vertical-align-top {
    vertical-align: top !important;
  }
  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .md\:vertical-align-top {
    vertical-align: top !important;
  }
  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .lg\:vertical-align-top {
    vertical-align: top !important;
  }
  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xl\:vertical-align-top {
    vertical-align: top !important;
  }
  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:flex-column {
    flex-direction: column !important;
  }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg\:flex-column {
    flex-direction: column !important;
  }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl\:flex-column {
    flex-direction: column !important;
  }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    justify-content: center !important;
  }
  .sm\:justify-content-between {
    justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    justify-content: space-around !important;
  }
  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    justify-content: center !important;
  }
  .md\:justify-content-between {
    justify-content: space-between !important;
  }
  .md\:justify-content-around {
    justify-content: space-around !important;
  }
  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    justify-content: center !important;
  }
  .lg\:justify-content-between {
    justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    justify-content: space-around !important;
  }
  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    justify-content: center !important;
  }
  .xl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }
  .sm\:align-content-end {
    align-content: flex-end !important;
  }
  .sm\:align-content-center {
    align-content: center !important;
  }
  .sm\:align-content-between {
    align-content: space-between !important;
  }
  .sm\:align-content-around {
    align-content: space-around !important;
  }
  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }
  .md\:align-content-end {
    align-content: flex-end !important;
  }
  .md\:align-content-center {
    align-content: center !important;
  }
  .md\:align-content-between {
    align-content: space-between !important;
  }
  .md\:align-content-around {
    align-content: space-around !important;
  }
  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }
  .lg\:align-content-end {
    align-content: flex-end !important;
  }
  .lg\:align-content-center {
    align-content: center !important;
  }
  .lg\:align-content-between {
    align-content: space-between !important;
  }
  .lg\:align-content-around {
    align-content: space-around !important;
  }
  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }
  .xl\:align-content-end {
    align-content: flex-end !important;
  }
  .xl\:align-content-center {
    align-content: center !important;
  }
  .xl\:align-content-between {
    align-content: space-between !important;
  }
  .xl\:align-content-around {
    align-content: space-around !important;
  }
  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }
  .sm\:align-items-start {
    align-items: flex-start !important;
  }
  .sm\:align-items-center {
    align-items: center !important;
  }
  .sm\:align-items-end {
    align-items: flex-end !important;
  }
  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }
  .md\:align-items-start {
    align-items: flex-start !important;
  }
  .md\:align-items-center {
    align-items: center !important;
  }
  .md\:align-items-end {
    align-items: flex-end !important;
  }
  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }
  .lg\:align-items-start {
    align-items: flex-start !important;
  }
  .lg\:align-items-center {
    align-items: center !important;
  }
  .lg\:align-items-end {
    align-items: flex-end !important;
  }
  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xl\:align-items-start {
    align-items: flex-start !important;
  }
  .xl\:align-items-center {
    align-items: center !important;
  }
  .xl\:align-items-end {
    align-items: flex-end !important;
  }
  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}
.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }
  .sm\:align-self-start {
    align-self: flex-start !important;
  }
  .sm\:align-self-end {
    align-self: flex-end !important;
  }
  .sm\:align-self-center {
    align-self: center !important;
  }
  .sm\:align-self-stretch {
    align-self: stretch !important;
  }
  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }
  .md\:align-self-start {
    align-self: flex-start !important;
  }
  .md\:align-self-end {
    align-self: flex-end !important;
  }
  .md\:align-self-center {
    align-self: center !important;
  }
  .md\:align-self-stretch {
    align-self: stretch !important;
  }
  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }
  .lg\:align-self-start {
    align-self: flex-start !important;
  }
  .lg\:align-self-end {
    align-self: flex-end !important;
  }
  .lg\:align-self-center {
    align-self: center !important;
  }
  .lg\:align-self-stretch {
    align-self: stretch !important;
  }
  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }
  .xl\:align-self-start {
    align-self: flex-start !important;
  }
  .xl\:align-self-end {
    align-self: flex-end !important;
  }
  .xl\:align-self-center {
    align-self: center !important;
  }
  .xl\:align-self-stretch {
    align-self: stretch !important;
  }
  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}
.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }
  .sm\:flex-order-1 {
    order: 1 !important;
  }
  .sm\:flex-order-2 {
    order: 2 !important;
  }
  .sm\:flex-order-3 {
    order: 3 !important;
  }
  .sm\:flex-order-4 {
    order: 4 !important;
  }
  .sm\:flex-order-5 {
    order: 5 !important;
  }
  .sm\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }
  .md\:flex-order-1 {
    order: 1 !important;
  }
  .md\:flex-order-2 {
    order: 2 !important;
  }
  .md\:flex-order-3 {
    order: 3 !important;
  }
  .md\:flex-order-4 {
    order: 4 !important;
  }
  .md\:flex-order-5 {
    order: 5 !important;
  }
  .md\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }
  .lg\:flex-order-1 {
    order: 1 !important;
  }
  .lg\:flex-order-2 {
    order: 2 !important;
  }
  .lg\:flex-order-3 {
    order: 3 !important;
  }
  .lg\:flex-order-4 {
    order: 4 !important;
  }
  .lg\:flex-order-5 {
    order: 5 !important;
  }
  .lg\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }
  .xl\:flex-order-1 {
    order: 1 !important;
  }
  .xl\:flex-order-2 {
    order: 2 !important;
  }
  .xl\:flex-order-3 {
    order: 3 !important;
  }
  .xl\:flex-order-4 {
    order: 4 !important;
  }
  .xl\:flex-order-5 {
    order: 5 !important;
  }
  .xl\:flex-order-6 {
    order: 6 !important;
  }
}
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }
  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }
  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }
  .sm\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-1 {
    flex: 1 1 0% !important;
  }
  .md\:flex-auto {
    flex: 1 1 auto !important;
  }
  .md\:flex-initial {
    flex: 0 1 auto !important;
  }
  .md\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }
  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }
  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }
  .lg\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xl\:flex-none {
    flex: none !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.gap-0 {
  gap: 0rem !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0rem !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 3rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0rem !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-6 {
  column-gap: 3rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0rem !important;
  }
  .sm\:gap-1 {
    gap: 0.25rem !important;
  }
  .sm\:gap-2 {
    gap: 0.5rem !important;
  }
  .sm\:gap-3 {
    gap: 1rem !important;
  }
  .sm\:gap-4 {
    gap: 1.5rem !important;
  }
  .sm\:gap-5 {
    gap: 2rem !important;
  }
  .sm\:gap-6 {
    gap: 3rem !important;
  }
  .sm\:gap-7 {
    gap: 4rem !important;
  }
  .sm\:gap-8 {
    gap: 5rem !important;
  }
  .sm\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .sm\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .sm\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .sm\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .sm\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .sm\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .sm\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .sm\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .sm\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .sm\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .sm\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .sm\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .sm\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .sm\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .sm\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .sm\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .sm\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .sm\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:gap-0 {
    gap: 0rem !important;
  }
  .md\:gap-1 {
    gap: 0.25rem !important;
  }
  .md\:gap-2 {
    gap: 0.5rem !important;
  }
  .md\:gap-3 {
    gap: 1rem !important;
  }
  .md\:gap-4 {
    gap: 1.5rem !important;
  }
  .md\:gap-5 {
    gap: 2rem !important;
  }
  .md\:gap-6 {
    gap: 3rem !important;
  }
  .md\:gap-7 {
    gap: 4rem !important;
  }
  .md\:gap-8 {
    gap: 5rem !important;
  }
  .md\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .md\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .md\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .md\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .md\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .md\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .md\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .md\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .md\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .md\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .md\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .md\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .md\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .md\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .md\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .md\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .md\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .md\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:gap-0 {
    gap: 0rem !important;
  }
  .lg\:gap-1 {
    gap: 0.25rem !important;
  }
  .lg\:gap-2 {
    gap: 0.5rem !important;
  }
  .lg\:gap-3 {
    gap: 1rem !important;
  }
  .lg\:gap-4 {
    gap: 1.5rem !important;
  }
  .lg\:gap-5 {
    gap: 2rem !important;
  }
  .lg\:gap-6 {
    gap: 3rem !important;
  }
  .lg\:gap-7 {
    gap: 4rem !important;
  }
  .lg\:gap-8 {
    gap: 5rem !important;
  }
  .lg\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .lg\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .lg\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .lg\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .lg\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .lg\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .lg\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .lg\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .lg\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .lg\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .lg\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .lg\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .lg\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .lg\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .lg\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .lg\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .lg\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .lg\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:gap-0 {
    gap: 0rem !important;
  }
  .xl\:gap-1 {
    gap: 0.25rem !important;
  }
  .xl\:gap-2 {
    gap: 0.5rem !important;
  }
  .xl\:gap-3 {
    gap: 1rem !important;
  }
  .xl\:gap-4 {
    gap: 1.5rem !important;
  }
  .xl\:gap-5 {
    gap: 2rem !important;
  }
  .xl\:gap-6 {
    gap: 3rem !important;
  }
  .xl\:gap-7 {
    gap: 4rem !important;
  }
  .xl\:gap-8 {
    gap: 5rem !important;
  }
  .xl\:row-gap-0 {
    row-gap: 0rem !important;
  }
  .xl\:row-gap-1 {
    row-gap: 0.25rem !important;
  }
  .xl\:row-gap-2 {
    row-gap: 0.5rem !important;
  }
  .xl\:row-gap-3 {
    row-gap: 1rem !important;
  }
  .xl\:row-gap-4 {
    row-gap: 1.5rem !important;
  }
  .xl\:row-gap-5 {
    row-gap: 2rem !important;
  }
  .xl\:row-gap-6 {
    row-gap: 3rem !important;
  }
  .xl\:row-gap-7 {
    row-gap: 4rem !important;
  }
  .xl\:row-gap-8 {
    row-gap: 5rem !important;
  }
  .xl\:column-gap-0 {
    column-gap: 0rem !important;
  }
  .xl\:column-gap-1 {
    column-gap: 0.25rem !important;
  }
  .xl\:column-gap-2 {
    column-gap: 0.5rem !important;
  }
  .xl\:column-gap-3 {
    column-gap: 1rem !important;
  }
  .xl\:column-gap-4 {
    column-gap: 1.5rem !important;
  }
  .xl\:column-gap-5 {
    column-gap: 2rem !important;
  }
  .xl\:column-gap-6 {
    column-gap: 3rem !important;
  }
  .xl\:column-gap-7 {
    column-gap: 4rem !important;
  }
  .xl\:column-gap-8 {
    column-gap: 5rem !important;
  }
}
.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0rem !important;
  }
  .sm\:p-1 {
    padding: 0.25rem !important;
  }
  .sm\:p-2 {
    padding: 0.5rem !important;
  }
  .sm\:p-3 {
    padding: 1rem !important;
  }
  .sm\:p-4 {
    padding: 1.5rem !important;
  }
  .sm\:p-5 {
    padding: 2rem !important;
  }
  .sm\:p-6 {
    padding: 3rem !important;
  }
  .sm\:p-7 {
    padding: 4rem !important;
  }
  .sm\:p-8 {
    padding: 5rem !important;
  }
  .sm\:pt-0 {
    padding-top: 0rem !important;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .sm\:pt-3 {
    padding-top: 1rem !important;
  }
  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .sm\:pt-5 {
    padding-top: 2rem !important;
  }
  .sm\:pt-6 {
    padding-top: 3rem !important;
  }
  .sm\:pt-7 {
    padding-top: 4rem !important;
  }
  .sm\:pt-8 {
    padding-top: 5rem !important;
  }
  .sm\:pr-0 {
    padding-right: 0rem !important;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .sm\:pr-3 {
    padding-right: 1rem !important;
  }
  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .sm\:pr-5 {
    padding-right: 2rem !important;
  }
  .sm\:pr-6 {
    padding-right: 3rem !important;
  }
  .sm\:pr-7 {
    padding-right: 4rem !important;
  }
  .sm\:pr-8 {
    padding-right: 5rem !important;
  }
  .sm\:pl-0 {
    padding-left: 0rem !important;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .sm\:pl-3 {
    padding-left: 1rem !important;
  }
  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .sm\:pl-5 {
    padding-left: 2rem !important;
  }
  .sm\:pl-6 {
    padding-left: 3rem !important;
  }
  .sm\:pl-7 {
    padding-left: 4rem !important;
  }
  .sm\:pl-8 {
    padding-left: 5rem !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .sm\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sm\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:p-0 {
    padding: 0rem !important;
  }
  .md\:p-1 {
    padding: 0.25rem !important;
  }
  .md\:p-2 {
    padding: 0.5rem !important;
  }
  .md\:p-3 {
    padding: 1rem !important;
  }
  .md\:p-4 {
    padding: 1.5rem !important;
  }
  .md\:p-5 {
    padding: 2rem !important;
  }
  .md\:p-6 {
    padding: 3rem !important;
  }
  .md\:p-7 {
    padding: 4rem !important;
  }
  .md\:p-8 {
    padding: 5rem !important;
  }
  .md\:pt-0 {
    padding-top: 0rem !important;
  }
  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .md\:pt-3 {
    padding-top: 1rem !important;
  }
  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .md\:pt-5 {
    padding-top: 2rem !important;
  }
  .md\:pt-6 {
    padding-top: 3rem !important;
  }
  .md\:pt-7 {
    padding-top: 4rem !important;
  }
  .md\:pt-8 {
    padding-top: 5rem !important;
  }
  .md\:pr-0 {
    padding-right: 0rem !important;
  }
  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .md\:pr-3 {
    padding-right: 1rem !important;
  }
  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .md\:pr-5 {
    padding-right: 2rem !important;
  }
  .md\:pr-6 {
    padding-right: 3rem !important;
  }
  .md\:pr-7 {
    padding-right: 4rem !important;
  }
  .md\:pr-8 {
    padding-right: 5rem !important;
  }
  .md\:pl-0 {
    padding-left: 0rem !important;
  }
  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .md\:pl-3 {
    padding-left: 1rem !important;
  }
  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .md\:pl-5 {
    padding-left: 2rem !important;
  }
  .md\:pl-6 {
    padding-left: 3rem !important;
  }
  .md\:pl-7 {
    padding-left: 4rem !important;
  }
  .md\:pl-8 {
    padding-left: 5rem !important;
  }
  .md\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .md\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .md\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:p-0 {
    padding: 0rem !important;
  }
  .lg\:p-1 {
    padding: 0.25rem !important;
  }
  .lg\:p-2 {
    padding: 0.5rem !important;
  }
  .lg\:p-3 {
    padding: 1rem !important;
  }
  .lg\:p-4 {
    padding: 1.5rem !important;
  }
  .lg\:p-5 {
    padding: 2rem !important;
  }
  .lg\:p-6 {
    padding: 3rem !important;
  }
  .lg\:p-7 {
    padding: 4rem !important;
  }
  .lg\:p-8 {
    padding: 5rem !important;
  }
  .lg\:pt-0 {
    padding-top: 0rem !important;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .lg\:pt-3 {
    padding-top: 1rem !important;
  }
  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .lg\:pt-5 {
    padding-top: 2rem !important;
  }
  .lg\:pt-6 {
    padding-top: 3rem !important;
  }
  .lg\:pt-7 {
    padding-top: 4rem !important;
  }
  .lg\:pt-8 {
    padding-top: 5rem !important;
  }
  .lg\:pr-0 {
    padding-right: 0rem !important;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .lg\:pr-3 {
    padding-right: 1rem !important;
  }
  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .lg\:pr-5 {
    padding-right: 2rem !important;
  }
  .lg\:pr-6 {
    padding-right: 3rem !important;
  }
  .lg\:pr-7 {
    padding-right: 4rem !important;
  }
  .lg\:pr-8 {
    padding-right: 5rem !important;
  }
  .lg\:pl-0 {
    padding-left: 0rem !important;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .lg\:pl-3 {
    padding-left: 1rem !important;
  }
  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .lg\:pl-5 {
    padding-left: 2rem !important;
  }
  .lg\:pl-6 {
    padding-left: 3rem !important;
  }
  .lg\:pl-7 {
    padding-left: 4rem !important;
  }
  .lg\:pl-8 {
    padding-left: 5rem !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .lg\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .lg\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .lg\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:p-0 {
    padding: 0rem !important;
  }
  .xl\:p-1 {
    padding: 0.25rem !important;
  }
  .xl\:p-2 {
    padding: 0.5rem !important;
  }
  .xl\:p-3 {
    padding: 1rem !important;
  }
  .xl\:p-4 {
    padding: 1.5rem !important;
  }
  .xl\:p-5 {
    padding: 2rem !important;
  }
  .xl\:p-6 {
    padding: 3rem !important;
  }
  .xl\:p-7 {
    padding: 4rem !important;
  }
  .xl\:p-8 {
    padding: 5rem !important;
  }
  .xl\:pt-0 {
    padding-top: 0rem !important;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }
  .xl\:pt-3 {
    padding-top: 1rem !important;
  }
  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }
  .xl\:pt-5 {
    padding-top: 2rem !important;
  }
  .xl\:pt-6 {
    padding-top: 3rem !important;
  }
  .xl\:pt-7 {
    padding-top: 4rem !important;
  }
  .xl\:pt-8 {
    padding-top: 5rem !important;
  }
  .xl\:pr-0 {
    padding-right: 0rem !important;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }
  .xl\:pr-3 {
    padding-right: 1rem !important;
  }
  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }
  .xl\:pr-5 {
    padding-right: 2rem !important;
  }
  .xl\:pr-6 {
    padding-right: 3rem !important;
  }
  .xl\:pr-7 {
    padding-right: 4rem !important;
  }
  .xl\:pr-8 {
    padding-right: 5rem !important;
  }
  .xl\:pl-0 {
    padding-left: 0rem !important;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }
  .xl\:pl-3 {
    padding-left: 1rem !important;
  }
  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }
  .xl\:pl-5 {
    padding-left: 2rem !important;
  }
  .xl\:pl-6 {
    padding-left: 3rem !important;
  }
  .xl\:pl-7 {
    padding-left: 4rem !important;
  }
  .xl\:pl-8 {
    padding-left: 5rem !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0rem !important;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }
  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }
  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }
  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }
  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }
  .xl\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .xl\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0rem !important;
  }
  .sm\:m-1 {
    margin: 0.25rem !important;
  }
  .sm\:m-2 {
    margin: 0.5rem !important;
  }
  .sm\:m-3 {
    margin: 1rem !important;
  }
  .sm\:m-4 {
    margin: 1.5rem !important;
  }
  .sm\:m-5 {
    margin: 2rem !important;
  }
  .sm\:m-6 {
    margin: 3rem !important;
  }
  .sm\:m-7 {
    margin: 4rem !important;
  }
  .sm\:m-8 {
    margin: 5rem !important;
  }
  .sm\:-m-1 {
    margin: -0.25rem !important;
  }
  .sm\:-m-2 {
    margin: -0.5rem !important;
  }
  .sm\:-m-3 {
    margin: -1rem !important;
  }
  .sm\:-m-4 {
    margin: -1.5rem !important;
  }
  .sm\:-m-5 {
    margin: -2rem !important;
  }
  .sm\:-m-6 {
    margin: -3rem !important;
  }
  .sm\:-m-7 {
    margin: -4rem !important;
  }
  .sm\:-m-8 {
    margin: -5rem !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:mt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .sm\:mt-3 {
    margin-top: 1rem !important;
  }
  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .sm\:mt-5 {
    margin-top: 2rem !important;
  }
  .sm\:mt-6 {
    margin-top: 3rem !important;
  }
  .sm\:mt-7 {
    margin-top: 4rem !important;
  }
  .sm\:mt-8 {
    margin-top: 5rem !important;
  }
  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }
  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }
  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }
  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }
  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }
  .sm\:mt-auto {
    margin-top: auto !important;
  }
  .sm\:mr-0 {
    margin-right: 0rem !important;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .sm\:mr-3 {
    margin-right: 1rem !important;
  }
  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .sm\:mr-5 {
    margin-right: 2rem !important;
  }
  .sm\:mr-6 {
    margin-right: 3rem !important;
  }
  .sm\:mr-7 {
    margin-right: 4rem !important;
  }
  .sm\:mr-8 {
    margin-right: 5rem !important;
  }
  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }
  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }
  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }
  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }
  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }
  .sm\:mr-auto {
    margin-right: auto !important;
  }
  .sm\:ml-0 {
    margin-left: 0rem !important;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .sm\:ml-3 {
    margin-left: 1rem !important;
  }
  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .sm\:ml-5 {
    margin-left: 2rem !important;
  }
  .sm\:ml-6 {
    margin-left: 3rem !important;
  }
  .sm\:ml-7 {
    margin-left: 4rem !important;
  }
  .sm\:ml-8 {
    margin-left: 5rem !important;
  }
  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }
  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }
  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }
  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }
  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }
  .sm\:ml-auto {
    margin-left: auto !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .sm\:mb-auto {
    margin-bottom: auto !important;
  }
  .sm\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:m-0 {
    margin: 0rem !important;
  }
  .md\:m-1 {
    margin: 0.25rem !important;
  }
  .md\:m-2 {
    margin: 0.5rem !important;
  }
  .md\:m-3 {
    margin: 1rem !important;
  }
  .md\:m-4 {
    margin: 1.5rem !important;
  }
  .md\:m-5 {
    margin: 2rem !important;
  }
  .md\:m-6 {
    margin: 3rem !important;
  }
  .md\:m-7 {
    margin: 4rem !important;
  }
  .md\:m-8 {
    margin: 5rem !important;
  }
  .md\:-m-1 {
    margin: -0.25rem !important;
  }
  .md\:-m-2 {
    margin: -0.5rem !important;
  }
  .md\:-m-3 {
    margin: -1rem !important;
  }
  .md\:-m-4 {
    margin: -1.5rem !important;
  }
  .md\:-m-5 {
    margin: -2rem !important;
  }
  .md\:-m-6 {
    margin: -3rem !important;
  }
  .md\:-m-7 {
    margin: -4rem !important;
  }
  .md\:-m-8 {
    margin: -5rem !important;
  }
  .md\:m-auto {
    margin: auto !important;
  }
  .md\:mt-0 {
    margin-top: 0rem !important;
  }
  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .md\:mt-3 {
    margin-top: 1rem !important;
  }
  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .md\:mt-5 {
    margin-top: 2rem !important;
  }
  .md\:mt-6 {
    margin-top: 3rem !important;
  }
  .md\:mt-7 {
    margin-top: 4rem !important;
  }
  .md\:mt-8 {
    margin-top: 5rem !important;
  }
  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .md\:-mt-3 {
    margin-top: -1rem !important;
  }
  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .md\:-mt-5 {
    margin-top: -2rem !important;
  }
  .md\:-mt-6 {
    margin-top: -3rem !important;
  }
  .md\:-mt-7 {
    margin-top: -4rem !important;
  }
  .md\:-mt-8 {
    margin-top: -5rem !important;
  }
  .md\:mt-auto {
    margin-top: auto !important;
  }
  .md\:mr-0 {
    margin-right: 0rem !important;
  }
  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .md\:mr-3 {
    margin-right: 1rem !important;
  }
  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .md\:mr-5 {
    margin-right: 2rem !important;
  }
  .md\:mr-6 {
    margin-right: 3rem !important;
  }
  .md\:mr-7 {
    margin-right: 4rem !important;
  }
  .md\:mr-8 {
    margin-right: 5rem !important;
  }
  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .md\:-mr-3 {
    margin-right: -1rem !important;
  }
  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .md\:-mr-5 {
    margin-right: -2rem !important;
  }
  .md\:-mr-6 {
    margin-right: -3rem !important;
  }
  .md\:-mr-7 {
    margin-right: -4rem !important;
  }
  .md\:-mr-8 {
    margin-right: -5rem !important;
  }
  .md\:mr-auto {
    margin-right: auto !important;
  }
  .md\:ml-0 {
    margin-left: 0rem !important;
  }
  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .md\:ml-3 {
    margin-left: 1rem !important;
  }
  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .md\:ml-5 {
    margin-left: 2rem !important;
  }
  .md\:ml-6 {
    margin-left: 3rem !important;
  }
  .md\:ml-7 {
    margin-left: 4rem !important;
  }
  .md\:ml-8 {
    margin-left: 5rem !important;
  }
  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .md\:-ml-3 {
    margin-left: -1rem !important;
  }
  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .md\:-ml-5 {
    margin-left: -2rem !important;
  }
  .md\:-ml-6 {
    margin-left: -3rem !important;
  }
  .md\:-ml-7 {
    margin-left: -4rem !important;
  }
  .md\:-ml-8 {
    margin-left: -5rem !important;
  }
  .md\:ml-auto {
    margin-left: auto !important;
  }
  .md\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .md\:mb-auto {
    margin-bottom: auto !important;
  }
  .md\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:m-0 {
    margin: 0rem !important;
  }
  .lg\:m-1 {
    margin: 0.25rem !important;
  }
  .lg\:m-2 {
    margin: 0.5rem !important;
  }
  .lg\:m-3 {
    margin: 1rem !important;
  }
  .lg\:m-4 {
    margin: 1.5rem !important;
  }
  .lg\:m-5 {
    margin: 2rem !important;
  }
  .lg\:m-6 {
    margin: 3rem !important;
  }
  .lg\:m-7 {
    margin: 4rem !important;
  }
  .lg\:m-8 {
    margin: 5rem !important;
  }
  .lg\:-m-1 {
    margin: -0.25rem !important;
  }
  .lg\:-m-2 {
    margin: -0.5rem !important;
  }
  .lg\:-m-3 {
    margin: -1rem !important;
  }
  .lg\:-m-4 {
    margin: -1.5rem !important;
  }
  .lg\:-m-5 {
    margin: -2rem !important;
  }
  .lg\:-m-6 {
    margin: -3rem !important;
  }
  .lg\:-m-7 {
    margin: -4rem !important;
  }
  .lg\:-m-8 {
    margin: -5rem !important;
  }
  .lg\:m-auto {
    margin: auto !important;
  }
  .lg\:mt-0 {
    margin-top: 0rem !important;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .lg\:mt-3 {
    margin-top: 1rem !important;
  }
  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .lg\:mt-5 {
    margin-top: 2rem !important;
  }
  .lg\:mt-6 {
    margin-top: 3rem !important;
  }
  .lg\:mt-7 {
    margin-top: 4rem !important;
  }
  .lg\:mt-8 {
    margin-top: 5rem !important;
  }
  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }
  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }
  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }
  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }
  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }
  .lg\:mt-auto {
    margin-top: auto !important;
  }
  .lg\:mr-0 {
    margin-right: 0rem !important;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .lg\:mr-3 {
    margin-right: 1rem !important;
  }
  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .lg\:mr-5 {
    margin-right: 2rem !important;
  }
  .lg\:mr-6 {
    margin-right: 3rem !important;
  }
  .lg\:mr-7 {
    margin-right: 4rem !important;
  }
  .lg\:mr-8 {
    margin-right: 5rem !important;
  }
  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }
  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }
  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }
  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }
  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }
  .lg\:mr-auto {
    margin-right: auto !important;
  }
  .lg\:ml-0 {
    margin-left: 0rem !important;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .lg\:ml-3 {
    margin-left: 1rem !important;
  }
  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .lg\:ml-5 {
    margin-left: 2rem !important;
  }
  .lg\:ml-6 {
    margin-left: 3rem !important;
  }
  .lg\:ml-7 {
    margin-left: 4rem !important;
  }
  .lg\:ml-8 {
    margin-left: 5rem !important;
  }
  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }
  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }
  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }
  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }
  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }
  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .lg\:mb-auto {
    margin-bottom: auto !important;
  }
  .lg\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .lg\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0rem !important;
  }
  .xl\:m-1 {
    margin: 0.25rem !important;
  }
  .xl\:m-2 {
    margin: 0.5rem !important;
  }
  .xl\:m-3 {
    margin: 1rem !important;
  }
  .xl\:m-4 {
    margin: 1.5rem !important;
  }
  .xl\:m-5 {
    margin: 2rem !important;
  }
  .xl\:m-6 {
    margin: 3rem !important;
  }
  .xl\:m-7 {
    margin: 4rem !important;
  }
  .xl\:m-8 {
    margin: 5rem !important;
  }
  .xl\:-m-1 {
    margin: -0.25rem !important;
  }
  .xl\:-m-2 {
    margin: -0.5rem !important;
  }
  .xl\:-m-3 {
    margin: -1rem !important;
  }
  .xl\:-m-4 {
    margin: -1.5rem !important;
  }
  .xl\:-m-5 {
    margin: -2rem !important;
  }
  .xl\:-m-6 {
    margin: -3rem !important;
  }
  .xl\:-m-7 {
    margin: -4rem !important;
  }
  .xl\:-m-8 {
    margin: -5rem !important;
  }
  .xl\:m-auto {
    margin: auto !important;
  }
  .xl\:mt-0 {
    margin-top: 0rem !important;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }
  .xl\:mt-3 {
    margin-top: 1rem !important;
  }
  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }
  .xl\:mt-5 {
    margin-top: 2rem !important;
  }
  .xl\:mt-6 {
    margin-top: 3rem !important;
  }
  .xl\:mt-7 {
    margin-top: 4rem !important;
  }
  .xl\:mt-8 {
    margin-top: 5rem !important;
  }
  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }
  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }
  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }
  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }
  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }
  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }
  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }
  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }
  .xl\:mt-auto {
    margin-top: auto !important;
  }
  .xl\:mr-0 {
    margin-right: 0rem !important;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }
  .xl\:mr-3 {
    margin-right: 1rem !important;
  }
  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }
  .xl\:mr-5 {
    margin-right: 2rem !important;
  }
  .xl\:mr-6 {
    margin-right: 3rem !important;
  }
  .xl\:mr-7 {
    margin-right: 4rem !important;
  }
  .xl\:mr-8 {
    margin-right: 5rem !important;
  }
  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }
  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }
  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }
  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }
  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }
  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }
  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }
  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }
  .xl\:mr-auto {
    margin-right: auto !important;
  }
  .xl\:ml-0 {
    margin-left: 0rem !important;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }
  .xl\:ml-3 {
    margin-left: 1rem !important;
  }
  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }
  .xl\:ml-5 {
    margin-left: 2rem !important;
  }
  .xl\:ml-6 {
    margin-left: 3rem !important;
  }
  .xl\:ml-7 {
    margin-left: 4rem !important;
  }
  .xl\:ml-8 {
    margin-left: 5rem !important;
  }
  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }
  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }
  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }
  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }
  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }
  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }
  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }
  .xl\:ml-auto {
    margin-left: auto !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0rem !important;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }
  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }
  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }
  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }
  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }
  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }
  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }
  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }
  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }
  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }
  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }
  .xl\:mb-auto {
    margin-bottom: auto !important;
  }
  .xl\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xl\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.shadow-2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-3 {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-5 {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.shadow-7 {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.shadow-8 {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-none:focus {
  box-shadow: none !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

.active\:shadow-none:active {
  box-shadow: none !important;
}

.focus\:shadow-1:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.hover\:shadow-1:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.active\:shadow-1:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.focus\:shadow-2:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.hover\:shadow-2:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.active\:shadow-2:active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.focus\:shadow-3:focus {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-3:hover {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.active\:shadow-3:active {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.focus\:shadow-4:focus {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-4:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-4:active {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-5:focus {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-5:hover {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-5:active {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-6:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.hover\:shadow-6:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.active\:shadow-6:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.focus\:shadow-7:focus {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.hover\:shadow-7:hover {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.active\:shadow-7:active {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.focus\:shadow-8:focus {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-8:hover {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-8:active {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }
  .sm\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .sm\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .sm\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }
  .md\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .md\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .md\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }
  .lg\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .lg\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .lg\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }
  .xl\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .xl\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .xl\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
.border-none {
  border-width: 0px !important;
  border-style: none;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-top-none {
  border-top-width: 0px !important;
  border-top-style: none;
}

.border-top-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-top-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-top-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-right-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-right-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
}

.border-left-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-left-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-x-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-y-none {
  border-top-width: 0px !important;
  border-top-style: none;
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .sm\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .sm\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .sm\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .sm\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .sm\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .sm\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .sm\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .sm\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .sm\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .sm\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .sm\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .sm\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .sm\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .md\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .md\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .md\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .md\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .md\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .md\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .md\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .md\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .md\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .md\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .md\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .lg\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .lg\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .lg\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .lg\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .lg\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .lg\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .lg\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .lg\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .lg\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .lg\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .lg\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .lg\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .lg\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }
  .sm\:border-dashed {
    border-style: dashed !important;
  }
  .sm\:border-dotted {
    border-style: dotted !important;
  }
  .sm\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }
  .md\:border-dashed {
    border-style: dashed !important;
  }
  .md\:border-dotted {
    border-style: dotted !important;
  }
  .md\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }
  .lg\:border-dashed {
    border-style: dashed !important;
  }
  .lg\:border-dotted {
    border-style: dotted !important;
  }
  .lg\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }
  .xl\:border-dashed {
    border-style: dashed !important;
  }
  .xl\:border-dotted {
    border-style: dotted !important;
  }
  .xl\:border-double {
    border-style: double !important;
  }
}
.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: 0.125rem !important;
}

.border-round-sm {
  border-radius: 0.25rem !important;
}

.border-round-md {
  border-radius: 0.375rem !important;
}

.border-round-lg {
  border-radius: 0.5rem !important;
}

.border-round-xl {
  border-radius: 0.75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }
  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .sm\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .sm\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .sm\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .sm\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .sm\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .sm\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }
  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .md\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .md\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .md\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .md\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .md\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .md\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }
  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .lg\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .lg\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .lg\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .lg\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .lg\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .lg\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }
  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xl\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xl\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xl\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xl\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xl\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xl\:border-circle {
    border-radius: 50% !important;
  }
}
.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-bottom-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-bottom-sm {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-left-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.border-round-top-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.border-round-right-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-bottom-md {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-bottom-xl {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .sm\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .sm\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .sm\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .sm\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .sm\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .sm\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .sm\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .sm\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .sm\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .sm\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .sm\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .md\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .md\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .md\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .md\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .md\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .md\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .md\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .md\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .md\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .md\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .md\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .lg\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .lg\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .lg\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .lg\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .lg\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .lg\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .lg\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .lg\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .lg\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .lg\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .lg\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xl\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xl\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xl\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xl\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xl\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xl\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xl\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xl\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xl\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xl\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xl\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }
  .sm\:w-screen {
    width: 100vw !important;
  }
  .sm\:w-auto {
    width: auto !important;
  }
  .sm\:w-1 {
    width: 8.3333% !important;
  }
  .sm\:w-2 {
    width: 16.6667% !important;
  }
  .sm\:w-3 {
    width: 25% !important;
  }
  .sm\:w-4 {
    width: 33.3333% !important;
  }
  .sm\:w-5 {
    width: 41.6667% !important;
  }
  .sm\:w-6 {
    width: 50% !important;
  }
  .sm\:w-7 {
    width: 58.3333% !important;
  }
  .sm\:w-8 {
    width: 66.6667% !important;
  }
  .sm\:w-9 {
    width: 75% !important;
  }
  .sm\:w-10 {
    width: 83.3333% !important;
  }
  .sm\:w-11 {
    width: 91.6667% !important;
  }
  .sm\:w-12 {
    width: 100% !important;
  }
  .sm\:w-min {
    width: min-content !important;
  }
  .sm\:w-max {
    width: max-content !important;
  }
  .sm\:w-fit {
    width: fit-content !important;
  }
  .sm\:w-1rem {
    width: 1rem !important;
  }
  .sm\:w-2rem {
    width: 2rem !important;
  }
  .sm\:w-3rem {
    width: 3rem !important;
  }
  .sm\:w-4rem {
    width: 4rem !important;
  }
  .sm\:w-5rem {
    width: 5rem !important;
  }
  .sm\:w-6rem {
    width: 6rem !important;
  }
  .sm\:w-7rem {
    width: 7rem !important;
  }
  .sm\:w-8rem {
    width: 8rem !important;
  }
  .sm\:w-9rem {
    width: 9rem !important;
  }
  .sm\:w-10rem {
    width: 10rem !important;
  }
  .sm\:w-11rem {
    width: 11rem !important;
  }
  .sm\:w-12rem {
    width: 12rem !important;
  }
  .sm\:w-13rem {
    width: 13rem !important;
  }
  .sm\:w-14rem {
    width: 14rem !important;
  }
  .sm\:w-15rem {
    width: 15rem !important;
  }
  .sm\:w-16rem {
    width: 16rem !important;
  }
  .sm\:w-17rem {
    width: 17rem !important;
  }
  .sm\:w-18rem {
    width: 18rem !important;
  }
  .sm\:w-19rem {
    width: 19rem !important;
  }
  .sm\:w-20rem {
    width: 20rem !important;
  }
  .sm\:w-21rem {
    width: 21rem !important;
  }
  .sm\:w-22rem {
    width: 22rem !important;
  }
  .sm\:w-23rem {
    width: 23rem !important;
  }
  .sm\:w-24rem {
    width: 24rem !important;
  }
  .sm\:w-25rem {
    width: 25rem !important;
  }
  .sm\:w-26rem {
    width: 26rem !important;
  }
  .sm\:w-27rem {
    width: 27rem !important;
  }
  .sm\:w-28rem {
    width: 28rem !important;
  }
  .sm\:w-29rem {
    width: 29rem !important;
  }
  .sm\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-screen {
    width: 100vw !important;
  }
  .md\:w-auto {
    width: auto !important;
  }
  .md\:w-1 {
    width: 8.3333% !important;
  }
  .md\:w-2 {
    width: 16.6667% !important;
  }
  .md\:w-3 {
    width: 25% !important;
  }
  .md\:w-4 {
    width: 33.3333% !important;
  }
  .md\:w-5 {
    width: 41.6667% !important;
  }
  .md\:w-6 {
    width: 50% !important;
  }
  .md\:w-7 {
    width: 58.3333% !important;
  }
  .md\:w-8 {
    width: 66.6667% !important;
  }
  .md\:w-9 {
    width: 75% !important;
  }
  .md\:w-10 {
    width: 83.3333% !important;
  }
  .md\:w-11 {
    width: 91.6667% !important;
  }
  .md\:w-12 {
    width: 100% !important;
  }
  .md\:w-min {
    width: min-content !important;
  }
  .md\:w-max {
    width: max-content !important;
  }
  .md\:w-fit {
    width: fit-content !important;
  }
  .md\:w-1rem {
    width: 1rem !important;
  }
  .md\:w-2rem {
    width: 2rem !important;
  }
  .md\:w-3rem {
    width: 3rem !important;
  }
  .md\:w-4rem {
    width: 4rem !important;
  }
  .md\:w-5rem {
    width: 5rem !important;
  }
  .md\:w-6rem {
    width: 6rem !important;
  }
  .md\:w-7rem {
    width: 7rem !important;
  }
  .md\:w-8rem {
    width: 8rem !important;
  }
  .md\:w-9rem {
    width: 9rem !important;
  }
  .md\:w-10rem {
    width: 10rem !important;
  }
  .md\:w-11rem {
    width: 11rem !important;
  }
  .md\:w-12rem {
    width: 12rem !important;
  }
  .md\:w-13rem {
    width: 13rem !important;
  }
  .md\:w-14rem {
    width: 14rem !important;
  }
  .md\:w-15rem {
    width: 15rem !important;
  }
  .md\:w-16rem {
    width: 16rem !important;
  }
  .md\:w-17rem {
    width: 17rem !important;
  }
  .md\:w-18rem {
    width: 18rem !important;
  }
  .md\:w-19rem {
    width: 19rem !important;
  }
  .md\:w-20rem {
    width: 20rem !important;
  }
  .md\:w-21rem {
    width: 21rem !important;
  }
  .md\:w-22rem {
    width: 22rem !important;
  }
  .md\:w-23rem {
    width: 23rem !important;
  }
  .md\:w-24rem {
    width: 24rem !important;
  }
  .md\:w-25rem {
    width: 25rem !important;
  }
  .md\:w-26rem {
    width: 26rem !important;
  }
  .md\:w-27rem {
    width: 27rem !important;
  }
  .md\:w-28rem {
    width: 28rem !important;
  }
  .md\:w-29rem {
    width: 29rem !important;
  }
  .md\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:w-screen {
    width: 100vw !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-1 {
    width: 8.3333% !important;
  }
  .lg\:w-2 {
    width: 16.6667% !important;
  }
  .lg\:w-3 {
    width: 25% !important;
  }
  .lg\:w-4 {
    width: 33.3333% !important;
  }
  .lg\:w-5 {
    width: 41.6667% !important;
  }
  .lg\:w-6 {
    width: 50% !important;
  }
  .lg\:w-7 {
    width: 58.3333% !important;
  }
  .lg\:w-8 {
    width: 66.6667% !important;
  }
  .lg\:w-9 {
    width: 75% !important;
  }
  .lg\:w-10 {
    width: 83.3333% !important;
  }
  .lg\:w-11 {
    width: 91.6667% !important;
  }
  .lg\:w-12 {
    width: 100% !important;
  }
  .lg\:w-min {
    width: min-content !important;
  }
  .lg\:w-max {
    width: max-content !important;
  }
  .lg\:w-fit {
    width: fit-content !important;
  }
  .lg\:w-1rem {
    width: 1rem !important;
  }
  .lg\:w-2rem {
    width: 2rem !important;
  }
  .lg\:w-3rem {
    width: 3rem !important;
  }
  .lg\:w-4rem {
    width: 4rem !important;
  }
  .lg\:w-5rem {
    width: 5rem !important;
  }
  .lg\:w-6rem {
    width: 6rem !important;
  }
  .lg\:w-7rem {
    width: 7rem !important;
  }
  .lg\:w-8rem {
    width: 8rem !important;
  }
  .lg\:w-9rem {
    width: 9rem !important;
  }
  .lg\:w-10rem {
    width: 10rem !important;
  }
  .lg\:w-11rem {
    width: 11rem !important;
  }
  .lg\:w-12rem {
    width: 12rem !important;
  }
  .lg\:w-13rem {
    width: 13rem !important;
  }
  .lg\:w-14rem {
    width: 14rem !important;
  }
  .lg\:w-15rem {
    width: 15rem !important;
  }
  .lg\:w-16rem {
    width: 16rem !important;
  }
  .lg\:w-17rem {
    width: 17rem !important;
  }
  .lg\:w-18rem {
    width: 18rem !important;
  }
  .lg\:w-19rem {
    width: 19rem !important;
  }
  .lg\:w-20rem {
    width: 20rem !important;
  }
  .lg\:w-21rem {
    width: 21rem !important;
  }
  .lg\:w-22rem {
    width: 22rem !important;
  }
  .lg\:w-23rem {
    width: 23rem !important;
  }
  .lg\:w-24rem {
    width: 24rem !important;
  }
  .lg\:w-25rem {
    width: 25rem !important;
  }
  .lg\:w-26rem {
    width: 26rem !important;
  }
  .lg\:w-27rem {
    width: 27rem !important;
  }
  .lg\:w-28rem {
    width: 28rem !important;
  }
  .lg\:w-29rem {
    width: 29rem !important;
  }
  .lg\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }
  .xl\:w-screen {
    width: 100vw !important;
  }
  .xl\:w-auto {
    width: auto !important;
  }
  .xl\:w-1 {
    width: 8.3333% !important;
  }
  .xl\:w-2 {
    width: 16.6667% !important;
  }
  .xl\:w-3 {
    width: 25% !important;
  }
  .xl\:w-4 {
    width: 33.3333% !important;
  }
  .xl\:w-5 {
    width: 41.6667% !important;
  }
  .xl\:w-6 {
    width: 50% !important;
  }
  .xl\:w-7 {
    width: 58.3333% !important;
  }
  .xl\:w-8 {
    width: 66.6667% !important;
  }
  .xl\:w-9 {
    width: 75% !important;
  }
  .xl\:w-10 {
    width: 83.3333% !important;
  }
  .xl\:w-11 {
    width: 91.6667% !important;
  }
  .xl\:w-12 {
    width: 100% !important;
  }
  .xl\:w-min {
    width: min-content !important;
  }
  .xl\:w-max {
    width: max-content !important;
  }
  .xl\:w-fit {
    width: fit-content !important;
  }
  .xl\:w-1rem {
    width: 1rem !important;
  }
  .xl\:w-2rem {
    width: 2rem !important;
  }
  .xl\:w-3rem {
    width: 3rem !important;
  }
  .xl\:w-4rem {
    width: 4rem !important;
  }
  .xl\:w-5rem {
    width: 5rem !important;
  }
  .xl\:w-6rem {
    width: 6rem !important;
  }
  .xl\:w-7rem {
    width: 7rem !important;
  }
  .xl\:w-8rem {
    width: 8rem !important;
  }
  .xl\:w-9rem {
    width: 9rem !important;
  }
  .xl\:w-10rem {
    width: 10rem !important;
  }
  .xl\:w-11rem {
    width: 11rem !important;
  }
  .xl\:w-12rem {
    width: 12rem !important;
  }
  .xl\:w-13rem {
    width: 13rem !important;
  }
  .xl\:w-14rem {
    width: 14rem !important;
  }
  .xl\:w-15rem {
    width: 15rem !important;
  }
  .xl\:w-16rem {
    width: 16rem !important;
  }
  .xl\:w-17rem {
    width: 17rem !important;
  }
  .xl\:w-18rem {
    width: 18rem !important;
  }
  .xl\:w-19rem {
    width: 19rem !important;
  }
  .xl\:w-20rem {
    width: 20rem !important;
  }
  .xl\:w-21rem {
    width: 21rem !important;
  }
  .xl\:w-22rem {
    width: 22rem !important;
  }
  .xl\:w-23rem {
    width: 23rem !important;
  }
  .xl\:w-24rem {
    width: 24rem !important;
  }
  .xl\:w-25rem {
    width: 25rem !important;
  }
  .xl\:w-26rem {
    width: 26rem !important;
  }
  .xl\:w-27rem {
    width: 27rem !important;
  }
  .xl\:w-28rem {
    width: 28rem !important;
  }
  .xl\:w-29rem {
    width: 29rem !important;
  }
  .xl\:w-30rem {
    width: 30rem !important;
  }
}
.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }
  .sm\:h-screen {
    height: 100vh !important;
  }
  .sm\:h-auto {
    height: auto !important;
  }
  .sm\:h-min {
    height: min-content !important;
  }
  .sm\:h-max {
    height: max-content !important;
  }
  .sm\:h-fit {
    height: fit-content !important;
  }
  .sm\:h-1rem {
    height: 1rem !important;
  }
  .sm\:h-2rem {
    height: 2rem !important;
  }
  .sm\:h-3rem {
    height: 3rem !important;
  }
  .sm\:h-4rem {
    height: 4rem !important;
  }
  .sm\:h-5rem {
    height: 5rem !important;
  }
  .sm\:h-6rem {
    height: 6rem !important;
  }
  .sm\:h-7rem {
    height: 7rem !important;
  }
  .sm\:h-8rem {
    height: 8rem !important;
  }
  .sm\:h-9rem {
    height: 9rem !important;
  }
  .sm\:h-10rem {
    height: 10rem !important;
  }
  .sm\:h-11rem {
    height: 11rem !important;
  }
  .sm\:h-12rem {
    height: 12rem !important;
  }
  .sm\:h-13rem {
    height: 13rem !important;
  }
  .sm\:h-14rem {
    height: 14rem !important;
  }
  .sm\:h-15rem {
    height: 15rem !important;
  }
  .sm\:h-16rem {
    height: 16rem !important;
  }
  .sm\:h-17rem {
    height: 17rem !important;
  }
  .sm\:h-18rem {
    height: 18rem !important;
  }
  .sm\:h-19rem {
    height: 19rem !important;
  }
  .sm\:h-20rem {
    height: 20rem !important;
  }
  .sm\:h-21rem {
    height: 21rem !important;
  }
  .sm\:h-22rem {
    height: 22rem !important;
  }
  .sm\:h-23rem {
    height: 23rem !important;
  }
  .sm\:h-24rem {
    height: 24rem !important;
  }
  .sm\:h-25rem {
    height: 25rem !important;
  }
  .sm\:h-26rem {
    height: 26rem !important;
  }
  .sm\:h-27rem {
    height: 27rem !important;
  }
  .sm\:h-28rem {
    height: 28rem !important;
  }
  .sm\:h-29rem {
    height: 29rem !important;
  }
  .sm\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:h-full {
    height: 100% !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:h-min {
    height: min-content !important;
  }
  .md\:h-max {
    height: max-content !important;
  }
  .md\:h-fit {
    height: fit-content !important;
  }
  .md\:h-1rem {
    height: 1rem !important;
  }
  .md\:h-2rem {
    height: 2rem !important;
  }
  .md\:h-3rem {
    height: 3rem !important;
  }
  .md\:h-4rem {
    height: 4rem !important;
  }
  .md\:h-5rem {
    height: 5rem !important;
  }
  .md\:h-6rem {
    height: 6rem !important;
  }
  .md\:h-7rem {
    height: 7rem !important;
  }
  .md\:h-8rem {
    height: 8rem !important;
  }
  .md\:h-9rem {
    height: 9rem !important;
  }
  .md\:h-10rem {
    height: 10rem !important;
  }
  .md\:h-11rem {
    height: 11rem !important;
  }
  .md\:h-12rem {
    height: 12rem !important;
  }
  .md\:h-13rem {
    height: 13rem !important;
  }
  .md\:h-14rem {
    height: 14rem !important;
  }
  .md\:h-15rem {
    height: 15rem !important;
  }
  .md\:h-16rem {
    height: 16rem !important;
  }
  .md\:h-17rem {
    height: 17rem !important;
  }
  .md\:h-18rem {
    height: 18rem !important;
  }
  .md\:h-19rem {
    height: 19rem !important;
  }
  .md\:h-20rem {
    height: 20rem !important;
  }
  .md\:h-21rem {
    height: 21rem !important;
  }
  .md\:h-22rem {
    height: 22rem !important;
  }
  .md\:h-23rem {
    height: 23rem !important;
  }
  .md\:h-24rem {
    height: 24rem !important;
  }
  .md\:h-25rem {
    height: 25rem !important;
  }
  .md\:h-26rem {
    height: 26rem !important;
  }
  .md\:h-27rem {
    height: 27rem !important;
  }
  .md\:h-28rem {
    height: 28rem !important;
  }
  .md\:h-29rem {
    height: 29rem !important;
  }
  .md\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:h-screen {
    height: 100vh !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-min {
    height: min-content !important;
  }
  .lg\:h-max {
    height: max-content !important;
  }
  .lg\:h-fit {
    height: fit-content !important;
  }
  .lg\:h-1rem {
    height: 1rem !important;
  }
  .lg\:h-2rem {
    height: 2rem !important;
  }
  .lg\:h-3rem {
    height: 3rem !important;
  }
  .lg\:h-4rem {
    height: 4rem !important;
  }
  .lg\:h-5rem {
    height: 5rem !important;
  }
  .lg\:h-6rem {
    height: 6rem !important;
  }
  .lg\:h-7rem {
    height: 7rem !important;
  }
  .lg\:h-8rem {
    height: 8rem !important;
  }
  .lg\:h-9rem {
    height: 9rem !important;
  }
  .lg\:h-10rem {
    height: 10rem !important;
  }
  .lg\:h-11rem {
    height: 11rem !important;
  }
  .lg\:h-12rem {
    height: 12rem !important;
  }
  .lg\:h-13rem {
    height: 13rem !important;
  }
  .lg\:h-14rem {
    height: 14rem !important;
  }
  .lg\:h-15rem {
    height: 15rem !important;
  }
  .lg\:h-16rem {
    height: 16rem !important;
  }
  .lg\:h-17rem {
    height: 17rem !important;
  }
  .lg\:h-18rem {
    height: 18rem !important;
  }
  .lg\:h-19rem {
    height: 19rem !important;
  }
  .lg\:h-20rem {
    height: 20rem !important;
  }
  .lg\:h-21rem {
    height: 21rem !important;
  }
  .lg\:h-22rem {
    height: 22rem !important;
  }
  .lg\:h-23rem {
    height: 23rem !important;
  }
  .lg\:h-24rem {
    height: 24rem !important;
  }
  .lg\:h-25rem {
    height: 25rem !important;
  }
  .lg\:h-26rem {
    height: 26rem !important;
  }
  .lg\:h-27rem {
    height: 27rem !important;
  }
  .lg\:h-28rem {
    height: 28rem !important;
  }
  .lg\:h-29rem {
    height: 29rem !important;
  }
  .lg\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }
  .xl\:h-screen {
    height: 100vh !important;
  }
  .xl\:h-auto {
    height: auto !important;
  }
  .xl\:h-min {
    height: min-content !important;
  }
  .xl\:h-max {
    height: max-content !important;
  }
  .xl\:h-fit {
    height: fit-content !important;
  }
  .xl\:h-1rem {
    height: 1rem !important;
  }
  .xl\:h-2rem {
    height: 2rem !important;
  }
  .xl\:h-3rem {
    height: 3rem !important;
  }
  .xl\:h-4rem {
    height: 4rem !important;
  }
  .xl\:h-5rem {
    height: 5rem !important;
  }
  .xl\:h-6rem {
    height: 6rem !important;
  }
  .xl\:h-7rem {
    height: 7rem !important;
  }
  .xl\:h-8rem {
    height: 8rem !important;
  }
  .xl\:h-9rem {
    height: 9rem !important;
  }
  .xl\:h-10rem {
    height: 10rem !important;
  }
  .xl\:h-11rem {
    height: 11rem !important;
  }
  .xl\:h-12rem {
    height: 12rem !important;
  }
  .xl\:h-13rem {
    height: 13rem !important;
  }
  .xl\:h-14rem {
    height: 14rem !important;
  }
  .xl\:h-15rem {
    height: 15rem !important;
  }
  .xl\:h-16rem {
    height: 16rem !important;
  }
  .xl\:h-17rem {
    height: 17rem !important;
  }
  .xl\:h-18rem {
    height: 18rem !important;
  }
  .xl\:h-19rem {
    height: 19rem !important;
  }
  .xl\:h-20rem {
    height: 20rem !important;
  }
  .xl\:h-21rem {
    height: 21rem !important;
  }
  .xl\:h-22rem {
    height: 22rem !important;
  }
  .xl\:h-23rem {
    height: 23rem !important;
  }
  .xl\:h-24rem {
    height: 24rem !important;
  }
  .xl\:h-25rem {
    height: 25rem !important;
  }
  .xl\:h-26rem {
    height: 26rem !important;
  }
  .xl\:h-27rem {
    height: 27rem !important;
  }
  .xl\:h-28rem {
    height: 28rem !important;
  }
  .xl\:h-29rem {
    height: 29rem !important;
  }
  .xl\:h-30rem {
    height: 30rem !important;
  }
}
.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0px !important;
  }
  .sm\:min-w-full {
    min-width: 100% !important;
  }
  .sm\:min-w-screen {
    min-width: 100vw !important;
  }
  .sm\:min-w-min {
    min-width: min-content !important;
  }
  .sm\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0px !important;
  }
  .md\:min-w-full {
    min-width: 100% !important;
  }
  .md\:min-w-screen {
    min-width: 100vw !important;
  }
  .md\:min-w-min {
    min-width: min-content !important;
  }
  .md\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-w-0 {
    min-width: 0px !important;
  }
  .lg\:min-w-full {
    min-width: 100% !important;
  }
  .lg\:min-w-screen {
    min-width: 100vw !important;
  }
  .lg\:min-w-min {
    min-width: min-content !important;
  }
  .lg\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-w-0 {
    min-width: 0px !important;
  }
  .xl\:min-w-full {
    min-width: 100% !important;
  }
  .xl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xl\:min-w-min {
    min-width: min-content !important;
  }
  .xl\:min-w-max {
    min-width: max-content !important;
  }
}
.max-w-0 {
  max-width: 0px !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0px !important;
  }
  .sm\:max-w-full {
    max-width: 100% !important;
  }
  .sm\:max-w-screen {
    max-width: 100vw !important;
  }
  .sm\:max-w-min {
    max-width: min-content !important;
  }
  .sm\:max-w-max {
    max-width: max-content !important;
  }
  .sm\:max-w-fit {
    max-width: fit-content !important;
  }
  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }
  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }
  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }
  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }
  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }
  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }
  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }
  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }
  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }
  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }
  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }
  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }
  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }
  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }
  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }
  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }
  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }
  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }
  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }
  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }
  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }
  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }
  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }
  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }
  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }
  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }
  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }
  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }
  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }
  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-w-0 {
    max-width: 0px !important;
  }
  .md\:max-w-full {
    max-width: 100% !important;
  }
  .md\:max-w-screen {
    max-width: 100vw !important;
  }
  .md\:max-w-min {
    max-width: min-content !important;
  }
  .md\:max-w-max {
    max-width: max-content !important;
  }
  .md\:max-w-fit {
    max-width: fit-content !important;
  }
  .md\:max-w-1rem {
    max-width: 1rem !important;
  }
  .md\:max-w-2rem {
    max-width: 2rem !important;
  }
  .md\:max-w-3rem {
    max-width: 3rem !important;
  }
  .md\:max-w-4rem {
    max-width: 4rem !important;
  }
  .md\:max-w-5rem {
    max-width: 5rem !important;
  }
  .md\:max-w-6rem {
    max-width: 6rem !important;
  }
  .md\:max-w-7rem {
    max-width: 7rem !important;
  }
  .md\:max-w-8rem {
    max-width: 8rem !important;
  }
  .md\:max-w-9rem {
    max-width: 9rem !important;
  }
  .md\:max-w-10rem {
    max-width: 10rem !important;
  }
  .md\:max-w-11rem {
    max-width: 11rem !important;
  }
  .md\:max-w-12rem {
    max-width: 12rem !important;
  }
  .md\:max-w-13rem {
    max-width: 13rem !important;
  }
  .md\:max-w-14rem {
    max-width: 14rem !important;
  }
  .md\:max-w-15rem {
    max-width: 15rem !important;
  }
  .md\:max-w-16rem {
    max-width: 16rem !important;
  }
  .md\:max-w-17rem {
    max-width: 17rem !important;
  }
  .md\:max-w-18rem {
    max-width: 18rem !important;
  }
  .md\:max-w-19rem {
    max-width: 19rem !important;
  }
  .md\:max-w-20rem {
    max-width: 20rem !important;
  }
  .md\:max-w-21rem {
    max-width: 21rem !important;
  }
  .md\:max-w-22rem {
    max-width: 22rem !important;
  }
  .md\:max-w-23rem {
    max-width: 23rem !important;
  }
  .md\:max-w-24rem {
    max-width: 24rem !important;
  }
  .md\:max-w-25rem {
    max-width: 25rem !important;
  }
  .md\:max-w-26rem {
    max-width: 26rem !important;
  }
  .md\:max-w-27rem {
    max-width: 27rem !important;
  }
  .md\:max-w-28rem {
    max-width: 28rem !important;
  }
  .md\:max-w-29rem {
    max-width: 29rem !important;
  }
  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-w-0 {
    max-width: 0px !important;
  }
  .lg\:max-w-full {
    max-width: 100% !important;
  }
  .lg\:max-w-screen {
    max-width: 100vw !important;
  }
  .lg\:max-w-min {
    max-width: min-content !important;
  }
  .lg\:max-w-max {
    max-width: max-content !important;
  }
  .lg\:max-w-fit {
    max-width: fit-content !important;
  }
  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }
  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }
  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }
  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }
  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }
  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }
  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }
  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }
  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }
  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }
  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }
  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }
  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }
  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }
  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }
  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }
  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }
  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }
  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }
  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }
  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }
  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }
  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }
  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }
  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }
  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }
  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }
  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }
  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }
  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-w-0 {
    max-width: 0px !important;
  }
  .xl\:max-w-full {
    max-width: 100% !important;
  }
  .xl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xl\:max-w-min {
    max-width: min-content !important;
  }
  .xl\:max-w-max {
    max-width: max-content !important;
  }
  .xl\:max-w-fit {
    max-width: fit-content !important;
  }
  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}
.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0px !important;
  }
  .sm\:min-h-full {
    min-height: 100% !important;
  }
  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-h-0 {
    min-height: 0px !important;
  }
  .md\:min-h-full {
    min-height: 100% !important;
  }
  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-h-0 {
    min-height: 0px !important;
  }
  .lg\:min-h-full {
    min-height: 100% !important;
  }
  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-h-0 {
    min-height: 0px !important;
  }
  .xl\:min-h-full {
    min-height: 100% !important;
  }
  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}
.max-h-0 {
  max-height: 0px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: min-content !important;
}

.max-h-max {
  max-height: max-content !important;
}

.max-h-fit {
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0px !important;
  }
  .sm\:max-h-full {
    max-height: 100% !important;
  }
  .sm\:max-h-screen {
    max-height: 100vh !important;
  }
  .sm\:max-h-min {
    max-height: min-content !important;
  }
  .sm\:max-h-max {
    max-height: max-content !important;
  }
  .sm\:max-h-fit {
    max-height: fit-content !important;
  }
  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }
  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }
  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }
  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }
  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }
  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }
  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }
  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }
  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }
  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }
  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }
  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }
  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }
  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }
  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }
  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }
  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }
  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }
  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }
  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }
  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }
  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }
  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }
  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }
  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }
  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }
  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }
  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }
  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }
  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-h-0 {
    max-height: 0px !important;
  }
  .md\:max-h-full {
    max-height: 100% !important;
  }
  .md\:max-h-screen {
    max-height: 100vh !important;
  }
  .md\:max-h-min {
    max-height: min-content !important;
  }
  .md\:max-h-max {
    max-height: max-content !important;
  }
  .md\:max-h-fit {
    max-height: fit-content !important;
  }
  .md\:max-h-1rem {
    max-height: 1rem !important;
  }
  .md\:max-h-2rem {
    max-height: 2rem !important;
  }
  .md\:max-h-3rem {
    max-height: 3rem !important;
  }
  .md\:max-h-4rem {
    max-height: 4rem !important;
  }
  .md\:max-h-5rem {
    max-height: 5rem !important;
  }
  .md\:max-h-6rem {
    max-height: 6rem !important;
  }
  .md\:max-h-7rem {
    max-height: 7rem !important;
  }
  .md\:max-h-8rem {
    max-height: 8rem !important;
  }
  .md\:max-h-9rem {
    max-height: 9rem !important;
  }
  .md\:max-h-10rem {
    max-height: 10rem !important;
  }
  .md\:max-h-11rem {
    max-height: 11rem !important;
  }
  .md\:max-h-12rem {
    max-height: 12rem !important;
  }
  .md\:max-h-13rem {
    max-height: 13rem !important;
  }
  .md\:max-h-14rem {
    max-height: 14rem !important;
  }
  .md\:max-h-15rem {
    max-height: 15rem !important;
  }
  .md\:max-h-16rem {
    max-height: 16rem !important;
  }
  .md\:max-h-17rem {
    max-height: 17rem !important;
  }
  .md\:max-h-18rem {
    max-height: 18rem !important;
  }
  .md\:max-h-19rem {
    max-height: 19rem !important;
  }
  .md\:max-h-20rem {
    max-height: 20rem !important;
  }
  .md\:max-h-21rem {
    max-height: 21rem !important;
  }
  .md\:max-h-22rem {
    max-height: 22rem !important;
  }
  .md\:max-h-23rem {
    max-height: 23rem !important;
  }
  .md\:max-h-24rem {
    max-height: 24rem !important;
  }
  .md\:max-h-25rem {
    max-height: 25rem !important;
  }
  .md\:max-h-26rem {
    max-height: 26rem !important;
  }
  .md\:max-h-27rem {
    max-height: 27rem !important;
  }
  .md\:max-h-28rem {
    max-height: 28rem !important;
  }
  .md\:max-h-29rem {
    max-height: 29rem !important;
  }
  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-h-0 {
    max-height: 0px !important;
  }
  .lg\:max-h-full {
    max-height: 100% !important;
  }
  .lg\:max-h-screen {
    max-height: 100vh !important;
  }
  .lg\:max-h-min {
    max-height: min-content !important;
  }
  .lg\:max-h-max {
    max-height: max-content !important;
  }
  .lg\:max-h-fit {
    max-height: fit-content !important;
  }
  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }
  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }
  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }
  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }
  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }
  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }
  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }
  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }
  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }
  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }
  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }
  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }
  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }
  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }
  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }
  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }
  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }
  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }
  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }
  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }
  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }
  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }
  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }
  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }
  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }
  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }
  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }
  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }
  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }
  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-h-0 {
    max-height: 0px !important;
  }
  .xl\:max-h-full {
    max-height: 100% !important;
  }
  .xl\:max-h-screen {
    max-height: 100vh !important;
  }
  .xl\:max-h-min {
    max-height: min-content !important;
  }
  .xl\:max-h-max {
    max-height: max-content !important;
  }
  .xl\:max-h-fit {
    max-height: fit-content !important;
  }
  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }
  .sm\:fixed {
    position: fixed !important;
  }
  .sm\:absolute {
    position: absolute !important;
  }
  .sm\:relative {
    position: relative !important;
  }
  .sm\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:static {
    position: static !important;
  }
  .md\:fixed {
    position: fixed !important;
  }
  .md\:absolute {
    position: absolute !important;
  }
  .md\:relative {
    position: relative !important;
  }
  .md\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:static {
    position: static !important;
  }
  .lg\:fixed {
    position: fixed !important;
  }
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:relative {
    position: relative !important;
  }
  .lg\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:static {
    position: static !important;
  }
  .xl\:fixed {
    position: fixed !important;
  }
  .xl\:absolute {
    position: absolute !important;
  }
  .xl\:relative {
    position: relative !important;
  }
  .xl\:sticky {
    position: sticky !important;
  }
}
.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }
  .sm\:top-0 {
    top: 0px !important;
  }
  .sm\:top-50 {
    top: 50% !important;
  }
  .sm\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:top-auto {
    top: auto !important;
  }
  .md\:top-0 {
    top: 0px !important;
  }
  .md\:top-50 {
    top: 50% !important;
  }
  .md\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:top-auto {
    top: auto !important;
  }
  .lg\:top-0 {
    top: 0px !important;
  }
  .lg\:top-50 {
    top: 50% !important;
  }
  .lg\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }
  .xl\:top-0 {
    top: 0px !important;
  }
  .xl\:top-50 {
    top: 50% !important;
  }
  .xl\:top-100 {
    top: 100% !important;
  }
}
.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }
  .sm\:left-0 {
    left: 0px !important;
  }
  .sm\:left-50 {
    left: 50% !important;
  }
  .sm\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:left-auto {
    left: auto !important;
  }
  .md\:left-0 {
    left: 0px !important;
  }
  .md\:left-50 {
    left: 50% !important;
  }
  .md\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:left-auto {
    left: auto !important;
  }
  .lg\:left-0 {
    left: 0px !important;
  }
  .lg\:left-50 {
    left: 50% !important;
  }
  .lg\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }
  .xl\:left-0 {
    left: 0px !important;
  }
  .xl\:left-50 {
    left: 50% !important;
  }
  .xl\:left-100 {
    left: 100% !important;
  }
}
.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0px !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }
  .sm\:right-0 {
    right: 0px !important;
  }
  .sm\:right-50 {
    right: 50% !important;
  }
  .sm\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:right-auto {
    right: auto !important;
  }
  .md\:right-0 {
    right: 0px !important;
  }
  .md\:right-50 {
    right: 50% !important;
  }
  .md\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:right-auto {
    right: auto !important;
  }
  .lg\:right-0 {
    right: 0px !important;
  }
  .lg\:right-50 {
    right: 50% !important;
  }
  .lg\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }
  .xl\:right-0 {
    right: 0px !important;
  }
  .xl\:right-50 {
    right: 50% !important;
  }
  .xl\:right-100 {
    right: 100% !important;
  }
}
.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }
  .sm\:bottom-0 {
    bottom: 0px !important;
  }
  .sm\:bottom-50 {
    bottom: 50% !important;
  }
  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }
  .md\:bottom-0 {
    bottom: 0px !important;
  }
  .md\:bottom-50 {
    bottom: 50% !important;
  }
  .md\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }
  .lg\:bottom-0 {
    bottom: 0px !important;
  }
  .lg\:bottom-50 {
    bottom: 50% !important;
  }
  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }
  .xl\:bottom-0 {
    bottom: 0px !important;
  }
  .xl\:bottom-50 {
    bottom: 50% !important;
  }
  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }
  .sm\:overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:overflow-visible {
    overflow: visible !important;
  }
  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }
  .md\:overflow-hidden {
    overflow: hidden !important;
  }
  .md\:overflow-visible {
    overflow: visible !important;
  }
  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }
  .lg\:overflow-hidden {
    overflow: hidden !important;
  }
  .lg\:overflow-visible {
    overflow: visible !important;
  }
  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }
  .xl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xl\:overflow-visible {
    overflow: visible !important;
  }
  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }
  .sm\:z-0 {
    z-index: 0 !important;
  }
  .sm\:z-1 {
    z-index: 1 !important;
  }
  .sm\:z-2 {
    z-index: 2 !important;
  }
  .sm\:z-3 {
    z-index: 3 !important;
  }
  .sm\:z-4 {
    z-index: 4 !important;
  }
  .sm\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }
  .md\:z-0 {
    z-index: 0 !important;
  }
  .md\:z-1 {
    z-index: 1 !important;
  }
  .md\:z-2 {
    z-index: 2 !important;
  }
  .md\:z-3 {
    z-index: 3 !important;
  }
  .md\:z-4 {
    z-index: 4 !important;
  }
  .md\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }
  .lg\:z-0 {
    z-index: 0 !important;
  }
  .lg\:z-1 {
    z-index: 1 !important;
  }
  .lg\:z-2 {
    z-index: 2 !important;
  }
  .lg\:z-3 {
    z-index: 3 !important;
  }
  .lg\:z-4 {
    z-index: 4 !important;
  }
  .lg\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }
  .xl\:z-0 {
    z-index: 0 !important;
  }
  .xl\:z-1 {
    z-index: 1 !important;
  }
  .xl\:z-2 {
    z-index: 2 !important;
  }
  .xl\:z-3 {
    z-index: 3 !important;
  }
  .xl\:z-4 {
    z-index: 4 !important;
  }
  .xl\:z-5 {
    z-index: 5 !important;
  }
}
.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }
  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .md\:bg-repeat-round {
    background-repeat: round !important;
  }
  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }
  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }
  .sm\:bg-cover {
    background-size: cover !important;
  }
  .sm\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }
  .md\:bg-cover {
    background-size: cover !important;
  }
  .md\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }
  .lg\:bg-cover {
    background-size: cover !important;
  }
  .lg\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }
  .xl\:bg-cover {
    background-size: cover !important;
  }
  .xl\:bg-contain {
    background-size: contain !important;
  }
}
.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }
  .sm\:bg-center {
    background-position: center !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .sm\:bg-left-top {
    background-position: left top !important;
  }
  .sm\:bg-right {
    background-position: right !important;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .sm\:bg-right-top {
    background-position: right top !important;
  }
  .sm\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }
  .md\:bg-center {
    background-position: center !important;
  }
  .md\:bg-left {
    background-position: left !important;
  }
  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .md\:bg-left-top {
    background-position: left top !important;
  }
  .md\:bg-right {
    background-position: right !important;
  }
  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .md\:bg-right-top {
    background-position: right top !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }
  .lg\:bg-center {
    background-position: center !important;
  }
  .lg\:bg-left {
    background-position: left !important;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .lg\:bg-left-top {
    background-position: left top !important;
  }
  .lg\:bg-right {
    background-position: right !important;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .lg\:bg-right-top {
    background-position: right top !important;
  }
  .lg\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }
  .xl\:bg-center {
    background-position: center !important;
  }
  .xl\:bg-left {
    background-position: left !important;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xl\:bg-left-top {
    background-position: left top !important;
  }
  .xl\:bg-right {
    background-position: right !important;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xl\:bg-right-top {
    background-position: right top !important;
  }
  .xl\:bg-top {
    background-position: top !important;
  }
}
.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color,border-color,color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: 100ms !important;
}

.transition-duration-150 {
  transition-duration: 150ms !important;
}

.transition-duration-200 {
  transition-duration: 200ms !important;
}

.transition-duration-300 {
  transition-duration: 300ms !important;
}

.transition-duration-400 {
  transition-duration: 400ms !important;
}

.transition-duration-500 {
  transition-duration: 500ms !important;
}

.transition-duration-1000 {
  transition-duration: 1000ms !important;
}

.transition-duration-2000 {
  transition-duration: 2000ms !important;
}

.transition-duration-3000 {
  transition-duration: 3000ms !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  transition-delay: 100ms !important;
}

.transition-delay-150 {
  transition-delay: 150ms !important;
}

.transition-delay-200 {
  transition-delay: 200ms !important;
}

.transition-delay-300 {
  transition-delay: 300ms !important;
}

.transition-delay-400 {
  transition-delay: 400ms !important;
}

.transition-delay-500 {
  transition-delay: 500ms !important;
}

.transition-delay-1000 {
  transition-delay: 1000ms !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }
  .sm\:origin-top {
    transform-origin: top !important;
  }
  .sm\:origin-top-right {
    transform-origin: top right !important;
  }
  .sm\:origin-right {
    transform-origin: right !important;
  }
  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }
  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .sm\:origin-left {
    transform-origin: left !important;
  }
  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }
  .md\:origin-top {
    transform-origin: top !important;
  }
  .md\:origin-top-right {
    transform-origin: top right !important;
  }
  .md\:origin-right {
    transform-origin: right !important;
  }
  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .md\:origin-bottom {
    transform-origin: bottom !important;
  }
  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .md\:origin-left {
    transform-origin: left !important;
  }
  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }
  .lg\:origin-top {
    transform-origin: top !important;
  }
  .lg\:origin-top-right {
    transform-origin: top right !important;
  }
  .lg\:origin-right {
    transform-origin: right !important;
  }
  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }
  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .lg\:origin-left {
    transform-origin: left !important;
  }
  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }
  .xl\:origin-top {
    transform-origin: top !important;
  }
  .xl\:origin-top-right {
    transform-origin: top right !important;
  }
  .xl\:origin-right {
    transform-origin: right !important;
  }
  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xl\:origin-left {
    transform-origin: left !important;
  }
  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes flip {
  from {
    transform: perspective(2000px) rotateX(-100deg);
  }
  to {
    transform: perspective(2000px) rotateX(0);
  }
}
@keyframes flipleft {
  from {
    transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipright {
  from {
    transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipup {
  from {
    transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}
@keyframes zoomin {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomindown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@keyframes zoominleft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@keyframes zoominright {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@keyframes zoominup {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
.fadein {
  animation: fadein 0.15s linear;
}

.fadeout {
  animation: fadeout 0.15s linear;
}

.slidedown {
  animation: slidedown 0.45s ease-in-out;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  animation: scalein 0.15s linear;
}

.fadeinleft {
  animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  animation: fadeinright 0.15s linear;
}

.fadeoutright {
  animation: fadeoutright 0.15s linear;
}

.fadeinup {
  animation: fadeinup 0.15s linear;
}

.fadeoutup {
  animation: fadeoutup 0.15s linear;
}

.fadeindown {
  animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  animation: fadeoutdown 0.15s linear;
}

.animate-width {
  animation: animate-width 1000ms linear;
}

.flip {
  backface-visibility: visible;
  animation: flip 0.15s linear;
}

.flipup {
  backface-visibility: visible;
  animation: flipup 0.15s linear;
}

.flipleft {
  backface-visibility: visible;
  animation: flipleft 0.15s linear;
}

.flipright {
  backface-visibility: visible;
  animation: flipright 0.15s linear;
}

.zoomin {
  animation: zoomin 0.15s linear;
}

.zoomindown {
  animation: zoomindown 0.15s linear;
}

.zoominleft {
  animation: zoominleft 0.15s linear;
}

.zoominright {
  animation: zoominright 0.15s linear;
}

.zoominup {
  animation: zoominup 0.15s linear;
}

.animation-duration-100 {
  animation-duration: 100ms !important;
}

.animation-duration-150 {
  animation-duration: 150ms !important;
}

.animation-duration-200 {
  animation-duration: 200ms !important;
}

.animation-duration-300 {
  animation-duration: 300ms !important;
}

.animation-duration-400 {
  animation-duration: 400ms !important;
}

.animation-duration-500 {
  animation-duration: 500ms !important;
}

.animation-duration-1000 {
  animation-duration: 1000ms !important;
}

.animation-duration-2000 {
  animation-duration: 2000ms !important;
}

.animation-duration-3000 {
  animation-duration: 3000ms !important;
}

.animation-delay-100 {
  animation-delay: 100ms !important;
}

.animation-delay-150 {
  animation-delay: 150ms !important;
}

.animation-delay-200 {
  animation-delay: 200ms !important;
}

.animation-delay-300 {
  animation-delay: 300ms !important;
}

.animation-delay-400 {
  animation-delay: 400ms !important;
}

.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}
.layout-height{
height: calc(100vh - 119px);
}
.navbar {
  height: 57px;
}
ul {

margin: 0px;
padding: 0px;
list-style: none;
}

li {
float: left;
list-style: none;
}

li a {
display: block;
color: white;
text-align: center;
padding: 16px;
text-decoration: none;
}

li a:hover {
background-color: #023f4a;
}

.container {
max-width: 400px;
width: 100%;
margin: 0 auto;
position: relative;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact {
margin: 0px 0;
/* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
}

#contact h3 {
display: block;
font-size: 30px;
font-weight: 300;
margin-bottom: 10px;
}

#contact h4 {
margin: 5px 0 15px;
display: block;
font-size: 13px;
font-weight: 400;
}

fieldset {
border: medium none !important;
margin: 0 0 10px;
min-width: 100%;
padding: 0;
width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
width: 100%;
border: 1px solid #ccc;
background: #FFF;
margin: 0 0 5px;
padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
-webkit-transition: border-color 0.3s ease-in-out;
-moz-transition: border-color 0.3s ease-in-out;
transition: border-color 0.3s ease-in-out;
border: 1px solid #aaa;
}

#contact textarea {
height: 100px;
max-width: 100%;
resize: none;
}

#contact button[type="submit"] {
cursor: pointer;
width: 100%;
border: none;
background: #4CAF50;
color: #FFF;
margin: 0 0 5px;
padding: 10px;
font-size: 15px;
}

#contact button[type="submit"]:hover {
background: #43A047;
-webkit-transition: background 0.3s ease-in-out;
-moz-transition: background 0.3s ease-in-out;
transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
text-align: center;
}

#contact input:focus,
#contact textarea:focus {
outline: 0;
border: 1px solid #aaa;
}

::-webkit-input-placeholder {
color: #888;
}

:-moz-placeholder {
color: #888;
}

::-moz-placeholder {
color: #888;
}

:-ms-input-placeholder {
color: #888;
}
.card ul {
list-style-type: none;
margin: 0;
padding: 0;
background-color: transparent !important;
}
.homepage .ul .li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}
.homepage  {
}
.csunderline {
  width: 60px;
  height: 1px;
  margin: auto;
  display: block;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px var(--primary-color);
}
.aboutbg{
  background-image: url('../../../public/website/aboutbg.png');
}
.dynamo ul li
{
  list-style: outside ;
  float: none;
  padding:3px

}
ul li::marker {
  color: #6c757d;
  font-size: 1.1em;
}
 a:-webkit-any-link {
  color: #343a40;
}
.navbar a:-webkit-any-link {
  color: white;
}
.cfs ul li  {
  list-style: none;
  float: none;
  padding: 3px;
}
.cfs ul li a {
  display: inline-block;
  color: black;
  text-align: left;
  padding: 5px;
  text-decoration: none;
}
.cfs ul li a:hover {
 background-color: #003c36;
 color: white;
 border-radius: 7px;
}